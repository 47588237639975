// ------------------------------------------------
//  Button loading
// ------------------------------------------------
.btn-is-loading {
  position: relative;
  color: transparent !important;

  &::before {
    content: 'Loading ...';
    color: rgba(255,255,255,0.7);
    position: absolute;
    left: 0;
    right: 0;
    @include animation(moveUp 0.5s);
  }

  &.no-loading-state {
    &::before {
        content: attr(data-disabled-text);
    }
  }
}

// ------------------------------------------------
//  Custom buttons
// ------------------------------------------------

.btn.btn-facebook {
  background: $facebook_blue;
  color: #fff;
  &:after {
    background-image: image-url('site/facebook.png');
    background-repeat: no-repeat;
    background-size: 80px 16.4px;
    background-position: center center;
    width: 80px;
    height: 18px;
    content: ' ';
    display: inline-block;
    vertical-align: top;
  }
  &:hover, &:focus {
    background: lighten($facebook_blue, 2%);
  }
  &[disabled],
  &:disabled,
  &.disabled {
    &:before {
      content: 'Redirecting ...';
    }
    &:after {
      display: none;
    }
  }
}

.btn.btn-google {
  background: #fff;
  color: #3c4043;
  border-color: #dadce0;
  font-weight: 500;
  font-family: "Google Sans",arial,sans-serif;
  &:hover, &:focus {
    border-color: #d2e3fc;
    background-color: #f8fafe;
  }
  &[disabled],
  &:disabled,
  &.disabled {
    &:before {
      content: 'Redirecting ...';
    }
    &:after {
      display: none;
    }
  }
  &:before {
    background-image: image-url('site/google.svg');
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: center center;
    width: 18px;
    height: 18px;
    content: ' ';
    display: inline-block;
    vertical-align: top;
    margin-left: -20px;
    margin-right: 20px;
  }
}

.btn.btn-twitter {
  background: $twitter_blue;
  color: #fff;
  &:after {
    background-image: image-url('site/twitter.png');
    background-repeat: no-repeat;
    background-size: 80px 16.4px;
    background-position: center center;
    width: 80px;
    height: 18px;
    content: ' ';
    display: inline-block;
    vertical-align: top;
  }
  &:hover, &:focus {
    background: lighten($twitter_blue, 2%);
  }
}

// Outline with completely rounded borders
.btn.btn-ghost {
  @extend .btn-default;
  border: solid 1px rgba(0,0,0,0.15);
  background: transparent;
  color: rgba(0,0,0,0.533);
  @include border-radius(999px);
  &:focus, &:hover {
    border-color: rgba(0,0,0,0.2);
    background: transparent;
    color: rgba(0,0,0,0.58);
  }
  &.btn-invert-on-hover {
    &:focus, &:hover {
      background: #111;
      border-color: #111;
      color: #eee;
    }
  }
}
.btn.btn-ghost-dark {
  @extend .btn-default;
  border: solid 1px #000;
  background: #111;
  color: rgba(255,255,255,0.9);
  @include border-radius(999px);
  &:focus, &:hover {
    background: lighten(#111, 2%);
    color: #fff;
    border: solid 1px lighten(#000, 1%);
  }
  &.btn-invert-on-hover {
    &:focus, &:hover {
      background: #fff;
      border-color: #111;
      color: #333;
    }
  }
}
.btn.btn-ghost-invert {
  @extend .btn-default;
  border: solid 1px rgba(255,255,255,0.5);
  background: transparent;
  color: rgba(255,255,255,0.75);
  @include border-radius(999px);
  &:focus, &:hover {
    border-color: rgba(255,255,255,0.55);
    background: transparent;
    color: rgba(255,255,255,0.8);
  }
}

// Outline with normal borders
.btn.btn-outline {
  @extend .btn-default;
  border: solid 1px #d9d9d9;
  background: transparent;
  color: #777;
  @include border-radius($standard-form-border-radius);
  padding-left: 2em;
  padding-right: 2em;
  &:focus, &:hover {
    border-color: darken(#d9d9d9, 5%);
    background: transparent;
    color: darken(#777, 5%);
  }
}

// No border
.btn.btn-plain {
  @extend .btn-default;
  border: none;
  background: transparent;
  color: #777;
  padding: 0 .75em;
  min-width: 0;
  line-height: 1;
  &:focus, &:hover {
    color: darken(#777, 30%);
    background: transparent;
  }
}
.open > .dropdown-toggle.btn.btn-plain {
  background: transparent;
  @include box-shadow(none);
}

.btn.btn-plain-green {
    color: $green;
}

.btn.btn-gold {
  @extend .btn-default;
  background: $gold;
  border: solid 1px $gold;
  color: #fff;
  @include border-radius(999px);
  &:focus, &:hover {
    background: lighten($gold, 2%);
    color: #fff;
    border-color: $gold;
  }
  &.btn-invert-on-hover {
    &:focus, &:hover {
      background: transparent;
      color: $gold;
    }
  }
}

.btn.btn-white {
  @extend .btn-default;
  background: #fff;
  border: solid 1px #fff;
  color: #000;
  &.btn-invert-on-hover {
    &:focus, &:hover {
      background: #000;
      border-color: #fff;
      color: #fff;
    }
  }
}

.btn.btn-forward {
  &:not([disabled]):not(:disabled):not(.disabled) {
    @extend .ion-ios-arrow-forward;
    position: relative;
    &:before {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -0.45em;
    }
  }
}

// ------------------------------------------------
//  Buttons with icons
// ------------------------------------------------

.btn i {
  margin-right: 3px;
}


.signup-blocked-button {
    position: absolute!important;
    top: 150px;
    right: 20px;

    @media (min-width: 992px) {
        top: 20px;
    }
}
