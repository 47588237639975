.ribbon-wrapper {
  width: 79px;
  height: 81px;
  overflow: hidden;
  position: absolute;
  top: -5px;
  z-index: 1;
  right: -5px;
  .ribbon {
    color: #fff;
    text-align: center;
    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
    @include transform(rotate(45deg));
    position: relative;
    padding: 5px 0 6px;
    left: -5px;
    top: 20px;
    width: 107px;
    background-color: $orange;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 600;
    @include box-shadow(0px 0px 3px rgba(0,0,0,0.3));
    &:before, &:after {
      content: "";
      border-top:   3px solid darken($orange,10%);
      border-left:  3px solid transparent;
      border-right: 3px solid transparent;
      position:absolute;
      bottom: -3px;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
.ribbon-wrapper.ribbon-white {
  &:before, &:after {
    content: ' ';
    position: absolute;
    z-index: 1;
  }
  &:before {
    border-top: solid 1px #dadada;
    width: 32px;
    top: 0;
    left: 2px;
  }
  &:after {
    border-right: solid 1px #dadada;
    bottom: 4px;
    right: 0;
    height: 32px;
  }
  .ribbon {
    background-color: #fff;
    color: #ccc;
    &:before, &:after {
      border-top-color: darken(#fff, 10%);
    }
  }
}
.ribbon-wrapper.ribbon-blue .ribbon {
  background-color: $blue;
  &:before, &:after {
    border-top-color: darken($blue, 10%);
  }
}
.ribbon-wrapper.ribbon-green .ribbon {
  background-color: $green;
  &:before, &:after {
    border-top-color: darken($green, 10%);
  }
}
.ribbon-wrapper.ribbon-red .ribbon {
  background-color: $red;
  &:before, &:after {
    border-top-color: darken($red, 10%);
  }
}
