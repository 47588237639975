.slick-slide {
  border: none !important;
}
.slick-arrow {
  position: absolute;
  border: none;
  background: transparent;
  display: block;
  line-height: 0px;
  font-size: 0px;
  bottom: 6px;
  left: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  z-index: 1;
  @include transition(color 350ms);
  &:before {
    font-size: 20px;
    position: relative;
    top: 1px;
  }
  &:hover, &:focus {
    outline: none;
  }
}
.slick-prev {
  margin-left: -75px;
  z-index: 1;
  @extend .ion-ios-arrow-left;
}
.slick-next {
  margin-left: 45px;
  @extend .ion-ios-arrow-right;
}
.slick-dots {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  height: 30px; // Hard coding necessary for Firefox
  text-align: center;
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  pointer-events: none;
  li {
    display: inline-block;
    vertical-align: top;
    pointer-events: auto;
    button {
      border: none;
      background: transparent;
      display: block;
      height: 30px;
      width: 30px;
      line-height: 0px;
      font-size: 0px;
      position: relative;
      &:hover, &:focus {
        outline: none;
      }
      &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        @include border-radius(100%);
        width: 12px;
        height: 12px;
        margin-top: -6px;
        margin-left: -6px;
        border: solid 2px #fff;
        @include transition(background-color 350ms);
      }
    }
    &.slick-active {
      button {
        &:before {
          background-color: #fff;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Large desktops
// ------------------------------------------------

@media only screen and (min-width: 1200px) {
  .slick-arrow {
    bottom: auto;
    left: auto;
    top: 50%;
    margin-top: -50px;
    width: auto;
    height: auto;
    padding: 30px;
    color: #fff;
    mix-blend-mode: difference;
    &:before {
      font-size: 40px;
      top: 0;
    }
  }
  .slick-next, .slick-prev {
    margin-left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 768px) {
  .slick-dots, .slick-arrow {
    display: none !important;
  }
}
