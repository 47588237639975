body#not_found {
  .not_found {
    background: #111;
    border: 0;
    color: #aaa;
    font: inherit;
    font-family: $sans_font_family;
    font-size: 16px;
    margin: 0;
    padding: 30px 0;
    vertical-align: baseline;

    .text-container {
      margin: 0 auto;
      max-width: 764px;
      text-align: center;

      em {
        background-image: linear-gradient(to top, #77b204 4px, #fff 4px);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 40px 4px;
        border: 0;
        color: #fff;
        display: block;
        font-family: $sans_font_family;
        font-size: 55px;
        font-weight: 600;
        font-style: normal;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 30px;
        padding: 0;
        padding-bottom: 5px;
        vertical-align: baseline;
        line-height: 60px;
      }

      p {
        line-height: 1.8em;
      }
    }
  }

  #missing {
    display: block;
    margin: auto auto 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }

  @media only screen and (max-width: 767px) {

    #missing {
      height: auto;
      margin-top: auto;
      width: 200px;
    }

    .text-container {
      margin-top: 30px;
      padding: 0 20px;
    }

    p {
      border: 0;
      font: inherit;
      font-size: 100%;
      margin: 0;
      padding: 0;
      vertical-align: baseline;
    }

    h1 {
      font-size: 60px;
    }
  }
}
