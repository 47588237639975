$base_font_size: 15px;
$base_font_weight: 300;
$bold_font_weight: 400;
$serif_font_weight: 300;

$heading_font_family: "GT Walsheim", sans-serif !default;
$sans_font_family: "Graphik", sans-serif !default;
$serif_font_family: "Merriweather", Georgia, Cambria, "Times New Roman", Times,
    serif;

$sans_font: $base_font_weight $base_font_size $sans_font_family;
$bold_font: $bold_font_weight $base_font_size $sans_font_family;
$serif_font: $serif_font_weight $base_font_size $serif_font_family;

$admin_sans_font: $base_font_weight $base_font_size "Open Sans", Arial,
    sans-serif;
$admin_bold_font: $bold_font_weight $base_font_size "Open Sans", Arial,
    sans-serif;
