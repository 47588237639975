article.event {
  position: relative;
  .controls {
    position: absolute;
    pointer-events: none;
    bottom: 45px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0;
    time, .btn {
      display: inline-block;
      vertical-align: top;
      pointer-events: auto;
    }
    time {
      background: #fafafa;
      @include border-radius(2px);
      margin-right: 10px;
      padding: 13px 18px;
      table {
        td, th {
          text-align: center;
          line-height: 1em;
          vertical-align: middle;
          font-weight: 400;
        }
        td {
          padding: 0 7px;
          font-size: 27px;
        }
        th {
          text-transform: uppercase;
          padding-top: 3px;
          font-size: 10px;
        }
      }
      &.highlight {
        table {
          td {
            color: $red !important;
          }
        }
      }
    }
    .btn {
      padding-left: 3.8em;
      padding-right: 3.8em;
      font-size: 20px;
    }
  }
  &.finished {
    position: relative;
    overflow: hidden;
    picture {
      @include greyscale();
    }
  }
}

.featured--event {
  margin-bottom: 15px;

  @media only screen and (min-width: 768px) {
      margin-bottom: 30px;
  }
}


// ------------------------------------------------
//  Every except mobiles
// ------------------------------------------------

@media only screen and (min-width: 768px) {
  article.event {

    // Event banner images should be 635px high -
    // make it so, and have the image fit the space
    picture {
      height: 635px;
      &.legacy-images {
        height: 544px;
      }
      .img-wrapper {
        height: 100%;
        position: absolute;
        left: 50%;
        width: auto;
        img {
          height: 100%;
          position: relative;
          left: -50%;
          max-width: none;
          width: auto;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  article.event {
    .controls {
      position: static;
      padding: 15px 15px 0;
      time, .btn {
        display: block;
      }
      time {
        margin-right: 0;
        margin-bottom: 15px;
        table {
          width: 100%;
          table-layout: fixed;
          td {
            font-size: 22px;
          }
        }
      }
      .btn {
        font-size: 15px;
      }
    }
  }
}
