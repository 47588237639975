body.account-user {
  .account-layout-container {
    .location-selection {
      form {
        display: block;
        .vote {
          margin-top: 25px;
        }
      }
    }
  }

  .identity {
    text-align: center;
    border: solid 1px #eaeaea;
    @include border-radius($standard-form-border-radius);
    padding: 20px;
    .picture-wrapper {
      border: solid 1px #eaeaea;
      padding: 2px;
      width: 80px;
      margin: 0 auto 6px;
    }
    .label {
      background: $facebook_blue;
      font-weight: 400;
      text-shadow: none;

      &.google {
        background: $google_blue;
      }
    }
    a {
      color: #bbb;
      font-size: 12px;
      display: inline-block;
      margin-top: 10px;
      &:focus, &:hover {
        text-decoration: none;
        color: $red;
      }
    }
  }
  .button-hint {
    font-size: 12px;
    color: #bbb;
    line-height: 1.3em;
    margin: 1em auto 0;
    max-width: 282px;
  }
  .btn-facebook {
    margin-top: 10px;
  }
  .standard-form + .standard-form {                   // Manually add the section separator, because we implement as two separate forms
    .standard-form-section:first-of-type {
      padding-top: 2em;
      border-top: solid 1px rgba(0, 0, 0, 0.03);
    }
  }
  &#close {
    .close-account-container {
      p {
        line-height: 1.6em;
      }
    }
    .alternative-option {
      margin: 30px 0;
      border: solid 1px #eaeaea;
      @include border-radius($standard_form_border_radius);
      padding: 25px;
      h5, .h5 {
        text-align: center;
        margin: 0 0 15px;
      }
      .btn {
        margin-top: 15px;
      }
    }
    input {
      text-transform: uppercase;
    }
  }
}
