.vertical-centre-outer {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.vertical-centre-inner {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}
.c-page-content > .vertical-centre-outer {
  height: 100vh;
}
