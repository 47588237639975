$black: #000;
$blue: #1E9AD8;
$border_green: #6da304;
$facebook_blue: #3B5998;
$gold: #e4b241;
$youtube_red: #cd201f;
$google_blue: #4285f4;
$green_hover_border: #74ad04;
$green_hover: #7ebc04;
$green: #77b204;
$instagram_pink: #d33786;
$light_grey: #dedede;
$linkedin_blue: #006fa6;
$off_white: #F6F6F6;
$orange: #fda705;
$paypal_blue: #1f4181;
$purple: #6D2869;
$red: #d51c5c;
$twitter_blue: #55acee;
$white: #fff;
$yellow: #FDC040;

