.payment-method-icon {
  width: 40px;
  height: 25px;
  display: inline-block;
  background-size: 40px 25px;
  transition: filter 250ms, opacity 250ms ease-in-out;
  -webkit-transition: -webkit-filter 250ms, opacity 250ms ease-in-out;
  &.visa {
    background-image: asset-url('common/visa.png');
  }
  &.mastercard {
    background-image: asset-url('common/mastercard.png');
  }
  &.amex {
    background-image: asset-url('common/amex.png');
  }
  &.generic {
    background-image: asset-url('common/generic.png');
  }
  &.paypal {
    background-image: asset-url('common/paypal.png');
  }
  &.afterpay {
    background-image: asset-url('common/afterpay.png');
  }
  &.gift-card {
    background-color: #444;
    @include border-radius(2px);
    @include box-shadow(none);
    &:before, &:after {
      font-family: 'Alternate Gothic No2 D', sans-serif;
      font-size: 16px;
      line-height: 1em;
      display: inline-block;
      padding-top: 4px;
    }
    &:before {
      content: 'V';
      color: #fff;
      padding-right: 1px;
    }
    &:after {
      content: 'M';
      color: $green;
    }
  }
  &.new-credit {
    background-image: asset-url('common/generic.png');
    position: relative;
    &:before {
      content: '+';
      font-style: normal;
      color: $green;
      position: absolute;
      right: -5px;
      top: -12px;
      font-size: 20px;
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
      font-weight: 600;
    }
  }
}
.supported-credit-cards {
  position: absolute;
  top: 0.85em;
  right: 0.85em;
}
.payment-gateway-icon {
  display: inline-block;
  &.stripe {
    background-image: asset_url('common/stripe-logo.png');
    background-size: 60.5px 25px;
    height: 25px;
    width: 60.5px;
  }
  &.fat-zebra {
    background-image: asset_url('common/fat-zebra-logo.png');
    background-size: 100px 25px;
    height: 25px;
    width: 100px;
  }
  &.paypal {
    background-image: asset_url('common/paypal-logo.png');
    background-size: 75px 20px;
    height: 20px;
    width: 75px;
  }
  &.afterpay {
    background-image: asset_url('common/afterpay-logo.png');
    background-size: 75px 30px;
    height: 30px;
    width: 75px;
  }
}
.standard-form-group {
  &.visa, &.mastercard, &.amex {
    .payment-method-icon {
      @include filter(grayscale(100%));
      opacity: 0.33;
    }
    &.visa .payment-method-icon.visa { @include filter(grayscale(0%)); opacity: 1; }
    &.mastercard .payment-method-icon.mastercard { @include filter(grayscale(0%)); opacity: 1; }
    &.amex .payment-method-icon.amex { @include filter(grayscale(0%)); opacity: 1; }
  }
}
ul.pending-payments {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    padding: $standard-form-ios-padding;
    border: $standard-form-border;
    position: relative;
    margin-bottom: -1px;
    background: #fff;
    span {
      position: absolute;
      right: 1.1em;
    }
    .credit-card-number {
      display: inline;
      font-size: 13px;
      color: #aaa;
      font-weight: 300;
      margin-left: 50px;
    }
    &:first-child {
      border-top-left-radius: $standard-form-border-radius;
      border-top-right-radius: $standard-form-border-radius;
    }
    &:last-child {
      border-bottom-left-radius: $standard-form-border-radius;
      border-bottom-right-radius: $standard-form-border-radius;
    }
  }
}
.no-charge {
  padding: $standard-form-ios-padding;
  border: $standard-form-border;
  @include border-radius($standard-form-border-radius);
  margin-bottom: 2em;
  text-align: center;
}
.standard-form-label-text {
  .payment-method-icon {
    margin-top: -8px;
    margin-bottom: -2px;
    vertical-align: middle;
    margin-right: 8px;
  }
}
.paypal-radio {
  position: relative;
  &:after {
    background-image: asset_url('common/paypal-logo.png');
    background-size: 75px 20px;
    height: 20px;
    width: 75px;
    position: absolute;
    right: 0.85em;
    top: 1.15em;
    content: ' ';
  }
  &.disabled:after {
    @include opacity(0.5);
  }
}
.afterpay-radio {
  position: relative;
  &:after {
    background-image: asset_url('common/afterpay-logo.png');
    background-size: 75px 20px;
    height: 20px;
    width: 75px;
    position: absolute;
    right: 0.85em;
    top: 1.15em;
    content: ' ';
  }
  &.disabled:after {
    @include opacity(0.5);
  }
}
.btn-success.submit-payment {
  &[disabled],
  &:disabled,
  &.disabled {
    span {
      visibility: hidden;
    }
    &:before {
      content: 'Processing ...';
    }
  }
}
.priority {
  margin-top: 3rem;

  select {
    width: 90%;
    border: solid 2px rgba(0, 0, 0, 0.145);
    height: 25px;
  }
}

// ------------------------------------------------
//  Tablet and Mobile
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  .standard-form-label-text {
    .payment-method-icon {
      margin: 0 8px 0 0;
      position: relative;
      top: -3px;
      vertical-align: top;
    }
  }
  .supported-credit-cards {
    top: 1.15em;
  }
}
