// ------------------------------------------------
//  Common
// ------------------------------------------------

section.offers {
  padding-top: 30px;
}

article.offer {
  position: relative;

  &.soldout, &.finished {
    @include greyscale();
  }

  // Themes
  &.light {
    header, footer {
      background: #fff;
    }
    header {
      a, h1 {
        color: #000;
      }
      h1 {
        line-height: normal;
      }
    }
    .price {
      .rrp {
        color: $red;
        &:after {
          border-top-color: $red;
        }
      }
    }
    .countdown {
      table {
        td {
          color: #5c5c5c;
        }
        th {
          color: #ccc;
        }
      }
    }
    .select-wrapper {
      &:before {
        color: #aaa;        // Caret
      }
      select {
        background: #fff;
        color: #555;
        border-color: #d9d9d9;
      }
    }
    .btn-default, span.btn-default:hover, span.btn-default:focus {
      background: #eaeaea;
      border-color: darken(#eaeaea, 3%);
      color: #555;
    }
    .btn-default:hover, .btn-default:focus {
      background: lighten(#eaeaea, 2%);
      border-color: darken(#eaeaea, 1%);
    }
  }

  &.dark {
    header, footer {
      background: #111;
    }
    header {
      a, h1 {
        color: #fff;
      }
    }
    picture {
      background-color: #191919;
    }
    .price {
        strong {
            color: #fff;
        }
      .rrp {
        color: $red;
        &:after {
          border-top-color: $red;
        }
      }
    }
    .countdown {
      table {
        td {
          color: #999;
        }
        th {
          color: #666;
        }
      }
    }
    .select-wrapper {
      &:before {
        color: #444;
      }
      select {
        background: #0d0d0d;
        color: #ccc;
        border-color: #333;
      }
    }
    .btn-default, span.btn-default:hover, span.btn-default:focus {
      background: #222;
      border-color: darken(#222, 3%);
      color: #555;
    }
    .btn-default:hover, .btn-default:focus {
      background: lighten(#222, 2%);
      border-color: darken(#222, 1%);
    }
  }

  .ribbon-wrapper {
    pointer-events: none;   // Allow clicks to pass through the ribbons
  }

  header {
    text-align: center;
    a, h1 {
      display: block;
      font-weight: 400;
      height: 4em;
      margin: 0;
      padding: 0.7em 1em 0;
    }
    a {
      &:hover, &:focus {
        color: $green;
        text-decoration: none;
      }
    }
    .text-wrapper {
      max-height: 2.8em;
      overflow: hidden;
    }
  }

  footer {
    position: relative;
  }

  .discount {
    background: #000;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    padding: 2px;

    &.highlight {
      background: $red;
    }

    .figure {
      border: 2px solid #fff;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      strong, em {
        line-height: 1em !important;  // !important is necessary to fix a Chrome bug
        display: block;
      }
      strong {
        font: $bold_font;
        color: #fff;
        font-weight: bold;
      }
      em {
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .attributes {
    line-height: 1em;
    dt, dd {
      display: inline-block;
    }
    dl {
      margin: 0;
    }
    dt {
      color: $green;
      width: 20px;
      text-align: center;
      vertical-align: text-bottom;
    }
    dd {
      color: #b0b0b0;
      line-height: 1em;
    }
  }

  .price {
    text-align: center;
    .rrp {
      font-family: $heading_font_family;
      font-weight: 300;
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        border-top: solid 1px;
        left: 0;
        right: 0;
      }
    }
    strong {
      font-family: $heading_font_family;
      font-weight: 300;
      color: #131314;
      line-height: 1.2;
    }
    em {
      display: block;
      font-style: normal;
      text-transform: uppercase;
      color: #aaa;
    }
  }

  .countdown {
    table {
      width: 100%;
      table-layout: fixed;
      td, th {
        text-align: center;
        line-height: 1em;
        vertical-align: middle;
      }
      th {
        text-transform: uppercase;
        font-weight: 400;
        padding-top: 3px;
      }
    }
    .highlight {
      table {
        td {
          color: $red !important;
        }
      }
    }
  }

  .select-wrapper {
    position: relative;
    margin-bottom: 1em;
    @extend .ion-chevron-down;
    &:before {
      position: absolute;
      right: 1.6em;
      top: 50%;
      margin-top: -5px;
      font-size: 10px;
      cursor: pointer;
      pointer-events: none;
    }
    select {
      @include appearance(none);
      cursor: pointer;
      border: solid 1px;
      @include border-radius($standard-form-border-radius);
      padding: 0.75em;
      width: 100%;
      &:focus {
        outline: none;
        border-color: $green;
      }
    }
  }

  span.btn-default {
    cursor: default;
  }
  .button {
    .btn {
      font-family: $heading_font_family;
      font-weight: 300;

      span {
        display: block;
        line-height: 1em;
        font-size: 12px;
        font-family: $sans_font_family;
        font-weight: $base_font_weight;
        color: rgba(255,255,255,0.7);
        margin-top: -1px;
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.has-subtext {
        padding-top: 0.55em;
        padding-bottom: 0.55em;
      }
    }
  }
  .one-click-button {
    margin-top: 5px;
    &[disabled],
    &:disabled,
    &.disabled {
      span {
        visibility: hidden;
      }
      &:before {
        content: 'Buying ...';
        margin-top: 6px;
      }
    }
  }

  .button {
    .btn-success {
          background-color: #48a90f;
          border-color: #48a90f;
          transition: background-color 0.3s, border-color 0.3s;

          &:hover,
          &:focus {
              background-color: lighten($green, 1%);
              border-color: lighten($green, 1%);
          }
      }
  }
}

// Layout for use when there isn't much horizontal
// space i.e. in cards, or banners on mobile
// devices. Laid out as a stack of title, details
// then button. Details has two-columns - pricing
// on the right and countdown (or attributes if
// countdown is disabled) on the left.

@mixin offer_xs_layout() {

  header {
    h1 {
      font-size: 15px;
    }
  }

  .discount {
    width: 60px;
    height: 60px;
    .figure {
      strong {
        font-size: 20px;
      }
      em {
        font-size: 10px;
      }
    }
  }

  .details {
    width: 100%;
    min-height: 69px;
    text-align: center;

    .countdown-price-table {
      min-height: 41px;
      display: table;
      table-layout: fixed;
    }
  }

  .attributes, .countdown, .price {
    vertical-align: middle;
    width: 100%;
  }

  .attributes, .countdown {
    padding: 0 8px;
    margin-bottom: 10px;
  }

  .attributes {
    padding: 0;
  }

  .countdown,
  .price {
    display: table-cell;
    width: 50%;
  }

  // Only display attributes if there is no
  // countdown
  &.no-countdown {
    footer {
      .attributes {
        display: block;
      }
    }
  }
  .attributes {
    font-size: 11px;
    min-height: 18px;
    dl {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    dt {
      position: absolute;
      top: 0;
      left: 0;
    }
    dd {
      white-space: nowrap;
      line-height: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  .countdown {
    border-right: $standard-form-border;
  }

  .price {
    font-size: 20px;
    .rrp:after {
      left: -1px;
      right: -1px;
    }
    strong {
      sup {
        top: -.27em;
      }
    }
    em {
      font-size: 11px;
    }
  }

  .button {
    margin: 18px 8px 8px;
    .btn {
      padding-left: 1em;
      padding-right: 1em;
      border-radius: 100px;

      &.has-subtext {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        span {
          font-size: 11px;
        }
      }
    }
  }
}

// Layout for use when we have a medium horizontal
// space (such as on a tablet or small desktop), or
// for when we have a large horizontal space but
// don't want the info box sitting over the top of
// the image (such as mixed case banners). The info
// box sits at the bottom and is broken into three
// columns - countdown and attributes, title and
// pricing, then buttons.

@mixin offer_md_layout() {
  @include banner_images();

  // Themes
  &.light {
    footer, footer > .vertical-centre-outer {
      border-color: #d9d9d9;
    }
  }
  &.dark {
    footer, footer > .vertical-centre-outer {
      border-color: #111;
    }
  }

  .info-box {
    height: 143px;
    position: absolute;
    bottom: 45px;
    left: 50%;
    width: 738px;
    margin-left: -369px;
  }

  header {
    position: absolute;
    left: 1px;
    top: 7px;
    width: 33%;
    z-index: 1;
    height: 50%;
    a, h1 {
      padding: 15px;
      height: 100%;
      font-size: 15px;
    }
  }

  // If there is a select or a one-click button e.g.
  // if the right column is displaced, the title
  // has full height when there is no countdown
  &.multi, &.has-one-click {
    &.no-countdown {
      header {
        bottom: 1px;
        height: auto;
      }
    }
  }

  // If there is no select or one-click, the title
  // has full height when there are no attributes
  &.single:not(.has-one-click) {
    &.no-attributes {
      header {
        bottom: 1px;
        height: auto;
      }
    }
  }

  // When the offer has finished, the title has
  // full width
  &.finished {
    header {
      width: auto;
      right: 1px;
    }
  }

  footer {
    height: 100%;
    border: solid 1px;
    & > .vertical-centre-outer {
      table-layout: fixed;
      position: absolute;
      left: 33.3333%;
      width: 33.3333%;
      border-left: solid 1px;
      border-right: solid 1px;
    }
  }
  &.finished {
    footer > .vertical-centre-outer {
      border-left: none;
      border-right: none;
    }
  }

  .attributes {
    font-size: 12px;
    text-align: center;
    margin-bottom: 4px;
    dt, dd, dl {
      display: inline-block;
    }
    dl {
      margin: 0;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    dt {
      color: $green;
      width: 20px;
      text-align: center;
      vertical-align: text-bottom;
    }
    dd {
      color: #b0b0b0;
    }
  }

  // Attributes will natively sit above the price.
  // We only need to move them over underneath the
  // title when there is no select or one-click e.g.
  // the right column is not displaced.

  &.single:not(.has-one-click) {
    .attributes {
      position: absolute;
      left: -100%;
      width: 100%;
      top: 63%;
      bottom: 1px;
      margin-bottom: 0;
    }
    &.finished {
      .attributes {
        width: 300%;
      }
    }
  }

  .button {
    padding: 15px;
    position: absolute;
    left: 100%;
    width: 101%;
    top: 0;
    height: 50%;
    .btn {
      padding: 10px 15px;
      font-size: 16px;
      border-radius: 100px;

    }
  }

  // When there's no select or one-click, and no
  // countdown, the button has the right column to
  // itself.
  &.no-countdown.single:not(.has-one-click) {
    .button {
      height: auto;
      bottom: 1px;
    }
  }

  .countdown {
    position: absolute;
    left: 101%;
    width: 100%;
    top: 50%;
    bottom: 5px;
    padding: 0 5px;
    table {
      th {
        font-size: 10px;
      }
      td {
        font-size: 30px;
        font-weight: 400;
      }
    }
  }

  // Countdown sits underneath the button, unless
  // there is a one-click button or a select, in
  // which case it moves over the underneath the
  // title.
  &.multi, &.has-one-click {
    .countdown {
      left: -100%;
      padding: 0 10px;
      top: 43%;
    }
  }

  .price {
    .rrp, strong {
      line-height: 1;
    }
    .rrp {
      font-size: 26px;
      letter-spacing: -2px;
      margin-right: 10px;
      position: relative;
      top: -4px;
      &:after {
        margin-top: -4px;
        left: -6px;
        right: -6px;
        border-top-width: 2px;
      }
    }
    strong {
      font-size: 36px;
      display: inline-block;
      letter-spacing: -1px;
      margin-bottom: 4px;
      sup {
        font-size: 66%;
        top: -0.38em;
        letter-spacing: -1px;
        margin-left: 0.08em;
      }
    }
    em {
      font-size: 15px;
    }
  }

  .discount {
    right: -40px;
    top: -65px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    .figure {
      strong {
        font-size: 36px;
      }
      em {
        font-size: 12px;
      }
    }
  }

  // There's not enough space to render 'Add to
  // Cart', one-click checkout and the variant
  // selector. Hide the one click.
  &.multi .one-click-button {
    display: none;
  }
}

// Layout for use when we have a large horizontal
// space i.e. banners on desktop devices. Places
// the info box on top of the image, to the right.

@mixin offer_lg_layout() {
  @include banner_images();

  overflow: hidden;   // Otherwise the discount circle causes horizontal scroll

  // Themes
  &.light {
    header, footer {
      border-color: #d9d9d9;
    }
    header {
      a {
        &:hover, &:focus {
          background: #fafafa;
          color: #000;
        }
      }
    }
    .countdown {
      background: #f7f7f7;
      border-top: solid 1px #d9d9d9;
      &:before {
        background-color: #131313;
      }
    }
  }
  &.dark {
    header, footer {
      border-color: #111;
    }
    header {
      a {
        &:hover, &:focus {
          background: #131313;
          color: #fff;
        }
      }
    }
    .countdown {
      background: #131313;
      border-top-color: #000;
      &:before {
        background-color: #000;
      }
    }
  }

  header, footer {
    border: solid 1px;
  }

  .info-box {
    height: 544px;
    position: absolute;
    top: 50%;
    margin-top: -272px;
    left: 50%;
    margin-left: 155px;
    width: 415px;
  }

  header {
    margin-bottom: 3px;
    a, h1 {
      font-size: 16px;
      height: 100px;
      padding-left: 45px;
      padding-right: 45px;
    }
  }

  footer {
    .vertical-centre-outer {
      table-layout: fixed;
    }
  }

  .discount {
    width: 110px;
    height: 110px;
    top: 50%;
    right: -60px;
    margin-top: -160px;
    .figure {
      strong {
        font-size: 40px;
      }
      em {
        font-size: 16px;
      }
    }
  }

  .details {
    padding: 0 20px;
    text-align: center;
  }

  .attributes {
    line-height: 1em;
    font-size: 12px;
    margin-bottom: 10px;
    dt, dd, dl {
      display: inline-block;
    }
    dl {
      margin: 0;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    dt {
      color: $green;
      width: 20px;
      text-align: center;
      vertical-align: text-bottom;
    }
    dd {
      color: #b0b0b0;
    }
  }

  .price {
    .rrp, strong {
      line-height: 1;
    }
    .rrp {
      font-size: 36px;
      letter-spacing: -2px;
      margin-right: 10px;
      position: relative;
      top: -4px;

      &:after {
        margin-top: -4px;
        left: -6px;
        right: -6px;
        border-top-width: 2px;
      }
    }
    strong {
      font-size: 50px;
      display: inline-block;
      margin-bottom: 4px;

      sup {
        font-size: 66%;
        top: -0.38em;
        letter-spacing: 0;
        margin-left: 0.08em;
      }
    }
    em {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }

  footer {
    height: 441px;
    padding-bottom: 112px;
  }
  &.no-countdown {
    footer {
      padding-bottom: 0;
    }
  }

  .countdown {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 22px 45px 14px;
    height: 100px;
    &:before {
      color: #fff;
      display: inline-block;
      width: 140px;
      font-size: 11px;
      position: absolute;
      left: 50%;
      margin-left: -70px;
      height: 24px;
      line-height: 24px;
      top: -12px;
      @include border-radius(3px);
      content: 'Expires In';
      text-align: center;
    }
    table {
      height: 100%;
      th {
        font-size: 12px;
      }
      td {
        font-size: 44px;
        font-weight: 400;
      }
    }
  }

  .button {
    padding: 0 45px;
    .btn {
      font-size: 16px;
      padding: 15px;
      border-radius: 100px;

      &.has-subtext {
        line-height: 1em;
        padding-top: 12px;
        padding-bottom: 12px;
        span {
          margin-top: 3px;
          margin-bottom: 0;
        }
      }
    }

    .btn-success {
        padding: 10px 15px;
        background-color: #48a90f;
        border-color: #48a90f;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover,
        &:focus {
            background-color: lighten($green, 1%);
            border-color: lighten($green, 1%);
        }
    }
  }
}

// Used in the md_ and lg_ layouts - makes the
// image 635px high and scales the image to fit the
// space.
@mixin banner_images() {

  // Offer banner images should be 635px high -
  // make it so, and have the image fit the space
  picture {
    height: 635px;
    &.legacy-images {
      height: 544px;
    }
    .img-wrapper {
      height: 100%;
      position: absolute;
      left: 50%;
      width: auto;
      img {
        height: 100%;
        position: relative;
        left: -50%;
        max-width: none;
        width: auto;
      }
    }
  }
}

// ------------------------------------------------
//  Cards
// ------------------------------------------------

article.offer.card {
  @include offer_xs_layout();

  // Round the top of the images. Note, we can't
  // just clip the card with overflow: hidden
  // because that would hide the discount circle
  // too.
  picture img {
    @include border-radius($standard-form-border-radius $standard-form-border-radius 0 0);
  }

  header {
    a {
      font-size: 13px;
    }
  }

  .discount {
    right: -5px;
    top: -108px;
  }

  .price {
    font-size: 20px;
    em {
      font-size: 11px;
    }
  }

  .countdown {
    table {
      th {
        font-size: 7px;
      }
      td {
        padding-top: 2px;
        font-size: 14px;
      }
    }
  }

  // Disable dark on cards
  &.dark {
    @extend .light;
    picture {
      background-color: #fafafa;
    }
    header {
      a:focus, a:hover {
        color: $green;
      }
    }
  }
}

// ------------------------------------------------
//  Banners
// ------------------------------------------------

@media only screen and (min-width: 1200px) {
  article.offer.banner.straight {
    @include offer_lg_layout();
  }
  article.offer.banner.mixed {
    @include offer_md_layout();
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  article.offer.banner {
    @include offer_md_layout();
    overflow-x: hidden;     // Hide overflow of the ribbon
  }
}

@media only screen and (max-width: 767px) {
  article.offer.banner {
    @include offer_xs_layout();
    overflow-x: hidden;     // Hide overflow of the ribbon

    // Treat the image as square, not dynamic
    picture {
      padding-top: 100%;
      &.has-img {
        padding-top: 0;
      }
    }

    .discount {
      top: -130px;
      right: 5px;
    }

    // We have a bit more room on banners vs. cards
    // because banners go to the screen edge. Make
    // the fonts a little bigger.

    .price {
      font-size: 25px;
      .rrp {
        font-size: 18px;
        position: relative;
        top: -3px;
      }
      em {
        font-size: 12px;
      }
    }

    .countdown {
      table {
        th {
          font-size: 9px;
        }
        td {
          font-size: 18px;
        }
      }
    }

    .select-wrapper {
      select {
        font-size: 14px;
      }
    }
  }
}
