body.account-orders#index {
  .orders-table {
    td {
      vertical-align: top;
    }
    .order-cancelled {
      color: $red;
    }
  }
  .items-table {
    margin: -6px 0;
    td {
      padding: 6px 0;
      vertical-align: middle;
    }
    .thumb {
      width: 40px;
      padding-right: 10px;
    }
  }
}

body.account-orders#show {

  .tax-invoice-label {
    display: none;
  }

  .abn, .legal-name {
    display: none;
  }

  section {
    padding: 50px 0;
  }

  .order-cancelled {
    background: $red;
    border: solid 1px #b72121;
    color: $white;
  }

  .print-button {
    position: absolute;
    padding: 0;
    min-width: 0;
    right: 15px;
    top: 45px;
    color: #aaa;
    font-size: 22px;
    &:hover, &:focus {
      color: $green;
    }
  }

  h1, .h1 {
    margin-top: 40px;
    margin-bottom: 0;
  }

  .fulfilment-progress-bar-container {
    background: #eee;
    margin-top: 4px;
    @include border-radius(999px);
    overflow: hidden;
    .fulfilment-progress-bar {
      height: 6px;
      background: $blue;
    }
  }

  .fulfilment-progress {
    list-style-type: none;
    margin: 0 10px;
    margin-top: -10px;
    padding: 0;
    position: relative;

    li {
      width: 20%;
      font-size: 13px;
      padding-top: 25px;
      position: absolute;
      &:before {
        content: ' ';
        position: absolute;
        width: 14px;
        height: 14px;
        background: #eee;
        @include border-radius(100%);
        top: 0;
      }
      &.completed {
        &:before {
          background: $blue;
        }
      }
      &:first-child {
        left: -10px;
        text-align: left;
        &:before {
          left: 0;
        }
      }
      &:last-child {
        right: -10px;
        text-align: right;
        &:before {
          right: 0;
        }
      }
    }
  }
  .customer-collect-progress-bar {
    li:nth-child(2) {
      left: 33%;
      margin-left: -10%;
      text-align: center;
      &:before {
        left: 50%;
        margin-left: -7px;
      }
    }
    li:nth-child(3) {
      left: 66%;
      margin-left: -8%;
      text-align: center;
      &:before {
        margin-left: 41px;
      }
    }
  }

  .standard-progress-bar {
    li:nth-child(2) {
      left: 25%;
      margin-left: -10%;
      text-align: center;
      &:before {
        left: 50%;
        margin-left: -7px;
      }
    }
    li:nth-child(3) {
      left: 50%;
      margin-left: -7px;
      margin-left: -10%;
      text-align: center;
      &:before {
        left: 50%;
        margin-left: -7px;
      }
    }
    li:nth-child(4) {
      left: 75%;
      margin-left: -10%;
      text-align: center;
      &:before {
        left: 50%;
        margin-left: -7px;
      }
    }
  }

  .bonus-item {
    background: lighten($green, 60%);
    color: rgba(0,0,0,0.533);
  }

  .cart-table {
    margin: 0;

    .thumb-cell {
      width: 75px;
      padding-right: 0px;
    }

    .description-cell {
      a {
        color: #333;
        &[href] {
          &:hover, &:focus {
            color: $green;
          }
        }
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }

    .delivery-estimate, .explanation, .variant, .shipping-estimate-label, .revealed-identity {
      font: $sans_font;
      color: rgba(0,0,0,0.6);
      font-size: 13px;
      display: block;
      font-style: normal;
      strong {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
      }
    }
    td.money .explanation {
      white-space: normal;
    }

    tfoot {
      th {
        font-weight: $bold_font_weight;
        position: relative;
        &:after {
          position: absolute;
          content: ' ';
          right: 16px;
          top: 16px;
          bottom: 16px;
          border-right: $standard-form-border;
          width: 1px;
        }
      }
      td.remove-item-cell {
        height: 62px;
      }
      tr:not(:first-child) {
        td, th {
          border-top: none;
        }
      }
      tr.balance {
        th, td {
          font-weight: bold;
        }
      }
    }
  }

  .order-summary {
    .section.cancel {
      border-bottom: none;
      margin: 0 -1px;
      padding: 0;
      .btn-danger {
        @include border-radius(0px);
        padding: 1em;
      }
    }
    .section.wharryoo {
      p:last-child {
        margin-bottom: 5px;
      }
    }
    .section.map {
      position: relative;
      padding: 0;
      padding-top: 58%;
      #map {
        background: #111;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        cursor: default;
        @include border-radius(2px 2px 0 0);
        @include transform(translateZ(0));
        overflow: hidden;
      }
      .map-marker {
        &, .pulse {
          @include border-radius(100%);
        }
        .pulse {
          position: absolute;
          top: 0;
          left: 0;
          @include animation(pulsate 1s ease-out);
          @include animation-iteration-count(infinite);
        }
        &.current-position {
          &, .pulse {
            width: 14px;
            height: 14px;
            background: $blue;
            cursor: default;
          }
          margin-top: -7px;
          margin-left: -7px;
        }
        &.destination, &.origin {
          width: 8px;
          height: 8px;
          margin-top: -4px;
          margin-left: -4px;
          background: #aaa;
          cursor: default;
        }
      }
    }
    .section.shipping {
      p {
        margin-bottom: 5px;
      }
    }
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.account-orders#show {
    section {
      padding: 30px 0;
    }
    .print-button {
      display: none !important;
    }
    .fulfilment-progress {
      margin-bottom: 20px;
      li {
        font-size: 11px;
      }
    }
    .cart-table {
      border-top: none;
      &, tbody, tfoot, tr {
        display: block;
      }
      thead {
        display: none;
      }
      tbody {
        .order-item {
          font-size: 0px;
          position: relative;
        }
        .thumb-cell, .description-cell {
          display: block;
        }
        .quantity-cell, .total-cell {
          display: inline-block;
          width: 50%;
        }
        .thumb-cell {
          position: absolute;
          width: 55px;
        }
        .description-cell {
          font-size: $base_font_size;
          padding-left: 65px;
        }
        .quantity-cell, .total-cell {
          line-height: 30px;
          font-size: $base_font_size;
        }
      }
      tfoot {
        tr {
          position: relative;
          font-size: 0;
        }
        .code {
          display: block;
          font-size: 15px;
        }
        th, td.money {
          display: inline-block;
          width: 40%;
          vertical-align: top;
          padding: 16px;
        }
        tr:not(:first-child) {
          th, td {
            border-top: $standard_form_border;
          }
        }
        th {
          width: 64%;
          font-size: 13.5px;
          padding-right: 8px;
          .title {
            border-right: none;
          }
          &:after {
            right: 0;
          }
        }
        td.spacer {
          display: none;
        }
        td.money {
          width: 36%;
          font-size: 15px;
          padding-left: 8px;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Print
// ------------------------------------------------

@media print {
  body.account-orders#show {

    .tax-invoice-label {
      display: inline;
    }

    .abn, .legal-name {
      display: block;
    }
    .abn {
      margin-bottom: 20px;
    }
    section {
      padding: 0;
    }
    .progress-section, .notification-bar, .site-footer, .section.map {
      display: none;
    }
    a[href]:after {
      display: none;
    }
    .section.wharryoo {
      display: none;
    }

    .print-button {
        display: none !important;
    }
  }
}
