.masquerading-banner {
    font-size: 12px;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    background-color: #509fe6;

    .environment-banner ~ & {
        top: 25px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    a {
        text-decoration: none;
        border-bottom: 1px dotted #fff;
        color: #fff;

        &:hover {
            text-decoration: none;
            border-bottom: 1px solid #fff;
        }
    }
}
