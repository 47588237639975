body.contact#show {
  .side-column {
    > section {
      border-bottom: none;
    }

    .picture-wrapper {
      width: 130px;
      display: inline-block;
    }
    h4 {
      margin-bottom: 6px;
    }
    h5 {
      margin-top: 0;
    }
    dl {
      margin: 40px 0 0;
    }
    dt, dd {
      padding: 15px 0;
    }
    dt {
      float: left;
      width: 150px;
      text-align: left;
      font-weight: $base_font_weight;
      i {
        display: inline-block;
        width: 25px;
        position: relative;
        &:before {
          font-size: 24px;
          position: absolute;
          top: -17px;
          left: 0;
        }
      }
    }
    dd {
      text-align: right;
      padding-left: 120px;
      border-bottom: solid 1px #eaeaea;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .detail-column {
    background-image: image_url('site/contact/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .form-control {
      margin-bottom: 15px;
      height: 50px;
      padding: 0 1em;
      line-height: 48px;
      @include box-shadow(none);
      border: none;
      @include border-radius($standard_form_border_radius);
      background: #fafafa;
      font-size: 15px;
    }
    h3, .h3 {
      & ~ h3, & ~ .h3 {
        margin-top: 60px;
        margin-bottom: 25px;
      }
    }
    p {
      line-height: 1.6em;
      margin: 0;
    }
  }
  .broker-contact {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 490px;
    padding: 10px;
    width: 100%;

    @media only screen and (max-width: 1770px) {
      align-items: center;
      flex-direction: column;
    }

    .broker-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 310px;
      text-align: right;
      width: 100%;

      > div {
        display: flex;
        justify-content: space-between;
        padding: 5px 0px;

        @media only screen and (max-width: 1250px) {
          align-items: center;
          flex-direction: column;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Everything except large desktops
// ------------------------------------------------

@media only screen and (max-width: 1199px) {
  body.contact#show {
    .side-column {
      dt {
        font-size: 0;
        width: 25px;
        i {
          &:before {
            top: 0;
          }
        }
      }
      dd {
        padding-left: 25px;
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.contact#show {
    .detail-column {
      p {
        font-size: 14px;
      }
    }
  }
}
