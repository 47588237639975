$tablet-min-width: 768px;

body.account-addresses {
  .content-column {
    .address {
      margin-bottom: 15px;
      border: $standard_form_border;
      padding: $standard-form-ios-padding;
      @include border-radius($standard-form-border-radius);
      position: relative;
    }

    .club-address-notice {
      margin-bottom: 28px;
      border: $standard-form-border;
      padding: $standard-form-ios-padding;
      @include border-radius($standard-form-border-radius);
      background-color: #f0f0f0;
      border-color: #c0c0c0;
      color: #777777;
      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }

  .standard-form-group.delivery_instructions.text-area {
    .standard-form-control {
      min-height: 5.5em;
    }
  }

  // ------------------------------------------------
  //  Control Buttons
  // ------------------------------------------------

  .address__controls {
    position: static;
    text-align: left;
    margin: 10px 0 0 -7px;

    .btn-icon-rounded {
      position: absolute;
      top: $standard-form-ios-padding;
      right: $standard-form-ios-padding;
    }
  }

  @media only screen and (min-width: $tablet-min-width) {
    .address__controls {
      position: absolute;
      margin: 0;
      top: $standard-form-ios-padding;
      right: $standard-form-ios-padding;
      white-space: nowrap;

      .btn-icon-rounded {
        position: static;
      }
    }
  }
}

// ------------------------------------------------
//  Small Desktops
// ------------------------------------------------

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body.account-addresses {
    .standard-form-group.delivery_instructions.text-area {
      .standard-form-control {
        min-height: 6.7em;
      }
    }
  }
}

// ------------------------------------------------
//  Tablets
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.account-addresses {
    .standard-form-group.delivery_instructions.text-area {
      .standard-form-control {
        min-height: 8.1em;
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.account-addresses {
    .standard-form-group.delivery_instructions.text-area {
      .standard-form-control {
        min-height: 12.25em;
      }
    }
  }
}
