.medium-editor {
  position: relative;
}

.medium-editor-document {
  padding: 50px 0;
}
.medium-editor-document > .layout-background:first-child {
  margin-top: -50px;
}

.medium-editor-document.medium-editor-document-blank p:before,
.medium-editor-document.medium-editor-document-blank h2:before,
.medium-editor-document.medium-editor-document-blank h3:before,
.medium-editor-document.medium-editor-document-blank h4:before,
.medium-editor-document.medium-editor-document-blank blockquote:before,
.medium-editor-document.medium-editor-document-blank ol li:before,
.medium-editor-document.medium-editor-document-blank ul li:before {
  content: 'Start typing ...';
  color: rgba(0,0,0,0.2);
  position: absolute;
}

.medium-editor-document:focus {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
}

.medium-editor-document p,
.medium-editor-document blockquote,
.medium-editor-document li {
  font-family: 'Merriweather',Georgia,Cambria,"Times New Roman",Times,serif;
  letter-spacing: .01rem;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  line-height: 1.8em;
  color: rgba(0,0,0,0.8);
  word-wrap: break-word;
}
@media only screen and (max-width: 767px) {
  .medium-editor-document p,
  .medium-editor-document blockquote,
  .medium-editor-document li {
    font-size: 15px;
  }
}

.medium-editor-document h2,
.medium-editor-document h3,
.medium-editor-document h4 {
  font-family: 'Montserrat', Arial, sans-serif;
  font-style: normal;
}
.medium-editor-document h2,
.medium-editor-document h3 {
  font-weight: 600;
}
.medium-editor-document h2 {
  font-size: 70px;
  margin-top: 0;
  margin-bottom: 20px;
  letter-spacing: -0.04em;
  margin-left: -3px;
  padding-top: 0;
  line-height: 1;
}
.medium-editor-document h3 {
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  margin-bottom: 15px;
  margin-top: 0;
  margin-left: -1.8px;
}
.medium-editor-document h4 {
  font-weight: 300;
  font-size: 26px;
  color: rgba(0,0,0,0.44);
  margin-left: -1.5px;
  line-height: 1.2em;
  margin-bottom: 15px;
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .medium-editor-document h2 {
    font-size: 47px;
  }
  .medium-editor-document h3 {
    font-size: 24px;
  }
  .medium-editor-document h4 {
    font-size: 20px;
  }
}

.medium-editor-document strong {
  font-weight: 600;
}

.medium-editor-document em {
  font-style: italic;
}

.medium-editor-document p,
.medium-editor-document blockquote,
.medium-editor-document ul,
.medium-editor-document ol {
  margin-bottom: 30px;
  margin-top: 0;
}

.medium-editor-document blockquote {
  font-style: italic;
  border-left: 3px solid #60d778;
  padding-left: 20px;
  margin-left: -23px;
  padding-bottom: 3px;
}
.medium-editor-document blockquote + blockquote {
  margin-top: -30px;
  padding-top: 20px;
}
.medium-editor-document blockquote em {
  font-style: normal;
}
.medium-editor-document blockquote.pull-quote {
  font-size: 26px;
  line-height: 1.6em;
  margin: 0 -100px 48px;
  border: none;
  padding: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
}
.medium-editor-document blockquote.pull-quote em {
  font-style: normal;
}
@media only screen and (max-width: 991px) {
  .medium-editor-document blockquote.pull-quote {
    font-size: 22px;
    margin-bottom: 43px;
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media only screen and (max-width: 767px) {
  .medium-editor-document blockquote.pull-quote {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 18px;
  }
}

.medium-editor-document li {
  margin-bottom: 12px;
  margin-left: 30px;
  padding-top: 2px;
}

.medium-editor-document ol {
  list-style: decimal;
}
.medium-editor-document ul {
  list-style: disc;
}

.medium-editor-document figure {
  text-align: center;
  margin: 0 0 60px;
  color: transparent;   /* Hides the cursor */
  cursor: default;
}
.medium-editor-document figure img {
  max-width: 100%;
}
.medium-editor-document figure figcaption {
  font-family: 'Merriweather',Georgia,Cambria,"Times New Roman",Times,serif;
  letter-spacing: .01rem;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  line-height: 1.4em;
  color: rgba(0,0,0,0.6);
  word-wrap: break-word;
  margin-top: 15px;
}
.medium-editor-document figure figcaption:focus {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
}

.medium-editor-media-selected img {
  box-shadow: 0 0 0 3px #57ad68;
}

.medium-editor-document hr {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 100px;
  border-top-color: rgba(0,0,0,0.15);
}

.medium-editor-document .layout-single-column {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
}

.medium-editor-document .wide-align {
  margin-left: -160px;
  margin-right: -160px;
}
@media only screen and (max-width: 991px) {
  .medium-editor-document .wide-align {
    margin-left: -54px;
    margin-right: -54px;
  }
}
@media only screen and (max-width: 767px) {
  .medium-editor-document .wide-align {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 40px;
  }
}

.medium-editor-document .centre-align {
  text-align: center;
}

.medium-editor-document .left-align {
  width: 50%;
  float: left;
  margin-top: 15px;
  margin-right: 30px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) {
  .medium-editor-document .left-outset {
    width: 75%;
    float: left;
    margin-top: 15px;
    margin-right: 30px;
    margin-bottom: 10px;
    margin-left: -160px;
  }
}

.medium-editor-document .layout-full-width figure {
  margin-top: 80px;
  margin-bottom: 80px;
}

.medium-editor-document .layout-background {
  overflow: hidden;
}

.medium-editor-document .layout-background figure {
  float: right;
  right: 50%;
  position: relative;
}
.medium-editor-document .layout-background figure img {
  position: relative;
  right: -50%;
  max-width: none;
}
@media only screen and (max-width: 767px) {
  .medium-editor-document .layout-background {
    zoom: 0.5;
  }
  .medium-editor-document > .layout-background:first-child {
    margin-top: -100px;
  }
}

.medium-editor-document p.video-link-prompt:before {
  content: 'Paste a YouTube link, and press Enter';
  color: rgba(0,0,0,0.2);
  position: absolute;
}

.medium-editor-document .video-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
}
.medium-editor-document .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.medium-editor-document[contenteditable] .video-wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/* ------------------------------------------------
    Anchor Form Menu
   ------------------------------------------------ */

.medium-editor-toolbar-form {
  display: none;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  margin-top: -13px;
  background: #262625;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  animation: pop-upwards 300ms forwards linear;
  -webkit-animation: pop-upwards 300ms forwards linear;
  -moz-animation: pop-upwards 300ms forwards linear;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &>form {
    display: inline-block;
    padding-left: 10px;
  }
}
.medium-editor-toolbar-form:after {
  content: ' ';
  left: 50%;
  bottom: -8px;
  margin-left: -10px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: #262625;
  border-bottom-color: transparent;
  border-width: 10px 10px 0 10px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}
.medium-editor-toolbar-form-active {
  display: block;
  transition: top 0.15s ease-out, left 0.15s ease-out;
  -webkit-transition: top 0.15s ease-out, left 0.15s ease-out;
  -moz-transition: top 0.15s ease-out, left 0.15s ease-out;
  -ms-transition: top 0.15s ease-out, left 0.15s ease-out;
  -o-transition: top 0.15s ease-out, left 0.15s ease-out;
}
.medium-editor-toolbar-form-under {
  margin-top: 13px;
}
.medium-editor-toolbar-form-under:after {
  bottom: auto;
  top: -8px;
  border-width: 0 10px 10px 10px;
  border-top-color: transparent;
  border-bottom-color: #262625;
}
.medium-editor-toolbar-form button {
  background: transparent;
  color: #fff;
  padding: 12px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Merriweather',Georgia,Cambria,"Times New Roman",Times,serif;
}
.medium-editor-toolbar-form button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
}
.medium-editor-toolbar-form button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
}
.medium-editor-toolbar-form button:hover,
.medium-editor-toolbar-form button:focus {
  background: #222221;
  color: #fff;
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
}
.medium-editor-toolbar-form button.medium-editor-toolbar-form-button-active {
  color: #60d778;
}
/* ------------------------------------------------
    Highlight Menu
   ------------------------------------------------ */

.medium-editor-highlight-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  margin-top: -13px;
  background: #262625;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  animation: pop-upwards 300ms forwards linear;
  -webkit-animation: pop-upwards 300ms forwards linear;
  -moz-animation: pop-upwards 300ms forwards linear;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.medium-editor-highlight-menu:after {
  content: ' ';
  left: 50%;
  bottom: -8px;
  margin-left: -10px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: #262625;
  border-bottom-color: transparent;
  border-width: 10px 10px 0 10px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}
.medium-editor-highlight-menu-active {
  display: block;
  transition: top 0.15s ease-out, left 0.15s ease-out;
  -webkit-transition: top 0.15s ease-out, left 0.15s ease-out;
  -moz-transition: top 0.15s ease-out, left 0.15s ease-out;
  -ms-transition: top 0.15s ease-out, left 0.15s ease-out;
  -o-transition: top 0.15s ease-out, left 0.15s ease-out;
}
.medium-editor-highlight-menu-under {
  margin-top: 13px;
}
.medium-editor-highlight-menu-under:after {
  bottom: auto;
  top: -8px;
  border-width: 0 10px 10px 10px;
  border-top-color: transparent;
  border-bottom-color: #262625;
}
.medium-editor-highlight-menu button {
  background: transparent;
  color: #fff;
  padding: 12px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Merriweather',Georgia,Cambria,"Times New Roman",Times,serif;
}
.medium-editor-highlight-menu button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
}
.medium-editor-highlight-menu button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
}
.medium-editor-highlight-menu button:hover,
.medium-editor-highlight-menu button:focus {
  background: #222221;
  color: #fff;
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
}
.medium-editor-highlight-menu button.medium-editor-highlight-menu-button-active {
  color: #60d778;
}

/* ------------------------------------------------
    Inline Tooltip
   ------------------------------------------------ */

.medium-editor-inline-tooltip {
  opacity: 0;
  position: absolute;
  z-index: 1000;
  margin-left: -40px;
  margin-top: -1px;
}

.medium-editor-inline-tooltip-active {
  opacity: 1;
  transition: top 0.1s ease-out, opacity 0.15s ease-out;
  -webkit-transition: top 0.1s ease-out, opacity 0.15s ease-out;
  -moz-transition: top 0.1s ease-out, opacity 0.15s ease-out;
  -ms-transition: top 0.1s ease-out, opacity 0.15s ease-out;
  -o-transition: top 0.1s ease-out, opacity 0.15s ease-out;
}

.medium-editor-inline-tooltip button {
  border: solid 1px rgba(0,0,0,0.33);
  line-height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 32px;
  background: transparent;
  height: 32px;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  text-align: center;
  font-size: 22px;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
}

.medium-editor-inline-tooltip button:hover,
.medium-editor-inline-tooltip button:focus {
  border-color: rgba(0,0,0,0.5);
  color: rgba(0,0,0,0.75);
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
}

.medium-editor-inline-tooltip-toggle {
  color: rgba(0,0,0,0.66);
  vertical-align: top;
  transition: transform 150ms ease-in-out;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  -moz-transition: -moz-transform 150ms ease-in-out;
  -ms-transition: -ms-transform 150ms ease-in-out;
  -o-transition: -o-transform 150ms ease-in-out;
}

.medium-editor-inline-tooltip-open
.medium-editor-inline-tooltip-toggle {
  transform: rotate3d(0,0,1,45deg);
  -webkit-transform: rotate3d(0,0,1,45deg);
  -moz-transform: rotate3d(0,0,1,45deg);
  -ms-transform: rotate3d(0,0,1,45deg);
  -o-transform: rotate3d(0,0,1,45deg);
}

.medium-editor-inline-tooltip-button-set {
  display: inline-block;
}

.medium-editor-inline-tooltip-button-set button {
  color: rgba(0,0,0,0.33);
  background: #fff;
  display: none;
  margin-left: 5px;
  animation: grow-in 150ms forwards ease-in-out;
  -webkit-animation: grow-in 150ms forwards ease-in-out;
  -moz-animation: grow-in 150ms forwards ease-in-out;
}
.medium-editor-inline-tooltip-open
.medium-editor-inline-tooltip-button-set button {
  display: inline-block;
}

/* ------------------------------------------------
    Animations
   ------------------------------------------------ */

@-webkit-keyframes pop-upwards {
  0% {
    -webkit-transform:matrix(0.97,0,0,1,0,12);
    transform:matrix(0.97,0,0,1,0,12);
    opacity:0
  }
  20% {
    -webkit-transform:matrix(0.99,0,0,1,0,2);
    transform:matrix(0.99,0,0,1,0,2);
    opacity:.7
  }
  40% {
    -webkit-transform:matrix(1,0,0,1,0,-1);
    transform:matrix(1,0,0,1,0,-1);
    opacity:1
  }
  70% {
    -webkit-transform:matrix(1,0,0,1,0,0);
    transform:matrix(1,0,0,1,0,0);
    opacity:1
  }
  100% {
    -webkit-transform:matrix(1,0,0,1,0,0);
    transform:matrix(1,0,0,1,0,0);
    opacity:1
  }
}

@-webkit-keyframes grow-in {
  0% {
    -webkit-transform:scale3d(0,0,1);
    transform:scale3d(0,0,1);
  }
  100% {
    -webkit-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1);
  }
}
