// ------------------------------------------------
//  Picture elements
// ------------------------------------------------

picture {
  position: relative;
  display: block;
  background-color: #fafafa;
  background-image: asset-url('common/preload-logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200px 58.5px;
  overflow: hidden;
  line-height: 0;
  img {
    max-width: 100%;
    width: 100%;
    transition: opacity .4s ease-in;
  }
  &.square, &.small {
    padding-top: 100%;
  }
  &.medium {
    padding-top: 52.91666667%;
  }
  &.large {
    padding-top: 31.75%;
  }
  &.half {
    padding-top: 50%;
  }
  &.six-by-four {
    padding-top: 66.66666%;
  }
  &.banner-lg {
    padding-top: 48.24561404%;
  }
  &.banner-md {
    padding-top: 76.38888889%;
  }
  &.article-thumb {
    padding-top: 58.31826401%;
  }
  &.circle {
    @include border-radius(100%);
    img {
      @include border-radius(100%);
      @include transform(translateZ(0));  // Fixes a weird bug with Chrome
    }
  }
  .img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.has-img {
    padding-top: 0;
    .img-wrapper {
      width: auto;
      height: auto;
      position: static;
    }
  }
}

// Correct a pretty awful display of images up to
// IE10 - target IE 8, 9, 10
@media screen\0 {
  picture img {
    height: auto
  }
}
