body.checkout-payment {
  .joke {
    font: $sans_font;
    color: rgba(0,0,0,0.6);
    font-size: 13px;
    margin: 10px 0 0;
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.checkout-payment {
    .btn-success {
      padding: 0.9em 2em;
    }
  }
}
