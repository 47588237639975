body.invites#index, body.invites#create {

  .detail-column {
    background-image: image_url('site/invites/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-right: none;
    form {
      margin-bottom: 0;
    }
    .share-buttons-container {
      margin-bottom: 7px;
      .btn-container {
        .btn {
          width: 48%;
          padding-left: 0;
          padding-right: 0;
        }
        .btn-facebook {
          float: left;
        }
        .btn-twitter {
          float: right;
        }
      }
    }
    .divider {
      color: #c0c0c0;
      margin: 20px 0;
    }
    .email-field-container {
      margin-bottom: 14px;
      .standard-form-group {
        margin-bottom: 0;
      }
      .standard-form-control {
        height: 50px;
      }
      .standard-form-error-icon {
        top: 1em;
      }
    }
    .spam-warning {
      color: #c0c0c0;
      font-size: 12px;
      margin-top: 15px;
    }
    .terms-link {
      color: #fff;
      font-size: 15px;
      margin-top: 15px;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
      text-decoration: none;

      &:hover {
        border-bottom-style: solid;
      }
    }
  }
  .side-column {
    border-left: solid 1px #eaeaea;
    .invite {
      padding: 20px 0;
      border-bottom: solid 1px #f4f4f4;
      text-align: left;
      &:last-child {
        border-bottom: none;
      }
      .btn {
        float: right;
        padding: 0.45em 2em;
        font-size: 13px;
        min-width: 0;
        margin-top: -6px;
        i {
          font-size: 16px;
          line-height: 0;
          position: relative;
          top: 1px;
        }
      }
    }
    .members {
      position: relative;
      .bought-yet {
        font-size: 11px;
        position: absolute;
        text-align: center;
        right: -17px;
        top: 96px;
        line-height: 1.3em;
        color: #c0c0c0;
      }
    }
    .member {
      padding: 21px 40px 21px 52px;
      border-bottom: solid 1px #f4f4f4;
      text-align: left;
      min-height: 64px;
      position: relative;
      &:last-child {
        border-bottom: none;
      }
      .thumb {
        width: 40px;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        left: 0;
      }
      i {
        color: $green;
        font-size: 30px;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        right: 0;
      }
    }
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.invites#index, body.invites#create {
    .detail-column {
      .share-buttons-container {
        .btn-container {
          .btn {
            width: 100%;
          }
          .btn-facebook, .btn-twitter {
            float: none;
          }
          .btn-facebook {
            margin-bottom: 8px;
          }
        }
      }
      .email-button .btn {
        width: 100%;
      }
    }
    .side-column {
      .members {
        .bought-yet {
          right: -8px;
          width: 40px;
          top: 87px;
        }
      }
    }
  }
}
