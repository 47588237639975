body.offers#show {

  // Scroll nav
  .offer-scroll-nav {
    background: #eaeaea;
    border-bottom: solid 1px #dadada;
    padding: 0 5px;
    .select-wrapper {
      display: none;
    }
    form, .hidden-unless-js {
      display: inline-block;
    }
    form {
      position: relative;
      z-index: 1;
    }
    .btn {
      font-size: 13px;
      padding: 10px 30px;
      margin-top: 4px;
      background-color: #48a90f;
      border-color: #48a90f;
      transition: background-color 0.3s, border-color 0.3s;

      &:hover,
      &:focus {
          background-color: lighten($green, 1%);
          border-color: lighten($green, 1%);
      }

      span {
        display: none;        // The address and card details on the one-click button
      }
      &.success {
        margin-right: 5px;
      }
    }
    .hidden-unless-js {
      margin-right: -10px;
    }
    .name {
      line-height: 48px;
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
      max-width: 450px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .price {
      display: inline-block;
      font-size: 20px;
      .vertical-centre-outer {
        height: 43px;
      }
      .rrp, strong, em {
        vertical-align: middle;
        line-height: 1em;
      }
      .rrp {
        position: relative;
        font-size: 16px;
        color: $red;

        &:after {
          content: ' ';
          position: absolute;
          top: 50%;
          border-top: solid 1px;
          left: -1px;
          right: -1px;
        }
      }
      strong {
        font-weight: bold;
        letter-spacing: -0.5px;
        color: #131314;
        sup {
          top: -.27em;
        }
      }
      em {
        font: $serif_font;
        font-size: 13px;
        font-style: italic;
        color: #aaa;
        margin-left: 10px;
        position: relative;
        top: 1px;
      }
    }
  }

  // Lot's of Medium Editor documents throughout
  // the page - they don't need the top margin
  .medium-editor-document {
    margin: 0 0 50px;
    padding: 0;
  }

  // The circle icons
  .tasting-profile, .ratings-and-awards, .food-matches, .featured-review {
    margin: 30px 0 15px;
  }
  .tasting-profile, .ratings-and-awards, .food-matches {
    font-size: 0;                                         // So the inline-block circles layout nicely
  }
  .tasting-profile, .ratings-and-awards {                 // For these two, offset left so the first circle lines up with text
    margin-left: -15px;
    h4 {
      margin-left: 15px;
    }
  }
  .taste, .feel, .award, .food-match {
    display: inline-block;
    width: 95px;
    text-align: center;
    vertical-align: top;
    margin-bottom: 15px;
    padding-left: 2px;
    padding-right: 2px;
    i {
      display: block;
      margin: 0 auto 6px;
      @include border-radius(100%);
    }
    span {
      font-size: 12px;
      display: block;
    }
  }
  .taste, .feel, .food-match {
    i {
      background: #111;
      width: 65px;
      height: 65px;
      color: #fff;
      font-size: 40px;
      line-height: 65px;
    }
  }
  .award {
    i {
      font-size: 72px;
      color: #777;
      width: 65px;
      position: relative;
      height: 65px;
      &:before {
        position: relative;
        z-index: 1;
        top: -3px;
        left: -3px;
      }
    }
    &.gold i {
      color: $gold;
    }
    &.black i {
      color: #111;
    }
  }
  .taste, .feel, .food-match {
    i {
      background: #111;
    }
  }

  h5, .h5, .wine-nerds dt {
    font-size: 13px;
    text-transform: uppercase;
    color: #666;
    font-weight: bold;
    line-height: 1.4em;
    letter-spacing: 0.08em;
  }

  p.collapsed {
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 5.4em;
    -webkit-line-clamp: 3;
    display: block;
    display: -webkit-box;
    margin-bottom: 15px;
  }
  .expand-toggle {
    min-width: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 20px;
    color: #aaa;
    display: block;
    margin: 0 auto;
    i {
      margin: 0;
    }
    &.expanded {
      i {
        &:before {
          content: "\f3d8";
          position: relative;
          top: -1px;
        }
      }
    }
  }

  section {
    &.wine-nerds,
    &.food {
      padding: 80px 0;
    }

    &.info-box {     // The info box on the offer is a section
      padding: 0;

      .overflow-visible {   // Temp fix to handle long offer name
        overflow: visible;
      }
    }

    .h1 {
      margin-top: 0;
    }

    &.wine-nerds, &.recommendations {
      background: #f7f7f7;
    }

    &.reference-info {
      background: #111;
      padding: 40px 0;
      font-size: 13px;
      color: #ddd;
      i {
        color: $green;
        font-size: 20px;
        margin-right: 8px;
        vertical-align: middle;
        line-height: 1em;
        position: relative;
        top: -2px;
      }
      strong {
        font-weight: bold;
        color: inherit;
        text-transform: uppercase;
      }
    }

    &.body {
      padding: 70px 0 0;

      .featured-review {
        cite {
          display: table;
          width: 100%;
          margin-bottom: 25px;
          font-style: normal;
        }
        .picture-container, .critic-container {
          display: table-cell;
          vertical-align: middle;
        }
        .picture-container {
          width: 1%;
          padding-right: 15px;
          .picture-wrapper {
            width: 70px;
          }
        }
        .critic-container {
          h5 {
            margin-bottom: 3px;
            margin-top: 4px;
          }
          .title, .byline {
            color: #bbb;
            font-size: 14px;
          }
          .title {
            font-weight: $bold_font_weight;
            margin-bottom: 2px;
          }
        }
        blockquote {
          border-left: none;
          margin-left: 0;
          padding-left: 0;
          padding-bottom: 0;
        }
      }

      .mixed-pack-item {
        margin: 75px 0;
        .attributes {
          line-height: 1em;
          font-size: 13px;
          letter-spacing: normal;
          i {
            font-size: 16px;
            &.ion-ios-location {
              font-size: 18px;
            }
          }
          dl {
            margin: 15px 15px 0 0;
          }
          dt, dd, dl {
            display: inline-block;
          }
          dt {
            color: $green;
            width: 30px;
            text-align: center;
            vertical-align: middle;
            padding-right: 5px;
            border-right: solid 1px #eaeaea;
          }
          dd {
            color: #b0b0b0;
            line-height: 20px;
            padding-left: 7px;
          }
        }
        p {
          margin-bottom: 0;
        }
        .best-price-promise {
          margin-top: 30px;
        }
      }

      .best-price-promise {
        background: #fafafa;
        border: solid 1px #eaeaea;
        margin-top: 50px;
        padding: 25px;
        @include border-radius($standard_form_border_radius);
        .h5 {
          margin-top: 0;
        }
        p {
          font: $sans_font;
          line-height: 1.6em;
          margin-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &.wine-nerds {
      dl {
        font-size: 0;
        margin: 0;
        dt, dd {
          display: inline-block;
          width: 50%;
        }
        dd {
          margin-bottom: 10px;
          font-size: $base_font_size;
        }
      }
    }

    &.food {
      .medium-editor-document {
        padding-bottom: 0;
      }
      .food-matches {
        text-align: center;
      }
      .recipe {
        margin-top: 40px;
        &.collapsed {
          max-height: 210px;
          overflow: hidden;
        }
        ol, ul {
          margin: 0;
          padding: 0;
        }
        ul {
          margin-bottom: 30px;
        }
        li {
          font: $sans_font;
          line-height: 1.7em;
          margin-bottom: 0;
        }
        ol li {
          margin-bottom: 0.5em;
        }
      }
      h5 {
        margin: 0 0 25px;
      }
      h6 {
        font-size: $base_font_size;
        font-weight: $base_font_weight;
        color: #aaa;
        margin: 0 0 15px;
      }
      picture {
        margin-bottom: 30px;
      }
    }

    &.recommendations {
      h2 {
        margin: 0 0 20px;
      }
    }
  }
}

// ------------------------------------------------
//  Large desktops
// ------------------------------------------------

@media only screen and (min-width: 1200px) {
  body.offers#show {

    p.collapsed {
      margin-bottom: 30px;
    }

    .expand-toggle {
      position: absolute;
      right: 200px;
      margin-top: -100px;
    }

    .side-header {
      font-family: $sans_font_family;
      position: absolute;
      left: 15px;
      margin-bottom: 0;
      margin-top: -10px;
      width: 200px;
      height: 100px;
      border-right: solid 1px rgba(0,0,0,0.2);
    }

    .tasting-profile, .ratings-and-awards {
      h4 {
        margin-left: 0;
      }
    }

    .tasting-profile, .ratings-and-awards, .food-matches, .featured-review {
      .side-header {
        line-height: 85px;
      }
    }

    section {
      &.body {
        .mixed-pack-item {
          .attributes {
            dl {
              display: block;
            }
          }
        }
      }
      &.food {
        .expand-toggle {
          margin-top: -40px;
          right: 210px;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Everything except large desktops
// ------------------------------------------------

@media only screen and (max-width: 1199px) {
  body.offers#show {
    section {
      &.body {
        .mixed-pack-item {
          .side-header {
            font-size: 0;
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Tablet and mobile
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  body.offers#show {

    .side-header {
      margin-bottom: 25px;
    }

    section {

      &.reference-info {
        padding: 20px 0;
        .reference {
          padding: 5px 0 5px 30px;
          text-align: left;
          position: relative;
          i {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            left: 0;
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.offers#show {

    .offer-scroll-nav {
      form, .hidden-unless-js {
        width: 50%;
      }
      .btn {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
      .hidden-unless-js {
        margin-right: 0;
      }
    }

    section {
      &.reference-info,
      &.body,
      &.wine-nerds,
      &.food {
        padding: 60px 0;
      }

      &.body {
        padding-top: 20px;
        .featured-review {
          .picture-container {
            padding-right: 20px;
            vertical-align: top;
            .picture-wrapper {
              width: 70px;
            }
          }
          .critic-container {
            h4 {
              font-size: 26px;
            }
            .title, .byline {
              font-size: 85%;
            }
          }
        }
        .best-price-promise {
          margin-top: 30px;
        }
      }

      &.food {
        .recipe {
          &.collapsed {
            max-height: 412px;
          }
        }
      }

      &.recommendations {
        h2 {
          font-size: 40px;
        }
      }
    }
  }
}
