body.gift_cards {
  h3 {
    color: #aaa;
    margin: 0;
  }
  .url {
    color: #ccc;
    display: none;
  }
  .gift-card {
    margin-top: 2.75em;
  }
  .gift-card-container {
    display: inline-block;
  }
  p {
    margin: .8em 0 0;
  }
  .action-bar {
    margin-top: 3em;
  }
}

// ------------------------------------------------
//  Print
// ------------------------------------------------

@media print {
  body.gift_cards {
    .url {
      display: block;
    }
    .action-bar {
      display: none;
    }
    .gift-card {
      -webkit-print-color-adjust: exact;
      background-color: #111 !important;
    }
    .amount {
      color: #fff !important;
    }
    .balance {
      background-color: $green !important;
      color: #fff !important;
    }
    .code {
      background: #282828 !important;
      color: #fff !important;
    }
  }
}
