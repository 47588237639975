body.checkout-address {
  address {
    font-weight: 400;
    .controls {
      position: absolute;
      top: $standard-form-ios-padding;
      right: $standard-form-ios-padding;
      white-space: nowrap;
    }
  }

  .addressee {
    &.warning {
      .standard-form-error-message {
        background: $gold;

        &::before {
          border-bottom-color: $gold;
        }
      }

      .standard-form-error-icon {
        display: none;
      }
    }
  }
}

// ------------------------------------------------
//  Small Desktops
// ------------------------------------------------

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body.checkout-address {
    .standard-form-group.delivery_instructions.text-area {
      .standard-form-control {
        min-height: 6.6em;
      }
    }
  }
}

// ------------------------------------------------
//  Tablets
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.checkout-address {
    .standard-form-group.delivery_instructions.text-area {
      .standard-form-control {
        min-height: 9.55em;
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.checkout-address {
    address {
      .controls {
        position: static;
        text-align: right;
        margin-top: 10px;
      }
    }
    .standard-form-group.delivery_instructions.text-area {
      .standard-form-control {
        min-height: 10.8em;
      }
    }
  }
}

.standard-form.standard-form-ios {
    .standard-form-group.delivery_instructions {
        .standard-form-label {
            white-space: normal;
        }

        .standard-form-control {
            padding-bottom: 3em;


            @media only screen and (max-width: 767px) {
                padding-bottom: 1.1em;
            }
        }
    }
}
