.age_verifications {
    .container {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    h1.mofotype {
        font-size: 40px !important;
    }
}

.age-verification-modal .modal-body {
    h1.mofotype {
        font-size: 40px !important;
    }

    @media (min-width: 768px) {
        padding: 40px 60px;
    }
}

.age-verification-form {
    line-height: 1.5;

    .c-btn {
        display: block;
        margin: 20px auto 0;
    }

    form {
        padding-top: 20px;
    }

    .c-field__error {
        position: relative;
    }
}

.age-verification-footer {
    font-size: 12px;
    text-align: center;
    padding: 12px;
    margin-top: 10px;

    @media (min-width: 768px) {
        margin-top: 0;
    }
}
