$h1_base_size: 40px;
$h2_base_size: 36px;
$h3_base_size: 28px;
$h4_base_size: 24px;
$h5_base_size: 18px;
$mofotype_multiplier: 1.1;

h1, .h1 {
  font-size: $h1_base_size;
  font-weight: bold;
  margin: 0.4em 0 0.5em;
}
h2, .h2 {
  font-family: $sans_font_family;
  font-size: $h2_base_size;
  line-height: 1.3em;
}
h3, .h3 {
  line-height: 1.5;
  font-size: $h3_base_size;
  font-weight: 400;
}
h4, .h4 {
  font-size: $h4_base_size;
}
h5, .h5 {
  font-size: $h5_base_size;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #333;
}

.hlines {
  text-align: center;
  overflow: hidden;
}

.mofotype {
  text-align: center;
  font-family: $heading_font_family;
  letter-spacing: normal;
  font-weight: 400;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  color: #000;
}
h1.mofotype, .h1.mofotype {
  font-size: $h1_base_size;
}
h2.mofotype, .h2.mofotype {
  font-size: $h2_base_size;
}
h3.mofotype, .h3.mofotype {
  font-size: $h3_base_size;
}
h4.mofotype, .h4.mofotype {
  font-size: $h4_base_size;
}
h5.mofotype, .h5.mofotype {
  font-size: $h5_base_size;
}

$h1_tablet_size: 56px;
$h2_tablet_size: 28px;
$h3_tablet_size: 24px;
$h4_tablet_size: 21px;
$h5_tablet_size: 18px;

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1, .h1 {
    font-size: $h1_tablet_size;
  }
  h2, .h2 {
    font-size: $h2_tablet_size;
  }
  h3, .h3 {
    font-size: $h3_tablet_size;
  }
  h4, .h4 {
    font-size: $h4_tablet_size;
  }
  h5, .h5 {
    font-size: $h5_tablet_size;
  }
  h1.mofotype, .h1.mofotype {
    font-size: $h1_tablet_size * $mofotype_multiplier;
  }
  h2.mofotype, .h2.mofotype {
    font-size: $h2_tablet_size * $mofotype_multiplier;
  }
  h3.mofotype, .h3.mofotype {
    font-size: $h3_tablet_size * $mofotype_multiplier;
  }
  h4.mofotype, .h4.mofotype {
    font-size: $h4_tablet_size * $mofotype_multiplier;
  }
  h5.mofotype, .h5.mofotype {
    font-size: $h5_tablet_size * $mofotype_multiplier;
  }
}

$h1_mobile_size: 42px;
$h2_mobile_size: 20px;
$h3_mobile_size: 18px;
$h4_mobile_size: 16px;
$h5_mobile_size: 15px;

@media only screen and (max-width: 767px) {
  .hlines {
    &:before, &:after {
      display: none;
    }
  }
  h1, .h1 {
    font-size: $h1_mobile_size;
  }
  h2, .h2 {
    font-size: $h2_mobile_size;
  }
  h3, .h3 {
    font-size: $h3_mobile_size;
  }
  h4, .h4 {
    font-size: $h4_mobile_size;
  }
  h5, .h5 {
    font-size: $h5_mobile_size;
  }
  h1.mofotype, .h1.mofotype {
    font-size: $h1_mobile_size * $mofotype_multiplier;
  }
  h2.mofotype, .h2.mofotype {
    font-size: $h2_mobile_size * $mofotype_multiplier;
  }
  h3.mofotype, .h3.mofotype {
    font-size: $h3_mobile_size * $mofotype_multiplier;
  }
  h4.mofotype, .h4.mofotype {
    font-size: $h4_mobile_size * $mofotype_multiplier;
  }
  h5.mofotype, .h5.mofotype {
    font-size: $h5_mobile_size * $mofotype_multiplier;
  }
}
