.table {
  th.money, td.money,
  th.accounting, td.accounting {
    text-align: right;
    white-space: nowrap;
  }
  td.money {
    font: $serif_font;
    font-size: 110%;
    font-style: italic;
  }
  th.number, td.number {
    text-align: center;
  }
  td.number {
    input {
      text-align: center;
    }
  }
}
