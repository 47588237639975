@font-face {
	font-family: 'icomoon';
	src:asset-url('common/icomoon/icomoon.eot?-d3u3ml');
	src:asset-url('common/icomoon/icomoon.eot?#iefix-d3u3ml') format('embedded-opentype'),
		asset-url('common/icomoon/icomoon.woff2?-d3u3ml') format('woff2'),
		asset-url('common/icomoon/icomoon.woff?-d3u3ml') format('woff'),
		asset-url('common/icomoon/icomoon.ttf?-d3u3ml') format('truetype'),
		asset-url('common/icomoon/icomoon.svg?-d3u3ml#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="vino-icon-"], [class*=" vino-icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.vino-icon-the-vinofiles:before {
	content: "\e734";
}
.vino-icon-tie:before {
	content: "\e735";
}
.vino-icon-90-points:before {
	content: "\e600";
}
.vino-icon-91-points:before {
	content: "\e601";
}
.vino-icon-92-points:before {
	content: "\e602";
}
.vino-icon-93-points:before {
	content: "\e603";
}
.vino-icon-94-points:before {
	content: "\e604";
}
.vino-icon-95-points:before {
	content: "\e605";
}
.vino-icon-96-points:before {
	content: "\e606";
}
.vino-icon-97-points:before {
	content: "\e607";
}
.vino-icon-98-points:before {
	content: "\e608";
}
.vino-icon-99-points:before {
	content: "\e609";
}
.vino-icon-100-points:before {
	content: "\e60a";
}
.vino-icon-award:before {
	content: "\e60b";
}
.vino-icon-box-wine:before {
	content: "\e60c";
}
.vino-icon-club:before {
	content: "\e60d";
}
.vino-icon-feel-angular:before {
	content: "\e60e";
}
.vino-icon-feel-bitter:before {
	content: "\e60f";
}
.vino-icon-feel-bold:before {
	content: "\e610";
}
.vino-icon-feel-bright:before {
	content: "\e611";
}
.vino-icon-feel-chalky:before {
	content: "\e612";
}
.vino-icon-feel-chewy:before {
	content: "\e613";
}
.vino-icon-feel-coarse:before {
	content: "\e614";
}
.vino-icon-feel-concentrated:before {
	content: "\e615";
}
.vino-icon-feel-crisp:before {
	content: "\e616";
}
.vino-icon-feel-deep:before {
	content: "\e617";
}
.vino-icon-feel-delicate:before {
	content: "\e618";
}
.vino-icon-feel-dense:before {
	content: "\e619";
}
.vino-icon-feel-drying:before {
	content: "\e61a";
}
.vino-icon-feel-elegant:before {
	content: "\e61b";
}
.vino-icon-feel-ethereal:before {
	content: "\e61c";
}
.vino-icon-feel-fine:before {
	content: "\e61d";
}
.vino-icon-feel-firm:before {
	content: "\e61e";
}
.vino-icon-feel-flabby:before {
	content: "\e61f";
}
.vino-icon-feel-flat:before {
	content: "\e620";
}
.vino-icon-feel-fresh:before {
	content: "\e621";
}
.vino-icon-feel-full-bodied:before {
	content: "\e622";
}
.vino-icon-feel-grippy:before {
	content: "\e623";
}
.vino-icon-feel-hot:before {
	content: "\e624";
}
.vino-icon-feel-intense:before {
	content: "\e625";
}
.vino-icon-feel-lean:before {
	content: "\e626";
}
.vino-icon-feel-light-bodied:before {
	content: "\e627";
}
.vino-icon-feel-lightly-oaked:before {
	content: "\e628";
}
.vino-icon-feel-lively:before {
	content: "\e629";
}
.vino-icon-feel-medium-bodied:before {
	content: "\e62a";
}
.vino-icon-feel-mellow:before {
	content: "\e62b";
}
.vino-icon-feel-mouth-watering:before {
	content: "\e62c";
}
.vino-icon-feel-oaky:before {
	content: "\e62d";
}
.vino-icon-feel-off-dry:before {
	content: "\e62e";
}
.vino-icon-feel-opulent:before {
	content: "\e62f";
}
.vino-icon-feel-powerful:before {
	content: "\e630";
}
.vino-icon-feel-racy:before {
	content: "\e631";
}
.vino-icon-feel-refined:before {
	content: "\e632";
}
.vino-icon-feel-refreshing:before {
	content: "\e633";
}
.vino-icon-feel-restrained:before {
	content: "\e634";
}
.vino-icon-feel-rich:before {
	content: "\e635";
}
.vino-icon-feel-round:before {
	content: "\e636";
}
.vino-icon-feel-rustic:before {
	content: "\e637";
}
.vino-icon-feel-silky:before {
	content: "\e638";
}
.vino-icon-feel-smooth:before {
	content: "\e639";
}
.vino-icon-feel-soft:before {
	content: "\e63a";
}
.vino-icon-feel-steely:before {
	content: "\e63b";
}
.vino-icon-feel-sweet:before {
	content: "\e63c";
}
.vino-icon-feel-tart:before {
	content: "\e63d";
}
.vino-icon-feel-textured:before {
	content: "\e63e";
}
.vino-icon-feel-thin:before {
	content: "\e63f";
}
.vino-icon-feel-tight:before {
	content: "\e640";
}
.vino-icon-feel-unoaked:before {
	content: "\e641";
}
.vino-icon-feel-velvety:before {
	content: "\e642";
}
.vino-icon-feel-warm:before {
	content: "\e643";
}
.vino-icon-feel-zesty:before {
	content: "\e644";
}
.vino-icon-feel-zippy:before {
	content: "\e645";
}
.vino-icon-food-bbq:before {
	content: "\e646";
}
.vino-icon-food-beef:before {
	content: "\e647";
}
.vino-icon-food-berries:before {
	content: "\e648";
}
.vino-icon-food-blue-cheese:before {
	content: "\e649";
}
.vino-icon-food-chicken:before {
	content: "\e64a";
}
.vino-icon-food-chocolate:before {
	content: "\e64b";
}
.vino-icon-food-creamy-sauces:before {
	content: "\e64c";
}
.vino-icon-food-duck:before {
	content: "\e64d";
}
.vino-icon-food-fish:before {
	content: "\e64e";
}
.vino-icon-food-hard-cheese:before {
	content: "\e64f";
}
.vino-icon-food-lamb:before {
	content: "\e650";
}
.vino-icon-food-light-desserts:before {
	content: "\e651";
}
.vino-icon-food-light-pasta:before {
	content: "\e652";
}
.vino-icon-food-light-vegatables:before {
	content: "\e653";
}
.vino-icon-food-oysters:before {
	content: "\e654";
}
.vino-icon-food-pizza:before {
	content: "\e655";
}
.vino-icon-food-pork:before {
	content: "\e656";
}
.vino-icon-food-rabbit:before {
	content: "\e657";
}
.vino-icon-food-rich-desserts:before {
	content: "\e658";
}
.vino-icon-food-rich-pasta:before {
	content: "\e659";
}
.vino-icon-food-rich-sauces:before {
	content: "\e65a";
}
.vino-icon-food-rich-vegetables:before {
	content: "\e65b";
}
.vino-icon-food-risotto:before {
	content: "\e65c";
}
.vino-icon-food-seafood:before {
	content: "\e65d";
}
.vino-icon-food-soft-cheese:before {
	content: "\e65e";
}
.vino-icon-food-spicy-foods:before {
	content: "\e65f";
}
.vino-icon-food-tomato-sauces:before {
	content: "\e660";
}
.vino-icon-food-veal:before {
	content: "\e661";
}
.vino-icon-food-venison:before {
	content: "\e662";
}
.vino-icon-gold-medal:before {
	content: "\e663";
}
.vino-icon-grapes:before {
	content: "\e664";
}
.vino-icon-taste-allspice:before {
	content: "\e666";
}
.vino-icon-taste-almond:before {
	content: "\e667";
}
.vino-icon-taste-anise:before {
	content: "\e668";
}
.vino-icon-taste-aniseed:before {
	content: "\e669";
}
.vino-icon-taste-apple:before {
	content: "\e66a";
}
.vino-icon-taste-apricot:before {
	content: "\e66b";
}
.vino-icon-taste-artichoke:before {
	content: "\e66c";
}
.vino-icon-taste-asparagus:before {
	content: "\e66d";
}
.vino-icon-taste-bacon:before {
	content: "\e66e";
}
.vino-icon-taste-banana:before {
	content: "\e66f";
}
.vino-icon-taste-barley-sugar:before {
	content: "\e670";
}
.vino-icon-taste-barnyard:before {
	content: "\e671";
}
.vino-icon-taste-basil:before {
	content: "\e672";
}
.vino-icon-taste-bay-leaf:before {
	content: "\e673";
}
.vino-icon-taste-beetroot:before {
	content: "\e674";
}
.vino-icon-taste-berries:before {
	content: "\e675";
}
.vino-icon-taste-biscuity:before {
	content: "\e676";
}
.vino-icon-taste-black-cherry:before {
	content: "\e677";
}
.vino-icon-taste-black-olive:before {
	content: "\e678";
}
.vino-icon-taste-blackberry:before {
	content: "\e679";
}
.vino-icon-taste-blackcurrant-leaf:before {
	content: "\e67a";
}
.vino-icon-taste-blackcurrant:before {
	content: "\e67b";
}
.vino-icon-taste-blood-plum:before {
	content: "\e67c";
}
.vino-icon-taste-blossoms:before {
	content: "\e67d";
}
.vino-icon-taste-blueberry:before {
	content: "\e67e";
}
.vino-icon-taste-boiled-lollies:before {
	content: "\e67f";
}
.vino-icon-taste-bread:before {
	content: "\e680";
}
.vino-icon-taste-briary:before {
	content: "\e681";
}
.vino-icon-taste-brioche:before {
	content: "\e682";
}
.vino-icon-taste-burnt-toast:before {
	content: "\e683";
}
.vino-icon-taste-butterscotch:before {
	content: "\e684";
}
.vino-icon-taste-buttery:before {
	content: "\e685";
}
.vino-icon-taste-cabbage:before {
	content: "\e686";
}
.vino-icon-taste-candied:before {
	content: "\e687";
}
.vino-icon-taste-capsicum:before {
	content: "\e688";
}
.vino-icon-taste-caramel:before {
	content: "\e689";
}
.vino-icon-taste-cashew:before {
	content: "\e68a";
}
.vino-icon-taste-cat-s-pee:before {
	content: "\e68b";
}
.vino-icon-taste-cedar:before {
	content: "\e68c";
}
.vino-icon-taste-charred:before {
	content: "\e68d";
}
.vino-icon-taste-cheese:before {
	content: "\e68e";
}
.vino-icon-taste-cherry:before {
	content: "\e68f";
}
.vino-icon-taste-chestnut:before {
	content: "\e690";
}
.vino-icon-taste-chocolate:before {
	content: "\e691";
}
.vino-icon-taste-cigarbox:before {
	content: "\e692";
}
.vino-icon-taste-cinnamon:before {
	content: "\e693";
}
.vino-icon-taste-citrus:before {
	content: "\e694";
}
.vino-icon-taste-cloves:before {
	content: "\e695";
}
.vino-icon-taste-cocoa:before {
	content: "\e696";
}
.vino-icon-taste-coconut:before {
	content: "\e697";
}
.vino-icon-taste-coffee-beans:before {
	content: "\e698";
}
.vino-icon-taste-coffee:before {
	content: "\e699";
}
.vino-icon-taste-confectionary:before {
	content: "\e69a";
}
.vino-icon-taste-cranberry:before {
	content: "\e69b";
}
.vino-icon-taste-creamy:before {
	content: "\e69c";
}
.vino-icon-taste-cucumber:before {
	content: "\e69d";
}
.vino-icon-taste-cumquat:before {
	content: "\e69e";
}
.vino-icon-taste-dark-chocolate:before {
	content: "\e69f";
}
.vino-icon-taste-dill:before {
	content: "\e6a0";
}
.vino-icon-taste-dough:before {
	content: "\e6a1";
}
.vino-icon-taste-dried-apricot:before {
	content: "\e6a2";
}
.vino-icon-taste-dried-bark:before {
	content: "\e6a3";
}
.vino-icon-taste-dried-flowers:before {
	content: "\e6a4";
}
.vino-icon-taste-dried-leaves:before {
	content: "\e6a5";
}
.vino-icon-taste-earthy:before {
	content: "\e6a6";
}
.vino-icon-taste-elderflower:before {
	content: "\e6a7";
}
.vino-icon-taste-eucalyptus:before {
	content: "\e6a8";
}
.vino-icon-taste-feral:before {
	content: "\e6a9";
}
.vino-icon-taste-figs:before {
	content: "\e6aa";
}
.vino-icon-taste-flinty:before {
	content: "\e6ab";
}
.vino-icon-taste-floral:before {
	content: "\e6ac";
}
.vino-icon-taste-forest-floor:before {
	content: "\e6ad";
}
.vino-icon-taste-fresh-cut-grass:before {
	content: "\e6ae";
}
.vino-icon-taste-fruit-salad:before {
	content: "\e6af";
}
.vino-icon-taste-fruitcake:before {
	content: "\e6b0";
}
.vino-icon-taste-fruity:before {
	content: "\e6b1";
}
.vino-icon-taste-gamey:before {
	content: "\e6b2";
}
.vino-icon-taste-geranium:before {
	content: "\e6b3";
}
.vino-icon-taste-gingerbread:before {
	content: "\e6b4";
}
.vino-icon-taste-gooseberry:before {
	content: "\e6b5";
}
.vino-icon-taste-grapefruit:before {
	content: "\e6b6";
}
.vino-icon-taste-grapey:before {
	content: "\e6b7";
}
.vino-icon-taste-grassy:before {
	content: "\e6b8";
}
.vino-icon-taste-green-apple:before {
	content: "\e6b9";
}
.vino-icon-taste-green-bean:before {
	content: "\e6ba";
}
.vino-icon-taste-green-olive:before {
	content: "\e6bb";
}
.vino-icon-taste-guava:before {
	content: "\e6bc";
}
.vino-icon-taste-hay:before {
	content: "\e6bd";
}
.vino-icon-taste-hazelnut:before {
	content: "\e6be";
}
.vino-icon-taste-herbaceous:before {
	content: "\e6bf";
}
.vino-icon-taste-honey:before {
	content: "\e6c0";
}
.vino-icon-taste-honeysuckle:before {
	content: "\e6c1";
}
.vino-icon-taste-jammy:before {
	content: "\e6c2";
}
.vino-icon-taste-jasmine:before {
	content: "\e6c3";
}
.vino-icon-taste-jubie:before {
	content: "\e6c4";
}
.vino-icon-taste-kerosene:before {
	content: "\e6c5";
}
.vino-icon-taste-kiwi:before {
	content: "\e6c6";
}
.vino-icon-taste-lanolin:before {
	content: "\e6c7";
}
.vino-icon-taste-lantana:before {
	content: "\e6c8";
}
.vino-icon-taste-lavender:before {
	content: "\e6c9";
}
.vino-icon-taste-leafy:before {
	content: "\e6ca";
}
.vino-icon-taste-leather:before {
	content: "\e6cb";
}
.vino-icon-taste-leesy:before {
	content: "\e6cc";
}
.vino-icon-taste-lemon-zest:before {
	content: "\e6cd";
}
.vino-icon-taste-lemon:before {
	content: "\e6ce";
}
.vino-icon-taste-licorice:before {
	content: "\e6cf";
}
.vino-icon-taste-lime:before {
	content: "\e6d0";
}
.vino-icon-taste-lychee:before {
	content: "\e6d1";
}
.vino-icon-taste-malty:before {
	content: "\e6d2";
}
.vino-icon-taste-mandarin:before {
	content: "\e6d3";
}
.vino-icon-taste-mango:before {
	content: "\e6d4";
}
.vino-icon-taste-marjoram:before {
	content: "\e6d5";
}
.vino-icon-taste-marmalade:before {
	content: "\e6d6";
}
.vino-icon-taste-marmite:before {
	content: "\e6d7";
}
.vino-icon-taste-marzipan:before {
	content: "\e6d8";
}
.vino-icon-taste-meaty:before {
	content: "\e6d9";
}
.vino-icon-taste-melon:before {
	content: "\e6da";
}
.vino-icon-taste-menthol:before {
	content: "\e6db";
}
.vino-icon-taste-metallic:before {
	content: "\e6dc";
}
.vino-icon-taste-minerally:before {
	content: "\e6dd";
}
.vino-icon-taste-mint:before {
	content: "\e6de";
}
.vino-icon-taste-mocha:before {
	content: "\e6df";
}
.vino-icon-taste-molasses:before {
	content: "\e6e0";
}
.vino-icon-taste-mossy:before {
	content: "\e6e1";
}
.vino-icon-taste-mousey:before {
	content: "\e6e2";
}
.vino-icon-taste-mulberry:before {
	content: "\e6e3";
}
.vino-icon-taste-mushroom:before {
	content: "\e6e4";
}
.vino-icon-taste-musk:before {
	content: "\e6e5";
}
.vino-icon-taste-nectarine:before {
	content: "\e6e6";
}
.vino-icon-taste-nettles:before {
	content: "\e6e7";
}
.vino-icon-taste-nougat:before {
	content: "\e6e8";
}
.vino-icon-taste-nutmeg:before {
	content: "\e6e9";
}
.vino-icon-taste-nutty:before {
	content: "\e6ea";
}
.vino-icon-taste-oaky:before {
	content: "\e6eb";
}
.vino-icon-taste-orange-blossom:before {
	content: "\e6ec";
}
.vino-icon-taste-orange-peel:before {
	content: "\e6ed";
}
.vino-icon-taste-orange:before {
	content: "\e6ee";
}
.vino-icon-taste-oxidised:before {
	content: "\e6ef";
}
.vino-icon-taste-oyster-shell:before {
	content: "\e6f0";
}
.vino-icon-taste-passionfruit:before {
	content: "\e6f1";
}
.vino-icon-taste-peach:before {
	content: "\e6f2";
}
.vino-icon-taste-pear:before {
	content: "\e6f3";
}
.vino-icon-taste-peas:before {
	content: "\e6f4";
}
.vino-icon-taste-pencil-shavings:before {
	content: "\e6f5";
}
.vino-icon-taste-pepper:before {
	content: "\e6f6";
}
.vino-icon-taste-peppermint:before {
	content: "\e6f7";
}
.vino-icon-taste-perfumed:before {
	content: "\e6f8";
}
.vino-icon-taste-petrol:before {
	content: "\e6f9";
}
.vino-icon-taste-pine:before {
	content: "\e6fa";
}
.vino-icon-taste-pineapple:before {
	content: "\e6fb";
}
.vino-icon-taste-plum:before {
	content: "\e6fc";
}
.vino-icon-taste-popcorn:before {
	content: "\e6fd";
}
.vino-icon-taste-potpourri:before {
	content: "\e6fe";
}
.vino-icon-taste-potting-soil:before {
	content: "\e6ff";
}
.vino-icon-taste-prunes:before {
	content: "\e700";
}
.vino-icon-taste-quince-paste:before {
	content: "\e701";
}
.vino-icon-taste-quince:before {
	content: "\e702";
}
.vino-icon-taste-raisin:before {
	content: "\e703";
}
.vino-icon-taste-raspberry:before {
	content: "\e704";
}
.vino-icon-taste-redcurrant:before {
	content: "\e705";
}
.vino-icon-taste-rhubarb:before {
	content: "\e706";
}
.vino-icon-taste-rockmelon:before {
	content: "\e707";
}
.vino-icon-taste-rose-petal:before {
	content: "\e708";
}
.vino-icon-taste-saffron:before {
	content: "\e709";
}
.vino-icon-taste-sage:before {
	content: "\e70a";
}
.vino-icon-taste-salami:before {
	content: "\e70b";
}
.vino-icon-taste-salty:before {
	content: "\e70c";
}
.vino-icon-taste-sandalwood:before {
	content: "\e70d";
}
.vino-icon-taste-savoury:before {
	content: "\e70e";
}
.vino-icon-taste-sawdust:before {
	content: "\e70f";
}
.vino-icon-taste-slate:before {
	content: "\e710";
}
.vino-icon-taste-smoky:before {
	content: "\e711";
}
.vino-icon-taste-sour-cherry:before {
	content: "\e712";
}
.vino-icon-taste-soy:before {
	content: "\e713";
}
.vino-icon-taste-spearmint:before {
	content: "\e714";
}
.vino-icon-taste-spice:before {
	content: "\e715";
}
.vino-icon-taste-spicebox:before {
	content: "\e716";
}
.vino-icon-taste-stewed-fruits:before {
	content: "\e717";
}
.vino-icon-taste-strawberry:before {
	content: "\e718";
}
.vino-icon-taste-struck-match:before {
	content: "\e719";
}
.vino-icon-taste-sweet:before {
	content: "\e71a";
}
.vino-icon-taste-tarry:before {
	content: "\e71b";
}
.vino-icon-taste-tea:before {
	content: "\e71c";
}
.vino-icon-taste-thyme:before {
	content: "\e71d";
}
.vino-icon-taste-toast:before {
	content: "\e71e";
}
.vino-icon-taste-tobacco:before {
	content: "\e71f";
}
.vino-icon-taste-toffee:before {
	content: "\e720";
}
.vino-icon-taste-tomato-leaf:before {
	content: "\e721";
}
.vino-icon-taste-tomato:before {
	content: "\e722";
}
.vino-icon-taste-treacle:before {
	content: "\e723";
}
.vino-icon-taste-tropical-fruit:before {
	content: "\e724";
}
.vino-icon-taste-truffle:before {
	content: "\e725";
}
.vino-icon-taste-umami:before {
	content: "\e726";
}
.vino-icon-taste-undergrowth:before {
	content: "\e727";
}
.vino-icon-taste-vanilla:before {
	content: "\e728";
}
.vino-icon-taste-vegemite:before {
	content: "\e729";
}
.vino-icon-taste-vegetative:before {
	content: "\e72a";
}
.vino-icon-taste-violets:before {
	content: "\e72b";
}
.vino-icon-taste-walnut:before {
	content: "\e72c";
}
.vino-icon-taste-white-peach:before {
	content: "\e72d";
}
.vino-icon-taste-white-pepper:before {
	content: "\e72e";
}
.vino-icon-taste-wild-berries:before {
	content: "\e72f";
}
.vino-icon-taste-woody:before {
	content: "\e730";
}
.vino-icon-taste-yeasty:before {
	content: "\e731";
}
.vino-icon-taste-yoghurt:before {
	content: "\e732";
}
