#geolocate-address-button {
  position: absolute;
  top: -2.85em;
  right: 0.85em;
  min-width: 0;
  padding: 0.4em 0.66em;
  z-index: 10;
  @include border-radius($standard-form-border-radius);
  i {
    margin-right: 0;
  }
  &[disabled],
  &:disabled,
  &.disabled {
    background: #fafafa;
    @extend .ion-ios-refresh-empty;
    &:before {
      color: #aaa;
      font-size: 22px;
      @include animation(spin 0.5s infinite linear);
    }
  }
}
