.environment-banner {
    font-size: 13px;
    font-weight: 400;
    padding: 5px 10px;
    text-align: center;
    color: #fff;

    &.development {
        background-color: #74b446;
    }

    &.review {
        background-color: #ffa742;
    }

    &.staging {
        background-color: #ff7b85;
    }
}
