.select2 {
  width: 100% !important;
}
.select2-dropdown {
  border: $standard-form-border;
  border-radius: $standard-form-border-radius;
}
.select2-container--default {
  &.select2-container--focus {
    .select2-selection {
      border-color: $green;
    }
  }

  .select2-selection__rendered,
  .select2-results__option {
    .select2-image-wrapper {
      display: inline;
    }
    img {
      width: 28px;
      position: relative;
      @include border-radius(2px);
    }
  }
  .select2-selection__rendered img {
    top: -1px;
    left: -4px;
    margin-right: 5px;
  }
  .select2-results__option img {
    left: 1;
    margin-right: 10px;
  }

  .select2-selection {
    border: $standard-form-border;
    border-radius: $standard-form-border-radius;
    height: auto;

    &.select2-selection--single .select2-selection__rendered {
      color: inherit;
      height: 40px;
      line-height: 39px;
      padding-left: 0.75em;
      padding-right: 0.75em;
      @extend .ion-chevron-down;
      &:before {
        color: #aaa;
        z-index: 2;
        position: absolute;
        right: 1.6em;
        top: 50%;
        margin-top: -5px;
        font-size: 10px;
        cursor: pointer;
        pointer-events: none;
      }
      .select2-selection__placeholder {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .select2-selection__arrow {
      display: none;
    }
    .select2-selection__choice {
      border-radius: $standard-form-border-radius;
      border: none;
      background: $blue;
      color: #fff;
      padding: 4px 10px 6px 26px;
      white-space: normal;
      position: relative;
    }
    .select2-selection__choice__remove {
      color: #fff;
      opacity: 0.9;
      position: absolute;
      top: 5px;
      left: 10px;
      &:hover {
        opacity: 1;
        color: #fff;
      }
    }
    .select2-search {
      margin-top: 5px;
      margin-right: 5px;
    }
    .select2-search__field {
      margin-top: 0;
      line-height: 31px;
    }
  }

  .select2-search__field {
    margin: 0;
    padding-left: 6.5px;
    border: none !important;
    &:focus {
      outline: none;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background: $blue;
  }
  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: $green;
    }
  }
}
