// ------------------------------------------------
//  Column resets
// ------------------------------------------------

.row.reset-columns {
  .col-xs-1 { &:nth-child(12n+1) { clear: both } &:not(:nth-child(12n+1)) { clear: none } }
  .col-xs-2 { &:nth-child(6n+1) { clear: both } &:not(:nth-child(6n+1)) { clear: none } }
  .col-xs-3 { &:nth-child(4n+1) { clear: both } &:not(:nth-child(4n+1)) { clear: none } }
  .col-xs-4 { &:nth-child(3n+1) { clear: both } &:not(:nth-child(3n+1)) { clear: none } }
  .col-xs-6 { &:nth-child(2n+1) { clear: both } &:not(:nth-child(2n+1)) { clear: none } }
}
@media (min-width: 768px) {
  .row.reset-columns {
    .col-sm-1 { &:nth-child(12n+1) { clear: both } &:not(:nth-child(12n+1)) { clear: none } }
    .col-sm-2 { &:nth-child(6n+1) { clear: both } &:not(:nth-child(6n+1)) { clear: none } }
    .col-sm-3 { &:nth-child(4n+1) { clear: both } &:not(:nth-child(4n+1)) { clear: none } }
    .col-sm-4 { &:nth-child(3n+1) { clear: both } &:not(:nth-child(3n+1)) { clear: none } }
    .col-sm-6 { &:nth-child(2n+1) { clear: both } &:not(:nth-child(2n+1)) { clear: none } }
  }
}
@media (min-width: 992px) {
  .row.reset-columns {
    .col-md-1 { &:nth-child(12n+1) { clear: both } &:not(:nth-child(12n+1)) { clear: none } }
    .col-md-2 { &:nth-child(6n+1) { clear: both } &:not(:nth-child(6n+1)) { clear: none } }
    .col-md-3 { &:nth-child(4n+1) { clear: both } &:not(:nth-child(4n+1)) { clear: none } }
    .col-md-4 { &:nth-child(3n+1) { clear: both } &:not(:nth-child(3n+1)) { clear: none } }
    .col-md-6 { &:nth-child(2n+1) { clear: both } &:not(:nth-child(2n+1)) { clear: none } }
  }
}
@media (min-width: 1200px) {
  .row.reset-columns {
    .col-lg-1 { &:nth-child(12n+1) { clear: both } &:not(:nth-child(12n+1)) { clear: none } }
    .col-lg-2 { &:nth-child(6n+1) { clear: both } &:not(:nth-child(6n+1)) { clear: none } }
    .col-lg-3 { &:nth-child(4n+1) { clear: both } &:not(:nth-child(4n+1)) { clear: none } }
    .col-lg-4 { &:nth-child(3n+1) { clear: both } &:not(:nth-child(3n+1)) { clear: none } }
    .col-lg-6 { &:nth-child(2n+1) { clear: both } &:not(:nth-child(2n+1)) { clear: none } }
  }
}

// ------------------------------------------------
//  Centered columns
// ------------------------------------------------

.row.centre-columns {
  font-size: 0;
  text-align: center;
  div[class^='col-'] {
    float: none;
    display: inline-block;
  }
}
