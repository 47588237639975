address {
  margin: 0;
  p {
    margin-bottom: 5px;
  }
}
.standard-form-group.delivery_instructions {
  .standard-form-control {
    resize: none;
  }
}
.customer-collect-address {
  border-left: $standard-form-border;
  border-right: $standard-form-border;
  line-height: 1.6em;
  margin-bottom: -1px;
  margin-top: -1px;
  padding: $standard-form-ios-padding * 1.5 $standard-form-ios-padding $standard-form-ios-padding * 1.5 3.5em

}

.customer-collect-instructions {
  white-space: normal;
}
