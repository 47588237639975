@-webkit-keyframes moveUp {
	0% {
		-webkit-transform: translateY(50%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes moveUp {
	0% {
		-moz-transform: translateY(50%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@keyframes moveUp {
	0% {
		transform: translateY(50%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}


@-webkit-keyframes fadeIn {
    0% { opacity: 0 }
    100% { opacity: 1 }
}
@-moz-keyframes fadeIn {
    0% { opacity: 0 }
    100% { opacity: 1 }
}
@-o-keyframes fadeIn {
    0% { opacity: 0 }
    100% { opacity: 1 }
}
@keyframes fadeIn {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
	}
}

@keyframes shake {
  0% {
    transform: translate(0px, 0);
  }
  12% {
    transform: translate(-4px, 0);
  }
  25% {
    transform: translate(0px, 0);
  }
  37% {
    transform: translate(4px, 0);
  }
  50% {
    transform: translate(0px, 0);
  }
  62% {
    transform: translate(-4px, 0);
  }
  75% {
    transform: translate(0px, 0);
  }
  87% {
    transform: translate(4px, 0);
  }
  100% {
    transform: translate(0px, 0);
  }
}

@-webkit-keyframes pulse
{
  0% {
    box-shadow:0 0 0 0 rgba(100,100,100,1),
  }
  100% {
    box-shadow:0 0 2px 5px rgba(100,100,100,0);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(1, 1); opacity: 0.8;}
  100% {-webkit-transform: scale(4, 4); opacity: 0.0;}
}
@-moz-keyframes pulsate {
	0% { -moz-transform: scale(1, 1); opacity: 0.8;}
  100% { -moz-transform: scale(4, 4); opacity: 0.0;}
}
@keyframes pulsate {
	0% { transform: scale(1, 1); opacity: 0.8;}
  100% { transform: scale(4, 4); opacity: 0.0;}
}

@-webkit-keyframes swipe-prompt-1 {
	0% {
    opacity: 1;
  }
	25% {
    opacity: 1;
  }
	40% {
		opacity: 0;
	}
	85% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes swipe-prompt-2 {
	0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
	50% {
    opacity: 1;
  }
	65% {
	  opacity: 0;
	}
	100% {
	  opacity: 0;
	}
}
@-webkit-keyframes swipe-prompt-3 {
	0% {
		opacity: 1;
	}
	15% {
		opacity: 0;
	}
  35% {
    opacity: 0;
  }
	50% {
	  opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes swipe-prompt-1 {
	0% {
    opacity: 1;
  }
	25% {
    opacity: 1;
  }
	40% {
		opacity: 0;
	}
	85% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes swipe-prompt-2 {
	0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
	50% {
    opacity: 1;
  }
	65% {
	  opacity: 0;
	}
	100% {
	  opacity: 0;
	}
}
@-moz-keyframes swipe-prompt-3 {
	0% {
		opacity: 1;
	}
	15% {
		opacity: 0;
	}
  35% {
    opacity: 0;
  }
	50% {
	  opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes swipe-prompt-1 {
	0% {
    opacity: 1;
  }
	25% {
    opacity: 1;
  }
	40% {
		opacity: 0;
	}
	85% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes swipe-prompt-2 {
	0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
	50% {
    opacity: 1;
  }
	65% {
	  opacity: 0;
	}
	100% {
	  opacity: 0;
	}
}
@keyframes swipe-prompt-3 {
	0% {
		opacity: 1;
	}
	15% {
		opacity: 0;
	}
  35% {
    opacity: 0;
  }
	50% {
	  opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes tick-bottom {
  0% { height: 0; }
	50% { height: 22px; }
  100% { height: 22px; }
}
@-webkit-keyframes tick-top {
  0% { height: 0; }
	50% { height: 0; }
  100% { height: 48px; }
}
