.card {
  background: #fff;
  border: $standard-form-border;
  margin-bottom: 30px;
  position: relative;
  @include border-radius($standard-form-border-radius);
  @include box-shadow(2px 2px 0px rgba(0, 0, 0, 0.05));
  &.padded {
    padding: 30px;
  }
  .card-annotation {
    text-align: center;
    color: #aaa;
    font-size: 80%;
    padding: 15px;
  }
  a:hover, a:focus {
    text-decoration: none;
    .card-annotation {
      color: $green;
    }
  }
}

.card-title {
    margin: 0 0 15px;
    font-size: 24px;
}


.card-panel {
    padding: 15px;
}

.card-footer {
    background: #fafafa;
    border-top: solid 1px rgba(0, 0, 0, 0.035);
    padding: 15px;
    margin: 15px -15px -15px;
    line-height: 14px;
    color: #bbb;
}
