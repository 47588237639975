body.home {
  section.quote .h2 {
    margin: auto auto;
    max-width: 650px;
  }

  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background: #111;
    z-index: 50;
    video {
      display: block;
      width: 100%;
      height: 100%;
    }
    button.close {
      @extend .ion-ios-close-empty;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 40px;
      color: #fff;
      opacity: .5;
      z-index: 1;
      width: 40px;
      height: 40px;
      line-height: 1em;
      @include border-radius(100%);
      &:hover {
        background: rgba(255,255,255,0.2);
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .play-video {
    display: block;
    @include appearance(none);
    background: none;
    @extend .ion-ios-play;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    @include opacity(0.8);
    border: solid 3px #fff;
    @include border-radius(100%);
    color: #fff;
    &:focus {
      outline: none;
    }
    &:hover, &:focus {
      @include opacity(1);
    }
    &:before {
      position: relative;
      left: 3px;
      font-size: 46px;
      line-height: 55px;
    }
  }

  section {
    &.awards {
      margin-bottom: 20px;
      .award {
        text-align: left;
        padding: 20px 0 20px 40px;
        position: relative;
        border-bottom: solid 1px #eaeaea;
        i {
          font-size: 30px;
          color: $green;
          position: absolute;
          top: 18px;
          left: 0;
        }
        span, h4 {
          text-transform: uppercase;
        }
        span {
          color: #aaa;
          font-size: 13px;
        }
        h4 {
          font-size: 20px;
          margin: 5px 0 0;
        }
        h5 {
          font-size: 16px;
          color: #999;
          font-weight: $base_font_weight;
        }
      }
      .col-sm-6 {
        &:nth-child(5), &:nth-child(6) {
          .award {
            border-bottom: none;
          }
        }
      }
    }
    &.as-seen-in {
      text-align: center;
      line-height: 56px;
      img {
        opacity: 0.3;
        display: inline-block;
        max-width: 100%;
      }
    }
  }
}

// ------------------------------------------------
//  Mobile and Tablet
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  body.home {
    section {
      &.awards {
        .award {
          span {
            font-size: 12px;
          }
          h4 {
            font-size: 18px;
          }
          h5 {
            font-size: 14px;
          }
          i {
            font-size: 26px;
            left: 6px;
            top: 19px;
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.home {
    section {
      &.awards {
        padding: 60px 0;
        .col-sm-6:nth-child(5) {
          .award {
            border-bottom: solid 1px #eaeaea;
          }
        }
      }
    }
  }
}
