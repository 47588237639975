.two-column-layout {
  display: table;
  width: 100%;
  table-layout: fixed;
  .side-column, .detail-column {
    display: table-cell;
    vertical-align: top;
    text-align: center;
    &:first-child {
      border-right: solid 1px #eaeaea;
    }
  }
  .side-column {
    width: 30%;
    padding: 50px 35px;
    background: #fff;
    h4, .h4 {
      font-weight: 400;
      color: #555;
      font-size: 22px;
      line-height: 1.4em;
    }
    h5, .h5 {
      font-weight: 400;
      color: #aaa;
      font-size: 15px;
    }
    section {
      padding: 50px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &:not(:last-child) {
        border-bottom: solid 1px #eaeaea;
      }
    }
  }
  .detail-column {
    width: 70%;
    padding: 50px 60px;
    h1, .h1 {
      color: #fff;
      margin-top: 0;
      margin-bottom: 32px;
      &:before, &:after {
        border-bottom-color: rgba(255,255,255,0.3);
      }
    }
    h3.serif, .h3.serif {
      color: rgba(255,255,255,0.75);
      font-style: italic;
      font-size: 24px;
      margin-bottom: 35px;
      font-weight: 400;
      strong {
        color: #fff;
        font-weight: bold;
      }
    }
    .detail-box {
      background: #fff;
      padding: 60px 70px;
      margin-bottom: 50px;
      max-width: 660px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      h3, .h3 {
        line-height: 1.3em;
        margin-top: 0;
        margin-bottom: 35px;
      }
    }
  }
}

// ------------------------------------------------
//  Tablets and mobiles
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  .two-column-layout {
    .detail-column {
      display: table-caption;
    }
    .side-column, .detail-column {
      width: 100%;
      &:first-child {
        border-right: none;
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  .two-column-layout {
    .detail-column {
      padding: 15px;
      h1, .h1 {
        font-size: 52px;
        margin-top: 21px;
      }
      h3.serif, .h3.serif {
        font-size: 16px;
      }
      .detail-box {
        padding: 30px 20px;
      }
    }
  }
}
