.location-selection {
  form {
    margin: 0;
    display: inline-block;
  }
  .flag {
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: -1px;
  }
  .overlay-container {
    position: relative;
    display: inline-block;
    .text-button {
      @include appearance(none);
      border: none;
      background: none;
      color: #777;
      padding: 0;
      border-bottom: dotted 1px #777;
    }
    .standard-form-group {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
  .submit-button {
    margin-left: 15px;
  }
}
