// Typography
$base_font_family: 'Montserrat', sans-serif;

$clubs_heading_font_family: 'Montserrat', sans-serif;

// Font sizes
$h1_font_size: 52px;
$h2_font_size: 40px;
$h3_font_size: 20px;
$h4_font_size: 16px;
$base_font_size: 24px;
$base_font_weight: 100;

// Section sizes

$section_width: 100%;
$section_height: 840px;

// Colours
$white: #FFF;
$light_gray: #454545;
$dark_gray: #222222;
$black: #111111;
$faq_card: #FAF9F7;
$options_color: #000;
$border_green: #6da304;
$gold: #e4b241;
$pewter: #b3b5b7;
$error_red: #d64e4e;

body.wine_clubs-subscriptions,
body.wine_clubs-addresses,
body.wine_clubs-credit_cards,
body.wine_clubs-sessions,
body.wine_clubs-registrations {
  section {
    background-color: $black;
    font-family: $base_font_family;
    font-size: $base_font_size;
    font-weight: $base_font_weight;
    min-height: $section_height;
    padding-top: 60px;
    width: $section_width;
  }

  h1,
  h2,
  h3,
  h4 {
    color: $white;
    font-family: $clubs_heading_font_family;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  h1 { font-size: $h1_font_size; }
  h2 { font-size: $h2_font_size; }
  h3 { font-size: $h3_font_size; }
  h4 { font-size: $h4_font_size; }

  .content {
    min-height: 420px;
  }

  .banner {
    background-color: $dark_gray;
    color: $white;
    padding: 1em 0;

    .club {
      &::after {
        content: ' - ';
      }
    }

    .deal {
      color: $green;
      span::after {
        content: ' / ';
      }

      span:last-of-type::after {
        content: '';
      }
    }

    .price {
      float: right;
    }
  }

  .nav-controls {
    display: flex;
    flex-direction: column-reverse;
  }

  .btn.btn-success {
    background-color: $green;
    border: 1px solid $border_green;
    border-radius: 999px;
    color: $white;
    font-family: $clubs_heading_font_family;
    font-size: $h3_font_size;
    line-height: 45px;
    padding: 13.5px 52.5px;
    text-transform: uppercase;
    width: 300px;

    margin: 1em;

    &:hover {
      color: $white;
      background-color: lighten($green, 2%);
    }
  }

  .step-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 2em auto;
    width: 300px;

    border-bottom: 1px solid $light_gray;
    height: 9px;
  }

  .step-btn {
    border: 1px solid $light_gray;
    border-radius: 50%;
    display: block;
    height: 18px;
    width: 18px;
    line-height: 1em;

    &.current {
      border: 1px solid $green;
      background-color: $green;
    }

    &.complete {
      background-color: $light_gray;
    }

    &.incomplete {
      background-color: $black;
    }
  }

  .login-header {
    padding: 20px 0;
    text-align: center;
  }

  .login-signup-toggle {
    font-size: 0; // This hides the whitespace which would otherwise add a gap
    margin: 36px auto;
    text-align: center;

    display: flex;
    justify-content: flex-start;

    .login-signup-toggle-option {
      flex: auto;
    }

    input {
      display: none;
    }

    label {
      border: solid 1px white;
      color: $white;
      cursor: pointer;
      font-size: $h4_font_size;
      margin: 0;
      padding: 1em 0;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }

    input:checked + label {
      background-color: $white;
      color: $black;
    }
  }

  .container.login-signup {
    max-width: 680px;
  }

  .login-signup-form-container {
    min-height: 300px;
  }

  .input {
    margin: 16px auto;

    input, textarea {
      background-color: transparent;
      border: none;
      border-bottom: solid 1px white;
      caret-color: $border_green;
      color: $white;
      padding: .3em 0;
      outline: none;
      width: 100%;
    }

    textarea {
      padding-bottom: .9em;
    }

    label {
      color: $white;
      display: block;
      font-family: $clubs_heading_font_family;
      font-size: $h4_font_size;
      text-transform: uppercase;
    }
  }

  .error {
    background-color: $white;
    color: $black;
    display: inline-block;
    font-size: $h4_font_size;
    font-weight: 400;
    margin: 0.6em 0;
    padding: 1em;
    position: relative;

    &::before {
      border-style: solid;
      border-width: 0 1em 1em 1em;
      border-color: transparent transparent $white transparent;
      content: '';
      position: absolute;
      top: -0.6em;
      width: 0;
      height: 0;
    }
  }

  .credit-card-error {
    color: $error_red;
    font-size: $h3_font_size;
    text-align: center;
  }

  .login-signup-form-legal {
    font-size: 20px;
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;

    label {
      width: 1.4em;
      height: 1.4em;
      cursor: pointer;
      position: relative;
      background: $light_gray;
      border-radius: 4px;

      &:after {
       content: '';
       width: 0.9em;
       height: 0.5em;
       position: absolute;
       top: 0.3em,;
       left: 0.25em;
       border: 3px solid $white;
       border-top: none;
       border-right: none;
       background: transparent;
       transform: rotate(-45deg);
       opacity: 0;
     }
    }

    input[type=checkbox] {
      display: none;

      &:checked + label:after {
        opacity: 1;
      }
    }

    .agreement {
      padding: 0.5em;
    }
  }

  .submit-login {
    margin: 40px auto;
    text-align: center;
  }

  .order-summary, .order-details {
    margin-bottom: 24px;

    .section {
      border: solid 1px $light_gray;
      padding: 12px 36px;
    }

    .header {
      border-radius: 36px 36px 0 0;
      text-align: center;
    }

    .body {
      border-top: 0;
    }

    .footer {
      border-radius: 0 0 36px 36px;
      border-top: 0;
    }

    .subscription__terms {
      color: #fff;
      font-weight: $base_font_weight;
      font-size: 13px;
    }
  }

  .order-summary {
    .header {
      background-color: $white;

      h3 {
        color: $black;
        font-weight: 400;
      }
    }

    .body {
      background-color: $dark_gray;
    }

    .footer {
      background-color: $dark_gray;
      text-align: center;
    }
  }

  .credit-card {
    background-color: $dark_gray;
    border-radius: 0.2em;
    padding: 1em;
    padding-bottom: 2em;

    #existing-credit-card {
      color: $white;
    }

    #new-credit-card {
      padding: 10px;
    }

    #brand,
    #card-type {
      background-size: contain;
      background-repeat: no-repeat;
      background-image: asset-url('common/generic.png');
      height: 36px;
      margin-top: 37px;
      width: 80px;

      &.visa {
        background-image: asset-url('common/visa.png');
      }
      &.mastercard {
        background-image: asset-url('common/mastercard.png');
      }
      &.amex {
        background-image: asset-url('common/amex.png');
      }
    }

    .card-field {
      border-bottom: solid 1px $light_gray;
    }
  }

  #existing-credit-card-user-option,
  #new-credit-card-user-option {
    color: $pewter;
    font-size: 20px;
  }

  .credit-card-controls {
    margin-top: 42px;

    #update-card {
      margin: 1em;

      &.disabled {
        color: $light_gray;

        &:hover {
          text-decoration: none;
          cursor: not-allowed;
        }
      }
    }
  }

  .club-hero {
      background-size: cover;
      background-position: 50% 0;
      color: #fff;
      height: 500px;
      text-align: center;

      @media (min-width: 768px) {
          height: 780px;
      }

      @media (min-width: 1920px) {
          height: 1040px;
      }
  }

  .club-hero--welcome {
      background-image: url(image-path('site/wine_clubs/welcome-to-the-tribe.jpg'));
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 0;
      text-align: left;

      .btn {
        font-weight: 200;
      }
  }

  .subscribed {
    @media (min-height: 800px) {
      min-height: 500px;
    }
    .next-release-container {
      border: $standard-form-border;
      @include border-radius($standard-form-border-radius);
      margin-top: 150px;
      text-align: center;
      h5 {
        color: #ccc;
        margin: 0;
        text-transform: uppercase;
        font-size: 12px;
      }
      form {
        margin-bottom: 0;
      }
    }
    .countdown-timer {
      display: block;
      margin-bottom: 20px;
      table {
        margin: 0 auto;
      }
      td {
        font-size: 2.2em;
        font-weight: 300;
        padding: 0 15px;
      }
      th {
        font-size: 13px;
        color: #999;
        text-transform: uppercase;
        font-weight: 300;
      }
    }

  }

  .age-verification-modal{
      h1.mofotype{
        font-family: $heading_font_family;
        letter-spacing: normal;
        text-transform: none;
        color: #000;
      }
  }

}
