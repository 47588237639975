.notification-bar {
    background: #1a1a1a;
    height: 50px;
    overflow: hidden;
    position: relative;
    font-size: 13px;
    color: #aaa;
    line-height: 48px;

    .reassurance {
        strong {
            text-transform: uppercase;
            font-weight: bold;
            color: inherit;
        }

        i {
            color: $green;
            font-size: 20px;
            vertical-align: middle;
            margin-right: 8px;
            position: relative;
            top: -1px;

            &.ion-jet::before {
                @include transform(rotate(30deg));
            }
        }
    }
}





.notification-bar-flash {
    .alert {
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        line-height: normal;
        text-align: center;
        border-radius: 0;
        margin: 0;
        border: 0;

        &.alert-error,
        &.alert-alert {
            @extend .alert-danger; // scss-lint:disable PlaceholderInExtend
        }

        &.alert-notice,
        &.alert-success,
        &.alert-primary {
            @extend .alert-success; // scss-lint:disable PlaceholderInExtend
        }

        &.alert-reminder {
            @extend .alert-reminder; // scss-lint:disable PlaceholderInExtend
        }

        .close {
            opacity: .9;
            text-shadow: none;
            line-height: 20px;
            color: #fff;

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
                outline: none;
            }
        }
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .notification-bar {
        .notification-bar-base {
            .col-sm-4 {
                &:first-child {
                    padding-right: 0;
                }

                &:nth-child(1) {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
                }

                &:last-child {
                    padding-left: 0;
                }
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .notification-bar {
        .reassurance {
            text-align: center;
        }
    }
}
