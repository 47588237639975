body.account-communication_preferences {
  .email-subscription-form,
  .notification-form {
    .standard-form-group.check-box {
      .standard-form-control-container {
        top: $standard-form-ios-padding;
        margin-top: -12.5px;
      }
      p {
        font-weight: $base_font_weight;
        margin: 5px 0;
        color: #aaa;
      }
    }
    .spam-level {
      margin-bottom: 40px;
    }
  }
}

form.notification-form {
    padding-top: 2em;

    .standard-form-section {
        border-bottom: solid 1px rgba(0, 0, 0, 0.03);
        padding-bottom: 2em;
    }

    .standard-form-control[disabled] {
        cursor: not-allowed!important;
    }
}

.push-notification__message {
    padding: 0 0 0 30px;

    span {
        display: inline-block;
        text-indent: -25px;
    }
}
