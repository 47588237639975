$tablet-min-width: 768px;

body.account-credit_cards {
  .credit-card {
    margin-bottom: 15px;
    border: $standard_form_border;
    padding: $standard-form-ios-padding;
    @include border-radius($standard-form-border-radius);
    position: relative;
    &.expired {
      text-decoration: line-through;
      color: #ccc;
      .standard-form-label-hint {
        color: #ccc;
      }
      .payment-method-icon {
        @include filter(grayscale(100%));
        opacity: 0.33;
      }
    }
    .payment-method-icon {
      margin-right: 8px;
      margin-top: -5px;
      vertical-align: middle;
    }
  }

  // ------------------------------------------------
  //  Control Buttons
  // ------------------------------------------------
  .credit-card__controls {
    position: static;
    text-align: left;
    margin: 10px 0 0 -7px;
    .btn-icon-rounded {
      position: absolute;
      top: $standard-form-ios-padding;
      right: $standard-form-ios-padding;
    }
  }

  @media only screen and (min-width: $tablet-min-width) {
    .credit-card__controls {
      position: absolute;
      margin: 0;
      top: 0.75em;
      right: $standard-form-ios-padding;
      white-space: nowrap;
      .btn-icon-rounded {
        position: static;
      }
    }
  }
}

// ------------------------------------------------
//  Tablets and Mobiles
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  body.account-credit_cards {
    .standard-form.standard-form-ios .standard-form-group.radio-button .standard-form-label {
      padding-left: 1.1em;
      cursor: default;
    }
  }
}
