body.cart {
  .wine-for-good {
    border: $standard-form-border;
    margin-top: 16px;
    max-width: 350px;
    padding: 10px;
    position: relative;

    i {
      color: #bbb;
      position: absolute;
      right: 10px;
      top: 8px;
    }

    .standard-form {
      margin-bottom: 0;
    }

    .input-group .standard-form-group {
      display: table-cell;
      margin-bottom: 0;

      input {
        border-bottom-right-radius: 0;
        border-right: none;
        border-top-right-radius: 0;
      }
    }
  }

  .cart-table,
  .savings .box {
    border: $standard-form-border;
  }

  .bonus-item, .express-checkout .title {
    background: lighten($green, 60%);
    color: rgba(0,0,0,0.533);
  }

  .cart-table {
    margin: 0;

    .thumb-cell {
      width: 97px;
      padding-right: 0px;
    }

    .description-cell {
      a {
        color: #333;
        &:hover, &:focus {
          color: $green;
          text-decoration: none;
        }
      }
    }

    // Shared button styles
    .quantity-fewer .btn,
    .quantity-more .btn,
    .remove-item .btn {
      font-size: 21px;
      min-width: 0;
      padding: 0;
      width: 30px;
      line-height: 28px;
      @include border-radius(100%);
    }

    .delivery-estimate, .explanation, .variant, .shipping-estimate-label {
      font: $sans_font;
      color: rgba(0,0,0,0.6);
      font-size: 13px;
      display: block;
      font-style: normal;
      text-transform: none;
      strong {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
      }
    }
    td.money .explanation {
      white-space: normal;
    }

    .delivery-estimate {
      font-weight: bold;
    }

    // Quantity cell and buttons
    .quantity-cell {
      white-space: nowrap;
      &.no-fewer .quantity-fewer,
      &.no-more .quantity-more {
        visibility: hidden;
      }
    }
    .quantity-fewer,
    .quantity-more {
      display: inline-block;
    }
    .quantity-fewer {
      margin-right: 5px;
    }
    .quantity-more {
      margin-left: 5px;
    }
    .quantity-fewer .btn {
      @extend .ion-ios-minus-empty;
    }
    .quantity-more .btn {
      @extend .ion-ios-plus-empty;
    }

    // Remove item cell and button
    .remove-item-cell {
      width: 1%;
      padding-left: 0;
    }
    .remove-item .btn {
      @extend .ion-ios-close-empty;
      &:hover, &:focus {
        border-color: $red;
        color: $red;
      }
    }

    tfoot {
      th {
        font-weight: $bold_font_weight;
        position: relative;
        &:after {
          position: absolute;
          content: ' ';
          right: 16px;
          top: 16px;
          bottom: 16px;
          border-right: $standard-form-border;
          width: 1px;
        }
      }
      td.remove-item-cell {
        height: 62px;
      }
      tr:not(:first-child) {
        td, th {
          border-top: none;
        }
      }
    }

    .code {
      padding-top: 16px;
      vertical-align: top;
      .standard-form-control {
        text-transform: uppercase;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          text-transform: none;
        }
        &:-moz-placeholder {
          text-transform: none;
        }
        &::-moz-placeholder {
          text-transform: none;
        }
        &::-ms-input-placeholder {
          text-transform: none;
        }
      }
      .input-group {
        max-width: 350px;
        width: 100%;
      }
      .btn {
        font-size: $base_font_size;
        height: 42px;
        line-height: 38px;
        padding: 0;
      }
    }

    .hidden-checkout-form-label {
      text-transform: none;
      font-weight: $base_font_weight;
      button {
        @include appearance(none);
        background: none;
        border: none;
        padding: 0;
        color: $green;
        &:hover, &:focus {
          outline: none;
          text-decoration: underline;
        }
      }
    }

    .hidden-checkout-form {
      font-weight: $base_font_weight;
      font-size: 13px;

      .hidden-checkout-form-label {
        margin-bottom: 3px;
      }

      .standard-form-control {
        text-transform: uppercase;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          text-transform: none;
        }
        &:-moz-placeholder {
          text-transform: none;
        }
        &::-moz-placeholder {
          text-transform: none;
        }
        &::-ms-input-placeholder {
          text-transform: none;
        }
      }
      .input-group-btn:last-child > .btn,
      .input-group-btn:last-child > .btn-group {
        margin-left: 0;
      }
      .input-group {
        max-width: 200px;
        width: 100%;
      }
      .btn {
        font-size: 13px;
        min-width: 80px;
        height: 42px;
        line-height: 38px;
        padding: 0;
        border-left: none;
      }
    }
  }

  .savings {
    .box {
      background: #fafafa;
      border-top: none;
      padding: 18px 25px 18px 105px;
      margin-bottom: 15px;
      position: relative;
      line-height: 1.4em;
      i {
        position: absolute;
        left: 25px;
        top: 50%;
        margin-top: -14.5px;
        font-size: 28px;
        border-right: $standard-form-border;
        width: 54px;
        display: inline-block;
        line-height: 1;
      }
    }
  }

  .recommendation-title {
    background-color: #f7f8fa;
    font-size: 12px;
    color: #626468;
    letter-spacing: -0.17px;
    line-height: 18px;
    font-weight: bold;
    padding: 10px 25px;
    border: 2px solid #fff;
    border-bottom: 1px solid #e2e2e2;
    margin: 0;
  }

  .recommendation-intro {
    font: $serif_font;
    color: #999;
    font-size: 14px;
    line-height: 2em;
    margin: 1em 0 1.2em;
    strong {
      color: #000;
    }
  }

  .checkout-button {
    float: right;
    margin-top: 10px;
  }

  .recommendations-list {
    border-radius: 5px;
      
    .recommendation {
      @include offer_xs_layout();
      border-bottom: 1px solid #e2e2e2;
      display: table;
      &:last-child {
        border-bottom: none;
      }
      .select-wrapper {
        display: none;
      }
      .thumb, .title-and-pricing {
        display: table-cell;
        padding: 20px 15px;
      }
      .thumb {
        width: 110px;
        position: relative;
        vertical-align: top;
      }
      .discount {
        position: relative;
        float: right;
        margin-top: -40px;
        margin-right: -30px;
      }
      .title-and-pricing {
        position: relative;
        padding-left: 35px;
        padding-bottom: 20px;
        vertical-align: middle;
        padding-right: 100px;
        a {
          display: block;
          color: #333;
          padding-bottom: 3px;
          &:hover, &:focus {
            color: $green;
            text-decoration: none;
          }
        }
      }
      .price {
        display: inline-block;
        text-align: left;
        width: 100%;
        .rrp {
            color: $red;
            font-size: 16px;
            position: relative;
            top: -2px;
        }
        em {
          display: inline-block;
          font-size: 14px;
          margin-left: 8px;
        }
      }
      form {
        position: absolute;
        right: 30px;
        top: 50%;
        margin-top: -14px;
      }
      .btn {
        font-size: 12px;
        padding: 0.4em 2em;
        min-width: 0;
        &:hover, &:focus {
          background: $green;
          color: #fff;
          border: solid 1px darken($green, 3%);
        }
        &[disabled],
        &:disabled,
        &.disabled {
          background: desaturate($green, 20%) !important;
          border-color: desaturate($green, 20%) !important;
        }
      }
    }
  }

  .express-checkout {
    margin-bottom: 0;
    .title {
      padding: 16px;
      text-transform: uppercase;
      font-weight: $bold_font_weight;
      color: #333;
      position: relative;
      border-bottom: none;
      i {
        font-size: 22px;
        position: absolute;
        top: 12px;
        right: 15px;
        color: $green;
      }
    }
    .select2-container--focus .select2-selection {
      z-index: 1;
      position: relative;
    }
    .select2-selection {
      padding-left: 35%;
      .select2-selection__rendered {
        padding: 1.1em 0;
        height: auto;
        line-height: normal;
        .select2-image-wrapper {
          display: inline-block;
          width: 40px;
          margin-right: 5px;
          height: 15px;
          position: relative;
        }
        img {
          position: absolute;
          left: 0;
          top: -3px;
          margin-right: 0;
          width: 40px;
        }
      }
    }
    .edit {
      padding: 15px;
      background: #fafafa;
      text-align: right;
      margin-bottom: -1px;
      font-size: 13px;
      .btn {
        padding: 0.4em 1.3em;
        min-width: 0;
        margin-left: 8px;
      }
    }
    .shipping-label {
      padding-top: 2em;
    }
    .standard-form-pseudo-container {
      padding: 2em 1.1em 0 35%;
      border: $standard-form-border;
      border-bottom: none;
      .standard-form-group {
        border: none;
        margin-bottom: 1.1em;
        &:last-child {
          margin-bottom: 0;
        }
        .standard-form-label {
          padding: 0.3em 0 0.3em 2.5em;
        }
        .standard-form-label-text {
          font-weight: $base_font_weight;
        }
        .standard-form-control-container {
          top: 0.25em;
          left: 0;
          margin: 0;
        }
        .name, .price {
          display: inline-block;
        }
        .price {
          font: $serif_font;
        }
        .eta {
          font-size: 13px;
          white-space: normal;
        }
      }
    }
    .button-container {
      padding: 15px 10px;
      text-align: center;
      border-top: none;
      
      @media only screen and (min-width: 992px) {
        padding: 20px 20px;
      }

      .btn-success {
        &[disabled],
        &:disabled,
        &.disabled {
          &:before {
            content: 'Processing ...';
          }
        }
      }
    }
  }

  .buttons {
    margin: 50px 0;
  }
}

// ------------------------------------------------
//  Mobile and Tablet
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  body.cart {
    .recommendations-list {
      .recommendation {
        .title-and-pricing {
          padding-right: 15px;
        }
        form {
          position: static;
          margin-top: 10px;
        }
        .btn {
          font-size: 13px;
          padding: 0.9em;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Tablet
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.cart {
    .recommendations-list {
      .recommendation {
        .price {
          em {
            display: block;
            margin-left: 0;
            padding-top: 3px;
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.cart {
    .cart-table {
      border-top: none;
      &, tbody, tfoot, tr {
        display: block;
      }
      thead {
        display: none;
      }
      tbody {
        .order-item {
          font-size: 0px;
          position: relative;
        }
        .thumb-cell, .description-cell {
          display: block;
        }
        .quantity-cell, .total-cell {
          display: inline-block;
          width: 50%;
        }
        .thumb-cell {
          position: absolute;
          width: 55px;
        }
        .description-cell {
          font-size: $base_font_size;
          padding-left: 65px;
          padding-right: 45px;
        }
        .quantity-cell {
          font-size: $base_font_size;
        }
        .total-cell {
          line-height: 30px;
          font-size: $base_font_size;
        }
        .remove-item-cell {
          position: absolute;
          top: 0;
          right: 25px;
        }
      }
      tfoot {
        tr {
          position: relative;
          font-size: 0;
        }
        .code {
          display: block;
          font-size: 15px;
        }
        th, td.money {
          display: inline-block;
          width: 40%;
          vertical-align: top;
          padding: 16px;
        }
        tr:not(:first-child) {
          th, td {
            border-top: $standard_form_border;
          }
        }
        th {
          width: 64%;
          font-size: 13.5px;
          padding-left: 50px;
          padding-right: 8px;
          .title {
            border-right: none;
          }
          &:after {
            right: 0;
          }
        }
        td.spacer {
          display: none;
        }
        td.money {
          width: 36%;
          font-size: 15px;
          padding-top: 23px;
          padding-left: 8px;
        }
        .remove-item-cell {
          position: absolute;
          border-top: none !important;
          top: 8px;
          left: 10px;
        }
      }
    }
    .checkout-button {
      float: none;
      text-align: right;
    }
    .recommendations-list {
      margin-bottom: 30px;
    }
    .express-checkout {
      .edit {
        .btn {
          padding-left: 0.8em;
          padding-right: 0.8em;
        }
      }
    }
    .buttons {
      margin: 30px 0;
      .btn {
        padding: 0.8em 1.4em;
      }
    }
  }
}
