/*!
 * Bootstrap v3.3.1 (http://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.btn {
  @include border-radius(2px);
  font-size: 1em;
  padding: 0.9em 3.5em;
  min-width: 100px;
  font-weight: 400;
}
@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  .btn {
    font-weight: 600;
  }
}
.btn-sm {
  font-size: 13px;
  padding: 0.45em 1.5em;
  min-width: 0;
  font-weight: 300;
}
.btn-lg {
  font-size: 18px;
}
.btn-xl {
  font-size: 26px;
}
.btn:focus,
.btn:active {
  outline: none !important;
}
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  background: #eaeaea;
  border-color: darken(#eaeaea, 3%);
  color: #555;
}
.btn-default:hover,
.btn-default:focus {
  background: lighten(#eaeaea, 2%);
  border-color: darken(#eaeaea, 1%);
}
.btn-default:active,
.btn-default.active {
  background: darken(#eaeaea, 1%);
  border-color: darken(#eaeaea, 1%);
}
.btn-default:disabled,
.btn-default[disabled],
.btn-default.disabled {
  background: #eaeaea !important;
  border-color: #eaeaea !important;
}
.btn-primary {
  background: $blue;
  border-color: $blue;
}
.btn-primary:hover,
.btn-primary:focus {
  background: lighten($blue, 2%);
  border-color: lighten($blue, 2%);
}
.btn-primary:active,
.btn-primary.active {
  background: darken($blue, 1%);
  border-color: darken($blue, 1%);
}
.btn-primary:disabled,
.btn-primary[disabled],
.btn-primary.disabled {
  background: desaturate($blue, 100%) !important;
  border-color: desaturate($blue, 100%) !important;
}
.btn-success {
  background: $green;
  border-color: darken($green, 3%);
}
.btn-success:hover,
.btn-success:focus {
  background: lighten($green, 2%);
  border-color: darken($green, 1%);
}
.btn-success:active,
.btn-success.active {
  background: darken($green, 1%);
  border-color: darken($green, 1%);
}
.btn-success:disabled,
.btn-success[disabled],
.btn-success.disabled {
  background: desaturate($green, 20%) !important;
  border-color: desaturate($green, 20%) !important;
}
.btn-info {
  background-image: -webkit-linear-gradient(top, #5bc0de 0%, #2aabd2 100%);
  background-image:      -o-linear-gradient(top, #5bc0de 0%, #2aabd2 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5bc0de), to(#2aabd2));
  background-image:         linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2aabd2', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #28a4c9;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #2aabd2;
  background-position: 0 -15px;
}
.btn-info:active,
.btn-info.active {
  background-color: #2aabd2;
  border-color: #28a4c9;
}
.btn-info:disabled,
.btn-info[disabled],
.btn-info.disabled {
  background-color: #2aabd2;
  background-image: none;
}
.btn-warning {
  background: $orange;
  border-color: $orange;
}
.btn-warning:hover,
.btn-warning:focus {
  background: lighten($orange, 3%);
  border-color: lighten($orange, 3%);
}
.btn-warning:active,
.btn-warning.active {
  background: darken($orange, 1%);
  border-color: darken($orange, 1%);
}
.btn-warning:disabled,
.btn-warning[disabled],
.btn-warning.disabled {
  background: desaturate($orange, 100%) !important;
  border-color: desaturate($orange, 100%) !important;
}
.btn-danger {
  background: $red;
  border-color: darken($red, 3%);
}
.btn-danger:hover,
.btn-danger:focus {
  background: lighten($red, 2%);
  border-color: darken($red, 1%);
}
.btn-danger:active,
.btn-danger.active {
  background: darken($red, 1%);
  border-color: darken($red, 4%);
}
.btn-danger:disabled,
.btn-danger[disabled],
.btn-danger.disabled {
  background: desaturate($red, 100%) !important;
  border-color: desaturate(darken($red, 3%), 100%) !important;
}
.thumbnail,
.img-thumbnail {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #e8e8e8;
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image:      -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#e8e8e8));
  background-image:         linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #2e6da4;
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image:      -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
  background-image:         linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  background-repeat: repeat-x;
}
.navbar-default {
  background-image: -webkit-linear-gradient(top, #fff 0%, #f8f8f8 100%);
  background-image:      -o-linear-gradient(top, #fff 0%, #f8f8f8 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f8f8f8));
  background-image:         linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image:      -o-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#e2e2e2));
  background-image:         linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
          box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
}
.navbar-inverse {
  background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222 100%);
  background-image:      -o-linear-gradient(top, #3c3c3c 0%, #222 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3c3c3c), to(#222));
  background-image:         linear-gradient(to bottom, #3c3c3c 0%, #222 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image:      -o-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#080808), to(#0f0f0f));
  background-image:         linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
          box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
    background-image:      -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
    background-image:         linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
    background-repeat: repeat-x;
  }
}
.alert {
  @include border-radius(2px);
  a {
    color: #fff;
    border-bottom: dotted 1px rgba(255,255,255,0.66);
    &:hover, &:active, &:focus {
      border-bottom-style: solid;
      text-decoration: none;
      color: #fff;
    }
  }
}
.alert-success {
  background: $green;
  border-color: $green;
  color: #fff;
}
.alert-info {
  background: $blue;
  border-color: $blue;
  color: #fff;
}
.alert-warning {
  background: $orange;
  border-color: $orange;
  color: #fff;
}
.alert-reminder {
  background: #fff6e6;
  border-bottom: 1px solid #ffedcd;
  color: #333;

  a,
  a:hover,
  a:active,
  a:visited {
      color: #000;
      border-bottom: dotted 1px #666;
  }

  a:hover {
      border-bottom-style: solid;
  }
}
.alert-danger {
  background: $red;
  border-color: $red;
  color: #fff;
}
.progress {
  background: #ececec;
  @include box-shadow(none);
  @include border-radius(2px);
}
.progress-bar {
  background: $blue;
  @include box-shadow(none);
}
.progress-bar-success {
  background: $green;
  @include box-shadow(none);
}
.progress-bar-info {
  background: $blue;
  @include box-shadow(none);
}
.progress-bar-warning {
  background: $orange;
  @include box-shadow(none);
}
.progress-bar-danger {
  background: $red;
  @include box-shadow(none);
}
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image:      -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image:         linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}
.list-group {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #286090;
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #2b669a 100%);
  background-image:      -o-linear-gradient(top, #337ab7 0%, #2b669a 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2b669a));
  background-image:         linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
  background-repeat: repeat-x;
  border-color: #2b669a;
}
.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}
.panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image:      -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#e8e8e8));
  background-image:         linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image:      -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
  background-image:         linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  background-repeat: repeat-x;
}
.panel-success > .panel-heading {
  background-image: -webkit-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image:      -o-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dff0d8), to(#d0e9c6));
  background-image:         linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
  background-repeat: repeat-x;
}
.panel-info > .panel-heading {
  background-image: -webkit-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image:      -o-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#d9edf7), to(#c4e3f3));
  background-image:         linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
  background-repeat: repeat-x;
}
.panel-warning > .panel-heading {
  background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image:      -o-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fcf8e3), to(#faf2cc));
  background-image:         linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
  background-repeat: repeat-x;
}
.panel-danger > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image:      -o-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#ebcccc));
  background-image:         linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
  background-repeat: repeat-x;
}
.well {
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image:      -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e8e8e8), to(#f5f5f5));
  background-image:         linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .1);
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .1);
}

// ------------------------------------------------
//  Override fonts
// ------------------------------------------------

body {
  font: $sans_font;
  color: #777;
}
h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-weight: normal;
}
strong, label, .strong {
  color: #555;
  font-weight: $bold_font_weight;
}
a {
  color: $green;
}
a:hover, a:focus {
  color: $green;
}
a:focus {
  outline: none;
}
.text-success {
  color: $green;
}
.text-info {
  color: $blue;
}
.text-muted {
  color: #aaa;
}

// ------------------------------------------------
//  Tables
// ------------------------------------------------

.table {
  border: solid 1px #dadada;
  & > tbody, & > thead, & > tfoot {
    & > tr {
      & > td, & > th {
        border-top: solid 1px #dadada;
        padding: 16px;
        vertical-align: middle;
      }
      & > th {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 90%;
        color: #555;
      }
    }
  }
  & > thead > tr > th {
    border-bottom: none;
  }
}

// ------------------------------------------------
//  Tooltips
// ------------------------------------------------

.tooltip.fade {
  @include transition(opacity 0s);
}
.tooltip {
  white-space: normal;
}
.tooltip-inner {
  padding: 10px 12px;
}

// ------------------------------------------------
//  Labels
// ------------------------------------------------

.label {
  padding: 5px 15px 6px;
  font-size: 11px;
  display: inline-block;
}

// ------------------------------------------------
//  Dropdown Menu
// ------------------------------------------------

.dropdown-menu {
  border: solid 1px rgba(0,0,0,0.145);
  @include border-radius(2px);
  @include box-shadow(2px 2px 0px rgba(0, 0, 0, 0.05));
  left: 10px;
  &:before, &:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    left: 15px;
  }
  &:before {
    border-color: rgba(198, 198, 198, 0);
    border-bottom-color: rgba(0,0,0,0.22);
    border-width: 7px;
    margin-left: -7px;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 6px;
    margin-left: -6px;
  }
  & > li > a {
    padding: 6px 20px;
    font-weight: 300;
    i {
      font-size: 1.4em;
      line-height: 1;
      margin-right: 0.5em;
      vertical-align: middle;
      color: inherit;
    }
    &:hover, &:focus {
      background: #fafafa;
    }
  }
  &.dropdown-menu-right {
    left: auto;
    &:before, &:after {
      left: auto
    }
    &:before {
      right: 7.5px;
    }
    &:after {
      right: 8.5px;
    }
  }
  & > li.sublabel {
    text-transform: uppercase;
    font-size: 12px;
    color: #ccc;
    padding: 6px 20px;
  }
}
