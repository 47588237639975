body.unsubscribe#show,
body.email_preferences#show {
  background-color: #111;

  .standard-form-label-text {
    color: #333;
  }

  .standard-form .standard-form-group.radio-button {
    text-align: left;
  }

  .detail-column {
    background-image: image_url('site/unsubscribe/background.jpg');
    background-color: #111;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-right: none;
    .checkmark {
      height: 100px;
      width: 100px;
      display: inline-block;
      background: $green;
      @include border-radius(100%);
      margin-bottom: 30px;
      position: relative;
      &:before, &:after {
        content: ' ';
        position: absolute;
        border-left: solid 6px #fff;
        @include transform-origin(top left);
      }
      &:before {
        left: 27px;
        top: 54px;
        @include transform(rotate(-45deg));
        @include animation(tick-bottom 0.6s ease 0.5s forwards);
      }
      &:after {
        left: 43px;
        top: 69px;
        @include transform(rotate(225deg));
        @include animation(tick-top 0.6s ease 0.5s forwards);
      }
    }
    .header-box {
      padding: 0 0 50px;
      max-width: 85ch;
      margin: 0 auto;
      color: rgba(255, 255, 255, .75);
      font-style: italic;
      font-weight: 400;

      p {
        font-size: 16px;
        margin-bottom: .7em;

        @media only screen and (min-width: 767px) {
          font-size: 24px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .detail-box {
      max-width: 700px;
      h3, .h3 {
        margin-bottom: 10px;
      }
    }
    small {
      color: #aaa;
      font-size: 100%;
      line-height: 1.5;
    }
    .resubscribe-options {
      border: solid 1px #eaeaea;
      padding: 30px;
      max-width: 385px;
      margin: 50px auto 10px;
      text-align: left;
      strong {
        font-weight: bold;
        color: #333;
        display: block;
        margin: 0 auto;
      }
      small {
        margin: 25px 10px;
        display: block;
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.unsubscribe#show {
    .detail-column {
      .btn {
        white-space: normal;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
