// Ionicons Font Path
// --------------------------

@font-face {
 font-family: $ionicons-font-family;
 src:font-url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}");
 src:font-url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}#iefix") format("embedded-opentype"),
  font-url("#{$ionicons-font-path}/ionicons.woff2?v=#{$ionicons-version}") format("woff2"),
  font-url("#{$ionicons-font-path}/ionicons.woff?v=#{$ionicons-version}") format("woff"),
  font-url("#{$ionicons-font-path}/ionicons.ttf?v=#{$ionicons-version}") format("truetype"),
  font-url("#{$ionicons-font-path}/ionicons.svg?v=#{$ionicons-version}#Ionicons") format("svg");
 font-weight: normal;
 font-style: normal;
}

.ion {
  display: inline-block;
  font-family: $ionicons-font-family;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
