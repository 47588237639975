.medium-editor {
  border-top: solid 1px rgba(0,0,0,0.145);
  background: #fff;
}
.medium-editor-document[contenteditable] {
  min-height: 700px;
}

.medium-editor-document blockquote {
  border-left-color: $green;
}
.medium-editor-highlight-menu button.medium-editor-highlight-menu-button-active {
  color: $green;
}

.medium-editor-document {
  p, blockquote, li {
    color: #777;
  }
}

.medium-editor-document h2 {
  font-family: $heading_font_family;
  font-size: 40px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 0 0 0.8em;
  position: relative;
  letter-spacing: normal;
  font-weight: normal;
  color: #000;
}

.medium-editor-document h3,
.medium-editor-document h4,
.medium-editor-document h5,
.medium-editor-document h6 {
  font-family: $heading_font_family;
  font-size: 30px;
  font-weight: normal;
  color: #000;
}

.medium-editor-document h4,
.medium-editor-document h5,
.medium-editor-document h6  {
    font-size: 24px;
    font-weight: normal;
    color: #000;
}
