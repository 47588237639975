.value-proposition, .experience-promise {
  background: #1a1a1a;
  padding: 50px 0 25px;
  text-align: center;
  h4, h5, p {
    margin: 0;
  }
  h4, h5 {
    color: #dadada;
  }
  h2 {
    color: #fff;
    margin-top: 0;
  }
  h4 {
    font: $sans_font;
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  h5 {
    font-family: $sans_font_family;
    line-height: 1.5em;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
    &.serif {
      font-family: $serif_font_family;
      text-transform: none;
      color: $green;
      font-style: italic;
      font-weight: $base_font_weight;
    }
  }
  p {
    line-height: 1.8em;
    margin-top: 0.7em;
    font-size: 16px;
    margin-bottom: 37.5px;
    color: #888;
  }
  .value-card, .promise-card {
    border: double 4px #2a2a2a;
    padding: 1.8em;
    margin: 2.5em 0;
    p {
      text-align: left;
      margin-bottom: 0;
    }
    strong {
      color: #dadada;
    }
    i {
      background: $green;
      color: #1a1a1a;
      @include border-radius(100%);
      line-height: 65px;
      height: 65px;
      width: 65px;
      font-size: 40px;
      display: inline-block;
      margin-bottom: 20px;
      &.ion-happy {
        font-size: 73px;
        color: $green;
        background: #1a1a1a;
        &:before {
          position: relative;
          top: -4px;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Large Desktops
// ------------------------------------------------

@media only screen and (min-width: 1200px) {
  .value-proposition, .experience-promise {
    .value-card, .promise-card {
      margin-left: 1em;
      margin-right: 1em;
    }
  }
}

// ------------------------------------------------
//  Small Desktops
// ------------------------------------------------

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .value-proposition, .experience-promise {
    .value-card, .promise-card {
      padding: 1.5em;
    }
    h4 {
      font-size: 22px;
    }
    h5 {
      font-size: 16px;
    }
    p {
      font-size: 15px;
    }
  }
}

// ------------------------------------------------
//  Tablets
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .value-proposition, .experience-promise {
    .value-card, .promise-card {
      padding: 1.57em;
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  .value-proposition, .experience-promise {
    .value-card, .promise-card {
      margin-bottom: 0;
      padding: 1.2em;
    }
    .col-sm-4:not(:first-child) {
      display: none;
    }
  }
  .value-proposition {
    .col-sm-4 {
      height: 365px;
    }
  }
  .experience-promise {
    .col-sm-4 {
      height: 350px;
    }
  }
}
