body[class^="account-"] {
  .background-wrapper {
    position: relative;
  }
  .background-colour {
    background: #fafafa;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
  }
  &.js:not(.legacy-ie) .nav-container {
    .legacy-form-submit {
      display: none;
    }
    input[type='file'] {
      @include opacity(0);
    }
  }
  .account-layout-container {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .nav-column, .content-column {
    display: table-cell;
    vertical-align: top;
  }
  .nav-column {
    width: 25%;
  }
  .content-column {
    width: 75%;
  }
  .nav-container {
    padding-right: 5px;
    .profile-image {
      border: solid 1px #eaeaea;
      padding: 2px;
      background: #fff;
      @include border-radius(100%);
      @include box-shadow(2px 2px 0px rgba(0, 0, 0, 0.05));
      position: relative;
      margin: 40px auto 0;
      max-width: 134px;

      picture {
        background-image: image_url('common/default-profile-image.png');
        background-color: #eaeaea;
        background-size: cover;
      }
    }
    h3 {
      text-align: center;
      margin: 10px 0 5px;
      color: #555;
      line-height: 1.2em;
      font-size: 25px;
    }
    .credits-total {
      color: #bbb;
      margin-bottom: 40px;
      text-align: center;
      font-size: 13px;
      line-height: 24px;
    }
    .loyalty-status {
        text-align: center;
        margin: 10px 0;

        .loyalty-level {
            padding: 4px 10px;
            border-radius: 30px;
            min-width: 180px;
            display: inline-block;
            color: white;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
        }
        .loyalty-level-0 {
            background: #e1e1e1;
        }
        .loyalty-level-1 {
            background: #CD7F32;
        }
        .loyalty-level-2 {
          background: #9babb8;
        }
        .loyalty-level-3 {
          background: #e7b000;
        }
        .loyalty-level-4 {
          background: #72c1d5;
        }
        .loyalty-level-5 {
          background: #77b204;
        }
    }
    .submenu {
      z-index: 1;
      position: relative;
      margin-right: -5px;
      margin-bottom: 50px;
      a {
        display: block;
        padding: 15px 10px 12px;
        border-bottom: solid 1px #eaeaea;
        color: #777;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include border-radius($standard-form-border-radius 0 0 $standard-form-border-radius);
        i {
          font-size: 22px;
          display: inline-block;
          vertical-align: middle;
          padding-bottom: 2px;
          width: 28px;
          line-height: 15px;
          text-align: center;
          &.vino-icon-club {
            min-height: 24px;
            line-height: 21px;
            font-size: 14px;
          }
        }
        &.active {
          border-bottom: solid 1px $green;
        }
        &:hover, &:focus {
          text-decoration: none;
          border-bottom: solid 1px $green;
        }
      }
    }
  }
  .content-column {
    background: #fff;
    border-left: solid 1px #eaeaea;
  }
  .content-container {
    padding: 40px 25px 40px 40px;
    h1 {
      margin-top: 0;
    }
  }
  .empty {
    font-size: 90%;
    color: #ccc;
    text-align: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 410px);
  }
  .control-bar {
    margin-bottom: 20px;
    .standard-form, .standard-form-group {
      margin-bottom: 0;
    }
    .btn {
      font-size: 13px;
      min-width: 0;
      padding: 11px 20px;
    }
    .standard-form-append {
      padding: 0;
      border: none;
      .btn {
        position: relative;
        margin-left: -1px;
        @include border-radius(0 $standard-form-border-radius $standard-form-border-radius 0);
      }
    }
    .btn-new {
      margin-top: 25px;
    }
    .col-sm-2 {
      padding-left: 0;
    }
  }

  .control-bar .btn-icon-rounded,
  .controls .btn-icon-rounded {
    color: rgba(0, 0, 0, 0.4);
    min-width: 0;
    width: 32px;
    height: 32px;
  }

  .control-bar .btn-icon-rounded {
    line-height: 30px;
    padding: 0 0 0 3px;
    font-size: 13px;
  }

  .controls .btn-icon-rounded {
    line-height: 31px;
    padding: 0 0 0 3px;
    font-size: 12px;
  }
}

// ------------------------------------------------
//  Tablets
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body[class^="account-"] {
    .standard-form-section-left-column,
    .standard-form-section-right-column {
      float: none;
      width: 100%;
    }
    .standard-form-section-left-column {
      margin-bottom: 25px;
    }
    .standard-form-section-hint {
      margin-top: 10px;
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body[class^="account-"] {
    h1, .h1 {
      font-size: 51px;
    }
    .background-colour {
      background: #f4f4f4;
      bottom: auto;
      right: 0;
      height: 110px;
      border-bottom: solid 1px #eaeaea;
    }
    .account-layout-container, .nav-column, .content-column {
      display: block;
      width: 100%;
    }
    .content-column {
      border-left: none;
    }
    .nav-container {
      .submenu {
        max-height: 54px;
        overflow: hidden;
        margin-bottom: 35px;
        white-space: nowrap;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: solid 1px #eaeaea;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        background: #fafafa;
        a {
          display: inline-block;
          border-bottom: solid 2px transparent;
          &.active, &:hover, &:focus {
            border-bottom: solid 2px $green;
          }
        }
      }
    }
    .content-container {
      padding: 5px;
    }
    .empty {
      height: auto;
      max-width: none;
      margin-bottom: 30px;
    }
    .control-bar {
      .btn-new {
        margin-top: 20px;
      }
    }
  }
}
