// ------------------------------------------------
//  Selection
// ------------------------------------------------

::selection {
  background: $green;
  color: #fff;
}
::-moz-selection {
  background: $green;
  color: #fff;
}
