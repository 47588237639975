// ------------------------------------------------
//  Lock icon
// ------------------------------------------------

body.checkout-login,
body.checkout-guests,
body.checkout-sessions,
body.checkout-registrations,
body.checkout-address,
body.checkout-verification,
body.checkout-shipping,
body.checkout-payment,
body.checkout {
  .c-page-content {
    @extend .ion-locked;
    &:before {
      position: absolute;
      top: 70px;
      right: 20px;
      color: #ccc;
      font-size: 22px;
    }
  }
}

// ------------------------------------------------
//  Order summary
// ------------------------------------------------

body.checkout-login,
body.checkout-guests,
body.checkout-sessions,
body.checkout-registrations,
body.checkout-address,
body.checkout-verification,
body.checkout-shipping,
body.checkout-payment,
body.checkout#confirmed,
body.account-orders#show {

  .order-summary-wrapper {
    margin-top: -30px;
  }
  .order-summary-container {
    padding-top: 30px;
    padding-bottom: 3em;
  }

  .order-summary {

    .section.header {
      font-weight: bold;
      text-transform: uppercase;
    }

    // Base styles and the scaffolding. The summary
    // is broken into four sections - the header,
    // the items, the totals and the payment due.
    background: #fafafa;
    border: $standard-form-border;
    @include border-radius($standard-form-border-radius);
    .section {
      padding: 1.5em;
      border-bottom: $standard-form-border;
      &:last-child {
        border-bottom: none;
      }
    }

    // Generic styles for lists - they're common
    // between the items and totals sections
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      .money {
        float: right;
        margin-right: -5.5em;
        font-weight: $bold_font_weight;
        color: #555;
      }
    }

    // The header
    .section.header {
      h5 {
        margin: 0;
        color: #555;
      }
    }

    // The items
    .section.items {
      ul {
        li {
          padding: 1.5em 5.5em 1.5em 0;
          border-bottom: solid 1px rgba(0,0,0,0.1);
          &:first-child {
            margin-top: -1.5em;
          }
          &:last-child {
            margin-bottom: -1.5em;
            border-bottom: none;
          }
          span {
            display: block;
            margin-top: 0.4em;
          }
        }
      }
    }

    // The totals
    .section.totals, .section.payments {
      ul {
        li {
          padding: 0.5em 5.5em 0.5em 0;
          &:first-child {
            margin-top: -0.5em;
          }
          &:last-child {
            margin-bottom: -0.5em;
          }
        }
      }
    }

    // Grand total
    .section.total {
      line-height: 26px;
      font-weight: $bold_font_weight;
      color: #555;
      .money {
        float: right;
        color: $green;
        font-size: 26px;
      }
    }

    // Address
    .section.address {
      strong {
        display: block;
        margin-bottom: 10px;
      }
    }

    // Shipping
    .section.shipping {
      .eta {
        margin-bottom: 0;
      }
    }

    .section.savings {
      background: #f1f1f1;
      padding-left: 4.8em;
      position: relative;
      i {
        font-size: 28px;
        position: absolute;
        top: 0.64em;
        left: 0.8em;
      }
    }

    // Buttons
    .btn {
      padding: 0.45em 2em;
    }
  }
}
