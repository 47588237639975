body.checkout#confirmed {
  .order-summary-wrapper {
    padding-top: 10px;
  }
  section {
    padding: 50px 0;
    border-bottom: solid 1px #eaeaea;
    &:last-of-type {
      border-bottom: none;
    }
  }
  .details-wrapper {
    margin: 0 40px;
  }
  h1 {
    margin-top: 0;
  }
  #tom {
    background-image: image-url('site/tom.jpg');
    background-size: 250px 250px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 250px;
    height: 250px;
    @include border-radius(100%);
    margin: 0 auto;
  }
  .fat-fingers-form {
    margin: 30px 0 0;
    text-align: center;
    .btn {
      margin-bottom: 0;
      &[disabled],
      &:disabled,
      &.disabled {
        &:before {
          content: 'Processing ...';
        }
      }
    }
  }
  p.text {
    line-height: 1.8em;
    margin-bottom: 40px;
    text-align: center;
  }
  .status-url {
    display: block;
    background: #fafafa;
    border: $standard-form-border;
    padding: 0.9em;
    text-align: center;
    color: #333;
    word-wrap: break-word;
    @include border-radius($standard-form-border-radius);
    &:hover, &:focus {
      color: $green;
      text-decoration: none;
    }
  }
  .dont-worry {
    font-size: 13px;
    margin: 5px 0 0;
    text-align: center;
    color: #aaa;
  }
  h5 {
    margin-top: 0;
  }
  p.wine-connoisseur {
    line-height: 1.8em;
  }
  .btn {
    margin-bottom: 10px;
  }
}

// ------------------------------------------------
//  Tablet and mobile
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  body.checkout#confirmed {
    .details-wrapper {
      margin: 0;
    }
  }
}

// ------------------------------------------------
//  Tablet
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.checkout#confirmed {
    section {
      padding: 40px 0;
    }
    #tom {
      background-size: 200px 200px;
      width: 200px;
      height: 200px;
    }
  }
}

// ------------------------------------------------
//  Mobile
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.checkout#confirmed {
    p.text {
      margin-bottom: 30px;
    }
    section {
      padding: 30px 0;
    }
    #tom {
      background-size: 150px 150px;
      width: 150px;
      height: 150px;
    }
  }
}
