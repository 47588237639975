.modal-content {
  @include box-shadow(none);
  @include border-radius($standard-form-border-radius);
  border: none;
}

.modal-backdrop {
  @include transition(opacity 0.15s ease);
  &.in {
    @include opacity(0.75);
  }
}
