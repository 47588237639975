.devise-signup-terms {
  margin-top: 10px;
  font-size: 13px;
  color: #999;
}
.devise-shared-links {
  font-size: 13px;
  margin-top: 10px;
  a {
    display: inline-block;
    margin-right: 8px;
    color: #999;
    &:hover, &:focus {
      text-decoration: none;
      color: $green;
    }
  }
}
.devise-subscribe-option {
  text-align: left;
  margin: 30px 0;
  .standard-form-group.check-box {
    .standard-form-label {
      .standard-form-label-text {
        color: #555;
      }
      .standard-form-control-container {
        top: 0;
        margin-top: 0;
      }
      .standard-form-hint {
        color: #999;
      }
    }
  }
}

body {
  &.registrations,
  &.sessions,
  &.unlocks,
  &.devise-passwords,
  &.passwords {
    .c-page-content {
      background-image: image-url('site/onboarding/wall.jpg');
      min-height: 100vh;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    form {
      margin: 0;
    }
    h1 {
      display: block;
      text-align: center;
      margin: 0 0 0.4em;
      letter-spacing: -0.066em;
    }
    .or {
      margin: 2.75em 0;
      position: relative;
      &:after {
        content: 'or';
        position: absolute;
        top: -2.45em;
        width: 40px;
        margin-left: -20px;
        font-size: 75%;
        color: #ccc;
        text-transform: uppercase;
      }
    }
    .referred {
      margin-bottom: 25px;
    }
    .standard-form-group {
      margin-bottom: 0.5em;
    }
  }
}
@media only screen and (max-width: 767px) {
  body {
    &.registrations,
    &.sessions,
    &.unlocks,
    &.devise-passwords,
    &.passwords {
      .site-content {
        background: #f7f7f7;
      }
      .vertical-centre-outer {
        padding: 50px 0;
      }
    }
  }
}


html.legacy-ie {
  height: 100%;
  body {
    &.registrations,
    &.sessions,
    &.unlocks,
    &.devise-passwords,
    &.passwords {
      height: 100%;
      .site-content {
        height: 100%;
      }
    }
  }
}

body.passwords {
    .c-page-content {
        background-image: none;
        background-color: #f7f8fa;
        background-image: none;
    }
}
