$home_h2_multiplier: 1.2;

body.home#index {

  section.still-scrolling {
    width: 100%;
    background: #111;
    padding-top: 1850px;
    padding-bottom: 150px;
    .h1 {
      color: #fff;
      margin-bottom: 25px;
    }
    p {
      font-size: 17px;
      margin-bottom: 50px;
      color: #bbb;
      line-height: 1.6em;
    }
    .go-back-up {
      font-size: 15px;
      color: #555;
    }
  }

  section {

    h2.mofotype {
      font-size: 50px;
      margin-bottom: 0.7em;
      margin-top: 0.7em;
    }

    &.inspire-video {
      height: 650px;
      background-image: image_url('site/chuckledark.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      h2 {
        margin-top: 0;
        color: #fff;
        margin-bottom: 30px;
      }
    }

    &.social-wall {
      padding: 30px 0 70px;
      background: #f7f7f7;

      .row.gutterless [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }

      .tile {
        position: relative;
        p {
          font-size: 14px;
        }
      }
      .tile-follow {
        text-align: center;
        i {
          font-size: 140px;
          line-height: 1em;
          display: inline-block;
          margin-top: -15px;
        }
        .btn-ghost:hover, .btn-ghost:focus {
          background-color: #fff;
          border-color: #fff;
        }
      }
      .tile-facebook {
        background: $facebook_blue;
        i {
          color: darken($facebook_blue, 9%);
        }
        p {
          color: darken($facebook_blue, 24%);
        }
        .btn-ghost {
          border-color: lighten($facebook_blue, 25%);
          color: lighten($facebook_blue, 40%);
          &:hover, &:focus {
            color: $facebook_blue;
          }
        }
      }
      .tile-twitter {
        background: $twitter_blue;
        i {
          color: desaturate(darken($twitter_blue, 10%),30%);
        }
        p {
          color: desaturate(darken($twitter_blue, 25%),30%);
        }
        .btn-ghost {
          border-color: lighten($twitter_blue, 15%);
          color: lighten($twitter_blue, 30%);
          &:hover, &:focus {
            color: $twitter_blue;
          }
        }
      }
      .tile-linkedin {
        background: $linkedin_blue;
        i {
          color: darken($linkedin_blue, 9%);
        }
        p {
          color: darken($linkedin_blue, 24%);
        }
        .btn-ghost {
          border-color: lighten($linkedin_blue, 25%);
          color: lighten($linkedin_blue, 40%);
          &:hover, &:focus {
            color: $linkedin_blue;
          }
        }
      }
      .tile-instagram {
        background: $instagram_pink;
        i {
          color: darken($instagram_pink, 9%);
        }
        p {
          color: darken($instagram_pink, 24%);
        }
        .btn-ghost {
          border-color: lighten($instagram_pink, 25%);
          color: lighten($instagram_pink, 40%);
          &:hover, &:focus {
            color: $instagram_pink;
          }
        }
      }

      .square-spacer {
        padding-top: 100%;
      }
      .square-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        a {

          &.insta-photo {
            display: block;
            height: 100%;
            width: 100%;

            background-position: center;
            background-size: cover;
          }

          &:hover, &:focus {
            text-decoration: none;
          }
        }
      }

      .tile-tweet {
        font-size: 14px;
        line-height: 1.8em;
        &:hover, &:focus {
          background: lighten($twitter_blue, 2%);
        }
        a {
          display: block;
          padding: 35px;
        }
        i {
          position: absolute;
          font-size: 36px;
          color: #fff;
          bottom: 10px;
          left: 50%;
          margin-left: -18px;
        }
        blockquote {
          border-left: none;
          font-size: inherit;
          color: #fff;
          margin: 0;
          padding: 0;
        }
        cite, time {
          color: desaturate(darken($twitter_blue, 25%),30%);
        }
        cite {
          font-style: normal;
        }
      }

      .tile-gram {
        &:hover, &:focus {
          img {
            @include filter(brightness(105%));
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }

      .social-icons {
        margin-top: 30px;
        font-size: 0;
        &:before, &:after {
          top: -14px;
        }
        &:before {
          right: 30px;
        }
        &:after {
          left: 30px;
        }
        a {
          display: inline-block;
          padding: 0 10px;
          font-size: 45px;
          color: #c6c6c6;
          &:hover, &:focus {
            text-decoration: none;
            &.social-button-facebook {
              color: $facebook_blue;
            }
            &.social-button-twitter {
              color: $twitter_blue;
            }
            &.social-button-instagram {
              color: $instagram_pink;
            }
            &.social-button-linkedin {
              color: $linkedin_blue;
            }
            &.social-button-youtube {
              color: $youtube_red;
            }
          }
        }
      }
    }

    &.awards {
      padding: 30px 0 100px;
    }

    &.as-seen-in {
      padding-bottom: 250px;
      background-image: image_url('site/silhouettes.jpg');
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }
}

// ------------------------------------------------
//  Tablets and Mobiles
// ------------------------------------------------

@media only screen and (max-width: 991px) {
  body.home#index {
    section {
      &.features {
        position: relative;
        .swipe-prompt-wrapper {
          width: 170px;
          height: 170px;
          position: absolute;
          top: 35%;
          left: 50%;
          margin-top: -85px;
          margin-left: -85px;
          background: rgba(0,0,0,0.8);
          @include border-radius(20px);
          .swipe-prompt-frame {
            width: 100px;
            height: 134px;
            top: 50%;
            left: 50%;
            margin-top: -67px;
            margin-left: -50px;
            position: absolute;
            background-image: image_url('site/swipe-prompt.png');
            background-repeat: no-repeat;
            background-size: 300px 134px;
            @include animation-duration(1s);
            @include animation-iteration-count(infinite);
            &.swipe-prompt-frame-1 {
              background-position: 0px 0px;
              @include animation-name(swipe-prompt-1);
            }
            &.swipe-prompt-frame-2 {
              background-position: -100px 0px;
              @include animation-name(swipe-prompt-2);
            }
            &.swipe-prompt-frame-3 {
              background-position: -200px 0px;
              @include animation-name(swipe-prompt-3);
            }
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Tablets
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.home#index {
    section {
      &.inspire-video {
        height: 500px;
      }
      &.social-wall {
        .tile-tweet {
          a {
            padding: 30px;
          }
          blockquote {
            line-height: 1.7em;
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {
  body.home#index {
    section {
      h2.mofotype {
        font-size: 50px;
      }
      &.features {
        background: #1a1a1a;    // So it blends nicely into the banner underneath if the images are different sizes
      }
      &.inspire-video {
        height: 350px;
        h2 {
          font-size: 36px;
        }
      }
      &.social-wall {
        .tile-follow {
          i {
            font-size: 80px;
          }
          .btn {
            font-size: 14px;
            padding: 0.45em 2.5em;
          }
        }
        .tile-tweet {
          a {
            padding: 15px;
          }
          blockquote {
            font-size: 13px;
            line-height: 1.6em;
            overflow: hidden;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            height: 8em;
            margin-bottom: -8px;
          }
          cite, time {
            font-size: 13px;
          }
          i {
            font-size: 20px;
            margin-left: -9px;
          }
        }
        .social-icons {
          a {
            font-size: 35px;
          }
        }
      }
      &.awards {
        padding: 40px 0;
      }
      &.as-seen-in {
        padding-bottom: 130px;
        background-size: 250% auto;
        h4 {
          line-height: 1.4em;
        }
      }
    }
  }
}
