#parcelpoint-container {
  background: #fff;
  .show-on-store-selected {
    display: none;
  }
  &.store-selected {
    .hide-on-store-selected {
      display: none;
    }
    .show-on-store-selected {
      display: block;
    }
  }
}
#parcelpoint-pitch,
#parcelpoint-widget,
#parcelpoint-store-details {
  border: $standard-form-border;
}
#parcelpoint-pitch,
#parcelpoint-store-details {
  padding: $standard-form-ios-padding * 1.5 $standard-form-ios-padding $standard-form-ios-padding * 1.5 3.5em;
}
#parcelpoint-pitch {
  line-height: 1.6em;
  margin-bottom: -1px;
}
#parcelpoint-widget {
  padding: $standard-form-ios-padding;
  @include border-radius(0 0 $standard-form-border-radius $standard-form-border-radius);
  iframe {
    min-height: 570px;
  }
}
#parcelpoint-store-details {
  p {
    margin-bottom: 5px;
  }
  .strong {
    font-weight: $bold_font_weight;
  }
  .btn-link {
    padding: 0;
    border: none;
    margin-bottom: 4px;
    font-weight: 300;
    color: $green;
  }
}

@media only screen and (max-width: 767px) {
  #parcelpoint-pitch,
  #parcelpoint-store-details {
    padding-left: $standard-form-ios-padding;
  }
}

html.legacy-ie {
  .parcelpoint-visibility-wrapper {
    display: none !important;
  }
}
