// ------------------------------------------------
//  Scroll navs
// ------------------------------------------------
//  These are fixed submenus which appear once the
//  user has scrolled a bit. They're positioned
//  underneath the navbar, within the `#nav-wrapper`
//  element.
// ------------------------------------------------

.scroll-nav {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #1a1a1a;
    white-space: nowrap;
    overflow: hidden;

    transition-property: transform, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    transform: translate3d(0, -100%, 0);
    visibility: hidden;
}

.scroll-nav-visible {
    .scroll-nav {
        transition-property: transform;

        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
}
