.gift-card {
  background: #111;
  width: 450px;
  height: 283px;
  position: relative;
  @include border-radius(12px);
  display: inline-block;
  @include box-shadow(4px 4px 0px rgba(0, 0, 0, 0.15));
  text-align: center;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -42px;
    margin-left: -85px;
  }
  .amount {
    right: 30px;
    top: 30px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    position: absolute;
  }
  .balance {
    background: $green;
    color: #fff;
    position: absolute;
    top: -50px;
    left: -8px;
    right: -11px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    @include border-radius(2px);
    @include animation(moveUp 0.5s);
    &:before {
      content: ' ';
      border-color: transparent;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-top-color: $green;
      left: 50%;
      margin-left: -6px;
      bottom: -6px;
      margin-top: -6px;
    }
  }
  .code-container {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }
  .code {
    background: #282828;
    font-size: 30px;
    padding: 8px 30px;
    display: inline-block;
    @include border-radius(2px);
    color: #fff;
  }
  .top-left-corner, .bottom-right-corner {
    position: absolute;
    &:before, &:after {
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: 0;
      position: absolute;
    }
  }
  .top-left-corner {
    top: -4px;
    left: -4px;
    &:before {
      top: 0;
      left: 0;
      border-top-color: #ccc;
      border-top-width: 60px;
      border-right-width: 60px;
    }
    &:after {
      left: 1px;
      top: 1px;
      border-top-color: #fff;
      border-top-width: 57px;
      border-right-width: 57px;
    }
  }
  .bottom-right-corner {
    right: -8px;
    bottom: -8px;
    &:before {
      bottom: 0;
      right: 0;
      border-bottom-color: #ccc;
      border-bottom-width: 60px;
      border-left-width: 60px;
    }
    &:after {
      right: 1px;
      bottom: 1px;
      border-bottom-color: #fff;
      border-bottom-width: 57px;
      border-left-width: 57px;
    }
  }
}
