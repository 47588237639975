.preference-control {
  border: solid 1px #eaeaea;
  @include border-radius(2 * $standard-form-border-radius);
  text-align: center;
  background: #fafafa;
  position: relative;
  @include noselect();
  cursor: pointer;
  &:before, &:after {
    font-family: "Ionicons";
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    font-size: 35px;
    color: rgba(0,0,0,0.1);
    margin-top: -18px;
    top: 50%;
  }
  &:before {
    content: "\f404";
    left: 15px;
  }
  &:after {
    content: "\f3fd";
    right: 15px;
  }
  &.positive {
    .preference-control-slider {
      border-color: $green;
    }
  }
  &.negative {
    .preference-control-slider {
      border-color: $red;
    }
  }
  .preference-control-slider {
    border: solid 1px #ccc;
    @include border-radius(2 * $standard-form-border-radius);
    padding: 0.6em 1em;
    text-align: center;
    display: inline-block;
    width: 66%;
    margin-top: -1px;
    margin-bottom: -1px;
    background: #fff;
    position: relative;
    z-index: 1;
    &:before, &:after {
      font-family: "Ionicons";
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      font-size: 15px;
      color: rgba(0,0,0,0.3);
      margin-top: -7.5px;
      top: 50%;
    }
    &:before {
      content: "\f3d2";
      left: 10px;
    }
    &:after {
      content: "\f3d3";
      right: 10px;
    }
  }
  .preference-control-slider-description {
    margin: 0;
    font-size: 12px;
    color: rgba(0,0,0,0.33);
  }
}
