.parcelpoint-radio {
  position: relative;
  &:after {
    content: 'New!';
    background: $orange;
    font-size: 14px;
    color: #fff;
    position: absolute;
    right: 0.85em;
    top: 0.85em;
    padding: 0.3em 1em;
    width: auto;
    @include border-radius(2px);
  }
}
