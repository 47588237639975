form.simple_form.classic-theme {
    div.input {
        margin-bottom: 1.5em;
    }

    label {
        color: #777;
        font-weight: 400;
        margin: 0 0 5px;
        width: 100%;
    }

    .input input,
    textarea,
    select {
        display: block;
        width: 100%;
        border: $standard-form-border;
        padding-left: 0.75em;
        padding-right: 0.75em;
        line-height: 40px;
        background: #fff;
        @include placeholder-color(rgba(0, 0, 0, 0.25));
        @include appearance(none);

        &:focus {
            outline: none;
            border-color: $green;
        }
    }

    .input input {
        height: 42px;
    }

    .error {
        color: #fff;
        display: block;
        background: $red;
        position: relative;
        padding: 0.75em 1em;
        margin-top: -1px;
        border-bottom-left-radius: $standard-form-border-radius;
        border-bottom-right-radius: $standard-form-border-radius;

        // The arrow above
        &:before {
            content: " ";
            border-color: transparent;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 6px 6px;
            border-bottom-color: $red;
            top: -5px;
            right: 1.1em;
            z-index: 2;
        }
    }
}

.birthdate-wrapper {
    display: flex;
}

.birthdate-segment {
    margin-right: 20px;
    margin-bottom: 5px;
    flex: 1;

    &.year {
        flex: 2;
    }

    &:last-child {
        margin-right: 0;
    }

    label {
        display: block;
    }

    input {
        display: block;
        width: 100%;
        border: solid 1px rgba(0, 0, 0, 0.145);
        height: 42px;
        padding-left: 0.75em;
        padding-right: 0.75em;
        line-height: 40px;
        background: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: 0;

        &:focus {
            border-color: $green;
        }
    }
}
