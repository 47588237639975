.form-section {
    border-bottom: 1px solid #dadada;
    margin-bottom: 40px;
    padding-bottom: 1.5em;

    &:first-of-type  {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #dadada;
    }
}


.form-section__header {
    margin-bottom: 20px;

    h3 {
        margin: 0;
    }
}

.wine-club-membership-status {
    display: inline-block;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: #dadada;
    padding: 0.4em 1em;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
}

.wine-club-membership-status--active {
    background-color: $green;
    color: #fff;
}

.wine-club-membership-status--pending {
    background-color: $orange;
    color: #fff;
}

.wine-club-membership-status--waitlisted {
    background-color: $blue;
    color: #fff;
}

.wine-club-order {
    border: $standard-form-border;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    margin-top: 1em;
}

@mixin wine-club-order-info {
    border: $standard-form-border;
    padding: 5px;
    color: $black;
    font-size: 0.8em;
}

.wine-club-order-info-default {
    @include wine-club-order-info;
    border-color: lighten($blue, 30%);
    background-color: lighten($blue, 30%);
}


.wine-club-order-tracking-info {
    @include wine-club-order-info;
    border-color: lighten($blue, 30%);
    background-color: lighten($blue, 30%);

    a {
        color: $black;
        text-decoration: underline;
    }
}

.wine-club-order-info-payment-failed {
    @include wine-club-order-info;
    border-color: lighten($red, 30%);
    background-color: lighten($red, 30%);
}

.wine-club-order-info-skipped {
    @include wine-club-order-info;
    background-color: #c3c3c3;
}

.cc-detail {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.cc-detail-brand {
    margin-right: 5px;
    line-height: 0;
}
