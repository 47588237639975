footer.site-footer {

  ul {
    list-style-type: none;
    margin: 0 0 40px;
    padding: 0;
    min-height: 180px;
  }

  .h3 {
    color: #ddd;
    margin-top: 0;
    line-height: 1em;
    margin-bottom: 15px;
    font-size: 22px;
  }

  p {
    line-height: 1.4em;
  }

  nav.footer-nav {
    background: #171717;
    padding: 80px 0 50px;
    .h2.mofotype {
      color: #eee;
      margin: 0 0 0.33em;
      font-size: 49px;
    }
    p {
      font-size: 1.06em;
      margin-bottom: 50px;
    }
    a {
      color: #777;
      line-height: 1.9em;
      &:hover {
        text-decoration: none;
        color: $green;
      }
    }
  }

  .location-selection {
    background: #171717;
    padding: 0 0 20px;
    em {
      font-style: normal;
      color: #ddd;
    }
  }

  .fine-print {
    background: #111;
    padding: 5.33em 0;
    li {
      margin-bottom: 0.66em;
      padding-left: 2em;
    }
    p {
      margin-right: 60px;
    }

    section {
        margin-bottom: 5.33em;

        ul {
            min-height: 0;
        }
    }
  }
}
@media only screen and (max-width: 767px) {
  footer.site-footer {
    ul {
      margin-bottom: 20px;
    }
    nav.footer-nav {
      .h2.mofotype {
        font-size: 40px;
      }
      p {
        font-size: 1em;
        margin-bottom: 2em;
      }
      a {
        line-height: 1.8em;
      }
      .h3 {
        margin-top: 1em;
      }
    }
    .fine-print {
      padding: 50px 15px;
      p, li {
        font-size: 0.9em;
        margin-right: 0;
      }
    }
    .col-xs-6, .col-xs-12 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media print {
    footer.site-footer {
        display: none;
    }
}
