// ------------------------------------------------
//  Navbar styles
// ------------------------------------------------
//  The positioning styles for this are in
//  menu.scss (there's a lot of interaction between
//  the two elements)
// ------------------------------------------------

$navbar_height: 125px;
$navbar_strip_height: 75px;

#nav-wrapper {
  @include transform(translate3d(0, 0, 0));
  @include transition-transform(0.5s);
}

#navbar {
  background: #111;
  position: relative;
  z-index: 1;           // So the scroll nav doesn't sit over the top
  .navbar-strip {
    display: table;
    width: 100%;
    table-layout: fixed;
    @include transition-transform(.5s);
    .menu-toggle, .left-of-logo, .logo, .right-of-logo, .utility-buttons {
      display: table-cell;
      vertical-align: middle;
    }
    .left-of-logo, .right-of-logo {
      @include transition(opacity .5s);
      text-transform: uppercase;
      opacity: 0;
      a {
        font-family: $sans_font_family;
        font-weight: 400;
        font-size: 0;
        display: inline-block;
        padding: 30px 5px;
        pointer-events: none;
        .short-version {
          display: none;
        }
      }
    }
    .menu-toggle, .utility-buttons {
      width: 186px;
    }
    .menu-toggle {
        width: 186px;
    }
    .menu-toggle {
      .menu-button {
        @include noselect();
        @include appearance(none);
        background: none;
        border: none;
        padding: 0;
        height: 67px;
        &:focus {
          outline: none;
        }
        &:hover, &:focus {
          .lines, .lines:before, .lines:after {
            background: $green;
          }
          .text:after {
            color: $green;
          }
        }
        .lines {
          display: inline-block;
          width: 25px;
          height: 2px;
          background: #fff;
          border-radius: 3px;
          position: relative;
          vertical-align: top;
          margin-right: 10px;
          top: 8px;
          @include transition-transform(.5s);
          &:before, &:after {
            display: inline-block;
            width: 25px;
            height: 2px;
            background: #fff;
            border-radius: 3px;
            position: absolute;
            left: 0;
            content: '';
            transition: transform .5s, top .5s;
            -webkit-transition: -webkit-transform .5s, top .5s;
            -moz-transition: -moz-transform .5s, top .5s;
            -ms-transition: -ms-transform .5s, top .5s;
            -o-transition: -o-transform .5s, top .5s;
          }
          &:before {
            top: 8px;
          }
          &:after {
            top: -8px;
          }
        }
        .text {
          &:after {
            content: 'Menu';
            color: #fff;
            line-height: 1em;
            font-size: 12px;
          }
          position: relative;
          top: -3px;
          left: -2px;
          opacity: 1;
          @include transition(opacity .5s);
        }
      }
    }
    .left-of-logo {
      text-align: right;
    }
    .logo {
      width: 180px;
      text-align: center;
      &:hover, &:focus {
        text-decoration: none;
      }
      img {
        @include transition-transform(.5s);
      }
    }
    .right-of-logo {
      text-align: left;
    }
    .utility-buttons {
      font-size: 0px;
      text-align: right;
      position: relative;
      line-height: 18px;
      .utility-button {
        display: inline-block;
        text-align: center;
        padding: 0 5px;
        font-size: 40px;

        vertical-align: middle;
        @include appearance(none);
        background: none;
        border: none;
        color: $green;
        position: relative;
        i {
          display: block;
          height: 33px;

          &:before {
            vertical-align: top;
            line-height: 33px;
          }
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &:hover, &:focus {
          text-decoration: none;
          outline: none;
          @include filter(grayscale(100%) brightness(200%));
        }
        &.global {
          &:focus:not(:hover) {
            @include filter(none);
          }
          i {
            font-size: 37px;
          }
        }
        .translate-wrapper {
          @include transition-transform(.5s);
          i {
            @include transition-transform(.5s);
            font-size: 40px;


            &.ion-ios-search {
              @media only screen and (min-width: 767px) {
                font-size: 32px;
              }
            }
          }
        }
        span {
          display: block;
          margin-top: 4px;
          color: $green;
          line-height: 1em;
          font-size: 12px;
          @include transition(opacity .5s);
        }
      }
      .alert-count {
        $badge-size: 16px;
        position: absolute;
        left: 30px;
        top: 0px;

        display: block;
        width: $badge-size;
        height: $badge-size;

        font-size: 12px;
        line-height: $badge-size;
        font-weight: bold;

        background: $red;
        color: #fff;
        @include border-radius(50%);
        pointer-events: none;
      }
      .signup-blocked-button {
        font-size: 15px;
      }
    }
  }
  .menu-items-strip {
    @include transform(translate3d(0,20px,0));
    @include transition(opacity .5s);
    display: none;
    text-transform: uppercase;
    opacity: 1;
    width: 100%;
    -js-display: flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      font-size: 13px;
      font-family: $sans_font_family;
      font-weight: 400;
      padding-bottom: 14px;
      .short-version {
        display: none;
      }
    }
  }
  .left-of-logo, .right-of-logo, .menu-items-strip {
    a {
      color: #fff;
      &:hover, &:focus {
        text-decoration: none;
        color: $green;
      }
      &.active {
        color: $green;
      }
    }
  }
}

// Turn the menu icon into an X when the menu is open
html.menu-open {
  #navbar {
    .navbar-strip {
      .menu-toggle {
        .menu-button {
          .lines {
            @include transform(rotate3d(0, 0, 1, 45deg));
            &:before, &:after {
              top: 0;
            }
            &:before {
              @include transform(rotate3d(0, 0, 1, -90deg));
            }
            &:after {
              @include transform(rotate3d(0, 0, 1, -90deg));
            }
          }
          .text {
            &:after {
              content: 'Close';
            }
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Non-mobiles
// ------------------------------------------------

@media only screen and (min-width: 768px) {
  #navbar {
    height: $navbar_height;
    .navbar-strip {
      @include transform(translate3d(0,12px,0));
      height: $navbar_strip_height;
    }
    &.simple {
      .navbar-strip {
        @include transform(translate3d(0,25px,0));
      }
    }
  }
  #page-wrapper {
    padding-top: 125px;
  }
  body.no-nav #page-wrapper {
    padding-top: 0;
  }

  // Condensed - 50px high, title links moved to left and right of logo
  html.menu-open {
    body.scrolled {
      #nav-wrapper {
        @include transform(translate3d(260px,-50px,0));
      }
    }
  }
  body.scrolled {
    #nav-wrapper {
      @include transform(translate3d(0,-50px,0));
    }

    #navbar {
      height: 145px;

      .navbar-strip {
        @include transform(translate3d(0,32px,0));
        height: 90px;
        .left-of-logo, .right-of-logo {
          opacity: 1;
          a {
            pointer-events: auto;
            font-size: 11px;
          }
        }
        .menu-toggle {
          .menu-button {
            .text { opacity: 0; }
          }
        }
        .logo img {
          @include transform(scale3d(0.8,0.8,1));
        }
        .utility-buttons {
          .utility-button {
            .translate-wrapper {
              @include transform(translate3d(0,7px,0));
              i {
                @include transform(scale3d(0.8,0.8,1));


                &.ion-ios-search {
                  @media only screen and (min-width: 767px) {
                    font-size: 32px;
                  }
                }
              }
            }
            span {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

// ------------------------------------------------
//  Tablets and small desktops
// ------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 992px) {
  #navbar {
    .navbar-strip {
      .left-of-logo, .right-of-logo {
        display: none;
      }
    }
    .menu-items-strip {
      a {
        .short-version {
          display: block;
        }
        .long-version {
          display: none;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Medium and large desktops
// ------------------------------------------------

@media only screen and (min-width: 1087px) and (max-width: 1332px) {
  #navbar {
    .navbar-strip {
      .left-of-logo, .right-of-logo {
        a {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 0px;
        }
      }
    }
  }
}

// ------------------------------------------------
//  Mobiles
// ------------------------------------------------

@media only screen and (max-width: 767px) {

  // Always 60px high (i.e. scrolling has no
  // effect), title links hidden
  #navbar {
    height: 60px;
    .navbar-strip {
      height: 60px;
      .menu-toggle, .utility-buttons {
        width: auto;
      }
      .menu-toggle {
        position: relative;
        .menu-button {
          padding: 0;
          height: 60px;
          width: 60px;
          background: #111;
          .text { display: none; }
          .lines {
            top: 9px;
            left: 4px;
            transition: none;
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            &:before, &:after {
              transition: none;
              -webkit-transition: none;
              -moz-transition: none;
              -ms-transition: none;
              -o-transition: none;
            }
          }
        }
      }
      .logo {
        width: 162px;
        padding-top: 3px;
        img {
          @include transform(scale3d(0.7,0.7,1));
        }
      }
      .left-of-logo, .right-of-logo {
        display: none;
      }
      .utility-buttons {
        .invite, .account {
          display: none;
        }
        .cart, .global, .search {
          padding-left: 0;
          padding-right: 0;
          i {
            @include transform(scale3d(0.8,0.8,1));
          }
          span { display: none; }
          .alert-count {
            right: 18px;
          }
        }
      }
    }
    .menu-items-strip {
      opacity: 0;
      display: none;
    }
  }
  #page-wrapper {
    padding-top: 0;
  }
}

// ------------------------------------------------
//  Print
// ------------------------------------------------

@media only print {
  #navbar {
    display: none;
  }
}

// ------------------------------------------------
//  Legacy IE
// ------------------------------------------------

html.legacy-ie {

  // Use margin-top to vertically centre the navbar
  // row instead of transforms
  #navbar {
    width: 100%;
    position: absolute;
    .navbar-strip {
      margin-top: 20px;
    }
  }

  &.menu-open {
    body {
      #navbar {
        margin-left: 260px;
        .navbar-strip {
          .menu-toggle {
            .menu-button {
              .lines {
                @include transform(rotate(45deg));
                &:before, &:after {
                  top: 0;
                }
                &:before {
                  @include transform(rotate(-90deg));
                }
                &:after {
                  @include transform(rotate(-90deg));
                }
              }
              .text {
                &:after {
                  content: 'Close';
                }
              }
            }
          }
        }
      }
    }
  }

  // Condensed navbar
  & {
    body.scrolled {
      #navbar {
        margin-top: -40px;
        .navbar-strip {
          margin-top: 40px;
          .left-of-logo, .right-of-logo {
            visibility: hidden;
          }
          .menu-toggle {
            .menu-button {
              .text { display: none; }
            }
          }
          .logo img {
            zoom: 0.8\9;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
