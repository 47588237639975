.vertical-centre-outer {
  display: table;
  width: 100%;
  height: 100%;
  .vertical-centre-inner {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}
