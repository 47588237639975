body.cart {
  .empty-cart-container {
    p {
      margin-bottom: 3em;
    }
    .btn-ghost {
      margin-bottom: 3em;
    }
  }
}
