@font-face {
    font-family: "Alternate Gothic No2 D";
    src: font-url("common/alternate-gothic/AlternateGotNo2D.woff2")
            format("woff2"),
        font-url("common/alternate-gothic/AlternateGotNo2D.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GT Walsheim";
    src: font-url("GT-Walsheim-Bold.woff2") format("woff2"),
         font-url("GT-Walsheim-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "GT Walsheim";
    src: font-url("GT-Walsheim-Bold.woff2") format("woff2"),
         font-url("GT-Walsheim-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GT Walsheim";
    src: font-url("GT-Walsheim-Medium.woff2") format("woff2"),
         font-url("GT-Walsheim-Medium.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: font-url("Graphik-Regular-Web.woff2") format("woff2"),
         font-url("Graphik-Regular-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: font-url("Graphik-Medium-Web.woff2") format("woff2"),
         font-url("Graphik-Medium-Web.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
